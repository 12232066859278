import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  useGetDealByIdMutation,
  useGetAuthorMutation,
} from '../redux/features/user/dealsApiSlice';

//Icons
import User from '../components/img/User.js';
import Email from '../components/img/EmailIcon.js';
import Back from '../components/img/Back.svg';
import Flag from '../components/img/Flag.js';
import Calendar from '../components/img/Calendar.js';
import Oval from '../components/img/Oval.svg';
import ReOpenDeal from '../components/Modals/ReOpenDeal';
import CloseDeal from '../components/Modals/CloseDeal';
import CloseDealInfo from '../components/Popover/CloseDealnfo';
import ReopenDealInfo from '../components/Popover/ReopenDealInfo';
import useAuth from '../hooks/useAuth.js';
import ContactSection from '../components/AnalyticsPanel/ContactSection.js';
import {
  useGetPartnerByIdMutation,
  useGetPartnerOwnerMutation,
} from '../redux/features/user/partnersApiSlice.js';

const Company1 = () => {
  const [deal, setDeal] = useState(null);
  const [stageNameProp, setStageNameProp] = useState(null);
  const [author, setAuthor] = useState('');
  const [reloadOnChange, setReloadOnChange] = useState(false);
  const location = useLocation();
  const [getDealById] = useGetDealByIdMutation();
  const [getAuthor] = useGetAuthorMutation();
  // const [editStage] = useEditStageMutation();
  const [partnerData, setPartnerData] = useState(null);
  const [partnerOwnerData, setPartnerOwnerData] = useState(null);
  const [getPartner] = useGetPartnerByIdMutation();
  const [getPartnerOwner] = useGetPartnerOwnerMutation();

  const dealId = location.pathname.split('/')[2];

  const userinfo = useAuth();

  useEffect(() => {
    const getPartnerData = async () => {
      const partnerInfo = await getPartner(userinfo.partnerId).unwrap();
      setPartnerData(partnerInfo);
    };
    getPartnerData();
  }, []);

  useEffect(() => {
    const getPartnerOwnerData = async () => {
      const partnerOwnerInfo = await getPartnerOwner(
        partnerData[0].salesforceId
      ).unwrap();
      setPartnerOwnerData(partnerOwnerInfo);
    };
    getPartnerOwnerData();
  }, [partnerData]);

  function reformatDate(dateStr) {
    let dArr = dateStr.split('-'); // ex input "2010-01-18"
    return dArr[1] + '/' + dArr[2] + '/' + dArr[0]; //ex out: "18/01/10"
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getDealById(dealId).unwrap();
        setDeal(res);
        setStageNameProp(res[0].StageName);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [dealId, reloadOnChange]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAuthor(dealId).unwrap();
        if (res.length > 0) {
          setAuthor(res[0].username);
        } else setAuthor(null);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [dealId, reloadOnChange]);

  // const handleSubmit = async (stage) => {
  //   try {
  //     const res = await editStage({ StageName: stage, Id: dealId }).unwrap();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const CloudTypes = [
    'Customer New Cloud',
    'Customer Renewal Cloud',
    'Customer Upgrade Cloud',
    'Customer Renewal/Upgrade Cloud',
    'Customer Renewal/Downgrade Cloud',
    'Partner Customer New Cloud',
    'Partner Customer Renewal Cloud',
  ];

  const PremisesTypes = [
    'Customer New On-premise',
    'Customer Renewal On-premise',
    'Customer Renewal/Expansion On-premise',
    'Customer Renewal/Reduction On-premise',
    'Customer Expansion On-premise',
    'Partner Customer New On premises',
    'Partner Customer Renewal On premises',
  ];

  function ReturnType(obj) {
    if (CloudTypes.includes(obj)) {
      return 'Cloud'; // replace name with 'Mike'
    } else if (PremisesTypes.includes(obj)) {
      return 'On premises';
    }
    return '-';
  }

  return (
    <div className=''>
      {!deal ? (
        <div className='flex mt-[50vh] -translate-y-32 justify-center items-center'>
          <img alt='loading' className='w-32' src={Oval} />
        </div>
      ) : (
        <div>
          <div
            className={`text-lg sm:text-2xl 2xl:text-3xl justify-between font-semibold p-5 h-[72px] 2xl:h-[76px] xl:pl-[22px] 2xl:px-[40px] flex items-center rounded-2xl m-3 lg:m-7 ${
              deal[0].StageName === 'Closed Lost'
                ? 'bg-[#5E6872]'
                : 'bg-[#3D82F1]'
            } text-white`}
          >
            <div className='items-center'>
              <div className='font-semibold text-2xl 2xl:text-3xl flex items-center'>
                {deal[0]?.Partner_Client__c}
                {/* <div className='hidden lg:flex'>
                  {deal[0].StageName === 'Closed Lost' ? (
                    <DealLost />
                  ) : deal[0].StageName === 'Closed Disqualified' ? (
                    <DealDisqualified />
                  ) : (
                    <DealPending />
                  )}
                </div> */}
              </div>
            </div>
            <>
              {deal[0].StageName === 'Closed Lost' ||
              deal[0].StageName === 'Closed Disqualified' ? (
                <div className='flex items-center text-base  font-normal'>
                  <ReOpenDeal
                    setReload={setReloadOnChange}
                    reloadValue={reloadOnChange}
                    dealId={dealId}
                    dealName={deal[0]?.Partner_Client__c}
                  />
                  <div className='ml-3 lg:ml-[14px] 2xl:ml-3'>
                    <ReopenDealInfo />
                  </div>
                </div>
              ) : (
                <div className='flex items-center text-base font-normal'>
                  <CloseDeal
                    setReload={setReloadOnChange}
                    reloadValue={reloadOnChange}
                    dealId={dealId}
                    dealName={deal[0]?.Partner_Client__c}
                  />
                  <div className='ml-3 lg:ml-[14px] 2xl:ml-3'>
                    <CloseDealInfo />
                  </div>
                </div>
              )}
            </>
            {/* <MobileDealStatus status={deal[0].StageName} /> */}
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-3 max-lg:pl-5 lg:pl-[30px] xl:pl-[37px] 2xl:pl-0 xl:mx-0 2xl:mx-[55px]'>
            <div className='mt-5 col-span-2'>
              <h1
                className={` text-xl 2xl:text-[22px] font-semibold text-left max-md:mb-[30px] ${
                  deal[0].StageName === 'Closed Lost'
                    ? 'text-[#A2ABBA]'
                    : 'text-black'
                } `}
              >
                Information
              </h1>

              <span className='form-divider h-1 mr-0 lg:mr-16 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
              <div className='flex flex-col '>
                <div className='grid grid-cols-1 xl:grid-cols-2 gap-y-9'>
                  <div className='flex items-center '>
                    <div>
                      <User
                        bgfill={'#EBF2FB'}
                        mainfill={
                          deal[0].StageName === 'Closed Lost'
                            ? '#A2ABBA'
                            : '#3D82F1'
                        }
                        className='mr-2 w-[41px]'
                      />
                    </div>
                    <div className='flex flex-row lg:flex-col 2xl:flex-row'>
                      <h1
                        className={`font-semibold leading-tight 2xl:text-lg ${
                          deal[0].StageName === 'Closed Lost'
                            ? 'text-[#A2ABBA]'
                            : 'text-[#3D82F1]'
                        } `}
                      >
                        Contact person:&nbsp;
                      </h1>
                      <h3
                        className={`2xl:text-lg  leading-tight ${
                          deal[0].StageName === 'Closed Lost' ||
                          deal[0].StageName === undefined
                            ? 'text-[#A2ABBA]'
                            : 'text-black'
                        }`}
                      >
                        {deal[0]?.Contact_Name__c}
                      </h3>
                    </div>
                  </div>

                  <div className='flex items-center'>
                    <div>
                      <Email
                        className='mr-2 w-[41px]'
                        bgfill={'#EBF2FB'}
                        mainfill={
                          deal[0].StageName === 'Closed Lost' ||
                          deal[0].StageName === undefined
                            ? '#A2ABBA'
                            : '#3D82F1'
                        }
                      />
                    </div>
                    <div className='flex flex-row lg:flex-col 2xl:flex-row'>
                      <h1
                        className={`font-semibold leading-tight 2xl:text-lg ${
                          deal[0].StageName === 'Closed Lost' ||
                          deal[0].StageName === undefined
                            ? 'text-[#A2ABBA]'
                            : 'text-[#3D82F1]'
                        } `}
                      >
                        Email:&nbsp;
                      </h1>
                      <h3
                        className={`2xl:text-lg leading-tight ${
                          deal[0].StageName === 'Closed Lost' ||
                          deal[0].StageName === undefined
                            ? 'text-[#A2ABBA]'
                            : 'text-black'
                        }`}
                      >
                        {deal[0]?.Contact_Email__c}
                      </h3>
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <div>
                      <Calendar
                        className='mr-2 w-[41px]'
                        bgfill={'#EBF2FB'}
                        mainfill={
                          deal[0].StageName === 'Closed Lost' ||
                          deal[0].StageName === undefined
                            ? '#A2ABBA'
                            : '#3D82F1'
                        }
                      />
                    </div>
                    <div className='flex'>
                      <h1
                        className={`font-semibold leading-tight 2xl:text-lg ${
                          deal[0].StageName === 'Closed Lost' ||
                          deal[0].StageName === undefined
                            ? 'text-[#A2ABBA]'
                            : 'text-[#3D82F1]'
                        } `}
                      >
                        Expiration date:&nbsp;
                      </h1>
                      <h3
                        className={`2xl:text-lg leading-tight ${
                          deal[0].StageName === 'Closed Lost' ||
                          deal[0].StageName === undefined
                            ? 'text-[#A2ABBA]'
                            : 'text-black'
                        }`}
                      >
                        {reformatDate(deal[0]?.CloseDate)}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=' col-span-1'>
              <div className='pt-5'>
                <h1
                  className={`text-xl 2xl:text-[22px] font-semibold text-left max-md:mb-[30px]   ${
                    deal[0].StageName === 'Closed Lost'
                      ? 'text-[#A2ABBA]'
                      : 'text-black'
                  }`}
                >
                  Service requested
                </h1>

                <span className='form-divider h-1 mr-0 lg:mr-16 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
                <div className='flex items-center'>
                  <div>
                    <Flag
                      className='mr-2 w-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={
                        deal[0].StageName === 'Closed Lost' ||
                        deal[0].StageName === undefined
                          ? '#A2ABBA'
                          : '#3D82F1'
                      }
                    />
                  </div>
                  <div className='flex items-center'>
                    <h1
                      className={`font-semibold  leading-tight 2xl:text-lg ${
                        deal[0].StageName === 'Closed Lost' ||
                        deal[0].StageName === undefined
                          ? 'text-[#A2ABBA]'
                          : 'text-[#3D82F1]'
                      } `}
                    >
                      Plan:&nbsp;
                    </h1>
                    <h3
                      className={`2xl:text-lg leading-tight ${
                        deal[0].StageName === 'Closed Lost' ||
                        deal[0].StageName === undefined
                          ? 'text-[#A2ABBA]'
                          : 'text-black'
                      }`}
                    >
                      {ReturnType(deal[0]?.Type)}
                    </h3>
                  </div>
                </div>
              </div>
              <div className='mt-8'>
                <div className='flex items-center col-span-3'>
                  <div>
                    <User
                      bgfill={'#EBF2FB'}
                      mainfill={
                        deal[0].StageName === 'Closed Lost' ||
                        deal[0].StageName === undefined
                          ? '#A2ABBA'
                          : '#3D82F1'
                      }
                      className='mr-2 w-[41px]'
                    />
                  </div>
                  <div className='flex flex-row lg:flex-col 2xl:flex-row'>
                    <h1
                      className={`font-semibold leading-tight 2xl:text-lg ${
                        deal[0].StageName === 'Closed Lost' ||
                        deal[0].StageName === undefined
                          ? 'text-[#A2ABBA]'
                          : 'text-[#3D82F1]'
                      } `}
                    >
                      Registered by:&nbsp;
                    </h1>
                    <h3
                      className={`2xl:text-lg leading-tight ${
                        deal[0].StageName === 'Closed Lost' ||
                        deal[0].StageName === undefined
                          ? 'text-[#A2ABBA]'
                          : 'text-black'
                      }`}
                    >
                      {author === null ? 'Wazuh Sales' : author}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='mx-4 lg:mx-10 2xl:mx-14 mt-16'>
            <ContactSection ownerData={partnerOwnerData} />
          </div>

          <div className='flex mt-12 mx-5 lg:mx-0  2xl:mx-[55px] lg:pl-[30px] xl:pl-[37px] 2xl:pl-0 2xl:max-w-[1500px]'>
            <Link
              className='btn bg-yellow-gradient px-11 py-[5px] mb-4 rounded-lg flex items-center'
              to='/registered-deals'
            >
              <img alt='back' className='w-3 mr-2' src={Back}></img>
              <h3 className=''>Back</h3>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Company1;
