import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import PartnersLogo from '../components/img/PartnersLogo.svg';
import wazuhlogo from '../components/img/wazuh-log.svg';
import EmailIcon from '../components/img/EmailIcon.svg';
import Lock from '../components/img/Lock.svg';
import eye from '../components/img/eye.svg';
import eyeclosed from '../components/img/eyeClosed.svg';
import '../index.css';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../redux/features/user/authSlice';
import { useLoginMutation } from '../redux/features/user/authApiSlice';
import { useNeeds2FAMutation } from '../redux/features/user/2FAApiSlice';
import axios from 'axios';

const loginValidationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .max(70, 'Email name must be 70 characters or less.'),
  password: Yup.string().required('Password is required!'),
});

const forgotPasswordValidationSchema = Yup.object({
  passwordEmail: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .max(70, 'Email name must be 70 characters or less.'),
});

const initialData = {
  email: '',
  password: '',
  recaptcha: null,
};

const TwoFactorInitialData = {
  token: '',
};

const forgotPasswordInitialData = {
  passwordEmail: '',
};

const Home = () => {
  const [login, { isLoading }] = useLoginMutation();
  const [needs2FA] = useNeeds2FAMutation();
  const [seePassword, setSeePassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [twoFactorForm, setTwoFactorForm] = useState(false);
  const [twoFactorFormData, setTwoFactorFormData] = useState([]);

  const dispatch = useDispatch();

  const [err, setErr] = useState(null);
  const [fullfill, setFullfill] = useState(null);
  const [forgotError, setForgotError] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    const email = formData.email;
    const password = formData.password;
    const recaptcha = await window.grecaptcha.execute(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      { action: 'submit' }
    );
    const is2FAneeded = await needs2FA({ email: formData.email });
    console.log(is2FAneeded.data.TwoFA, 'is2fa needed');
    if (is2FAneeded.data.TwoFA === 'Enabled') {
      setTwoFactorForm(true);
      setTwoFactorFormData(formData);
    } else {
      try {
        const { accesstoken } = await login({
          email,
          password,
          recaptcha,
        }).unwrap();

        dispatch(setCredentials({ accesstoken }));
        navigate('/dashboard');
      } catch (error) {
        setErr(error.data);
      }
    }
  };

  const handleSubmitTwoFactor = async ({ token }) => {
    const recaptcha = await window.grecaptcha.execute(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      { action: 'submit' }
    );
    const formData = {
      ...twoFactorFormData,
      recaptcha: recaptcha,
      token: token,
    };
    try {
      const { accesstoken } = await login(formData).unwrap();
      dispatch(setCredentials({ accesstoken }));
      navigate('/dashboard');
    } catch (error) {
      setTwoFactorForm(false);
      setErr(error.data);
    }
  };

  const handleSubmitForgotPassword = async (formData) => {
    try {
      const sendEmail = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/password/forgot-pasword`,
        formData
      );
      setFullfill(sendEmail.data);
      setTimeout(() => setFullfill(null), 5000);
    } catch (error) {
      console.log(error, 'login error object');
      setForgotError(error.response.data);
      setTimeout(() => setForgotError(null), 5000);
    }
  };

  return (
    <div className='flex flex-col h-screen bg-[#F6F6F6] font-manrope'>
      <Navbar />
      <div className='bg-white flex flex-1 m-4 sm:m-16 rounded-xl'>
        <div className='flex flex-1 justify-center sm:justify-around items-center flex-col lg:flex-row'>
          <div className='flex flex-col items-center'>
            <img
              alt='partners logo'
              className='w-32 sm:w-auto'
              src={PartnersLogo}
            ></img>
            <div className='mt-2'>
              <img
                className='sm:w-[200px]'
                alt='wazuh logo'
                src={wazuhlogo}
              ></img>
            </div>
            <div className='text-xl sm:text-2xl font-semibold mt-4'>
              Partners Portal
            </div>
          </div>
          {isForgotPassword ? (
            <div className='flex flex-col w-[288px]'>
              <div className='text-center flex-col flex items-center content-center'>
                <h1 className=' text-xl sm:text-2xl font-semibold'>
                  Reset your password
                </h1>
              </div>
              <Formik
                validationSchema={forgotPasswordValidationSchema}
                initialValues={forgotPasswordInitialData}
                onSubmit={handleSubmitForgotPassword}
              >
                {({ errors, touched, dirty }) => (
                  <Form className='my-4'>
                    <h2 className='max-w-xs mb-4'>
                      Please enter your email address and we will send you a
                      password reset link.
                    </h2>
                    {forgotError && (
                      <p className='text-white text-center bg-red-300 p-3 mt-2 rounded-lg'>
                        {forgotError}
                      </p>
                    )}
                    {fullfill && (
                      <p className='text-white text-center mt-2 bg-blue-300 p-3 rounded-lg'>
                        {fullfill}
                      </p>
                    )}
                    {errors.passwordEmail && touched.passwordEmail && dirty ? (
                      <p className='font-semibold mb-2 text-red-500'>
                        {errors.passwordEmail}
                      </p>
                    ) : (
                      <p className='font-semibold mb-2'>Email</p>
                    )}

                    <div className='flex'>
                      <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300'>
                        <img alt='email icon' className='w-4' src={EmailIcon} />
                      </span>
                      <Field
                        name='passwordEmail'
                        type='email'
                        className='rounded-none rounded-r-lg bg-gray-50 border  text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5'
                        placeholder='Email'
                      ></Field>
                    </div>

                    <button
                      type='submit'
                      className='bg-[#3D82F1] hover:bg-blue-600 rounded-lg mt-4 w-36 text-white py-2 text-center items-center'
                    >
                      Reset password
                    </button>
                    <button
                      onClick={() => setIsForgotPassword(false)}
                      className=' hover:underline mt-4 w-36 text-blue-500  py-2 text-center items-center'
                    >
                      Cancel
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          ) : twoFactorForm ? (
            <div className='flex flex-col justify-center'>
              <div className='flex justify-center'>
                Two Factor Authentication
              </div>
              <p className='flex justify-center'>
                Please enter the code of your athentication app to log in.
              </p>
              <Formik
                onSubmit={handleSubmitTwoFactor}
                initialValues={TwoFactorInitialData}
              >
                <Form>
                  <Field
                    type='number'
                    autoComplete='off'
                    name='token'
                    placeholder='Enter 2FA code'
                    maxLength='6'
                    min='0'
                    className='rounded-lg bg-gray-50 border  text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5'
                  ></Field>
                  <button
                    className='bg-[#3D82F1] w-full hover:bg-blue-600 rounded-lg mt-4 text-white py-2 text-center items-center'
                    type='submit'
                  >
                    Log In
                  </button>
                </Form>
              </Formik>
            </div>
          ) : (
            <div className='flex flex-col w-auto'>
              <div className='text-center flex-col flex items-center content-center'>
                <h1 className=' text-xl sm:text-2xl font-semibold'>Log in</h1>
              </div>
              <Formik
                validationSchema={loginValidationSchema}
                initialValues={initialData}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, dirty }) => (
                  <Form className='mx-8 sm:mx-0 sm:max-w-[300px] md:max-w-[350px]'>
                    {errors.email && touched.email && dirty ? (
                      <p className='font-semibold mb-2 text-red-500'>
                        {errors.email}
                      </p>
                    ) : (
                      <p className='font-semibold mb-2'>Email</p>
                    )}
                    <div className='flex'>
                      <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300'>
                        <img alt='email icon' className='w-4' src={EmailIcon} />
                      </span>
                      <Field
                        name='email'
                        type='email'
                        className='rounded-none rounded-r-lg bg-gray-50 border  text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5'
                        placeholder='Email'
                      ></Field>
                    </div>
                    {errors.password && touched.password && dirty ? (
                      <p className='font-semibold mb-2 text-red-500'>
                        {errors.password}
                      </p>
                    ) : (
                      <p className='font-semibold my-2'>Password</p>
                    )}
                    <div className='flex mb-4'>
                      <span className='bg-[#FBFCFD] inline-flex items-center px-3 text-sm text-gray-900 bg-gray-[#DBE3EB] rounded-l-md border border-r-0 border-gray-300'>
                        <img alt='lock' src={Lock} />
                      </span>
                      <Field
                        name='password'
                        type={seePassword ? 'text' : 'password'}
                        id='website-admin'
                        className='rounded-none  bg-gray-50 border border-l-0  text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5'
                        placeholder='Password'
                      ></Field>
                      <span
                        onClick={() => setSeePassword(!seePassword)}
                        className='hover:cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-[#DBE3EB] rounded-r-md border border-l-0 border-gray-300'
                      >
                        {seePassword ? (
                          <img alt='open eye' src={eye} />
                        ) : (
                          <img alt='closed eye' src={eyeclosed} />
                        )}
                      </span>
                    </div>

                    <div className='text-xs'>
                      This site is protected by reCAPTCHA and the Google{' '}
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className=' text-blue-500 hover:underline hover:cursor-pointer'
                        href='https://policies.google.com/privacy'
                      >
                        Privacy Policy
                      </a>{' '}
                      and{' '}
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className=' text-blue-500 hover:underline hover:cursor-pointer'
                        href='https://policies.google.com/terms'
                      >
                        Terms of Service
                      </a>{' '}
                      apply.
                    </div>
                    {err && (
                      <p className='text-red-500 text-center mt-2'>{err}</p>
                    )}
                    <button
                      type='submit'
                      className='bg-[#256BD1] w-full hover:bg-blue-600 hover:cursor-pointer rounded-lg mt-4 py-2 text-center items-center text-white'
                    >
                      Log in
                    </button>
                  </Form>
                )}
              </Formik>
              <div className='text-center mt-6 cursor-pointer'>
                <button
                  onClick={() => setIsForgotPassword(true)}
                  className='hover:text-blue-500 hover:underline'
                >
                  Forgot your password?
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Footer */}
      <div className='h-16 bg-black text-white  flex justify-around items-center'>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://wazuh.com/terms-of-use/'
          className='hover:text-blue-500 hover:underline cursor-pointer'
        >
          Legal Resources
        </a>
        {/* <h1 className='hover:text-blue-500 hover:underline cursor-pointer'>
          Privacy
        </h1>
        <h1 className='hover:text-blue-500 hover:underline cursor-pointer'>
          Contact us
        </h1> */}
      </div>
    </div>
  );
};

export default Home;
