import React from 'react';
import BrandKit from '../components/img/BrandKit.svg';
import MarketingMaterial from '../components/img/MarketingMaterial.svg';
import TrainingSyllabus from '../components/img/TrainingSyllabus.svg';
import Download from '../components/img/Download.svg';
import { DownloadFiles } from '../hooks/DownloadFiles';

const url = `${process.env.REACT_APP_API_URL}/api/`;

const PartnersMaterial = () => {
  return (
    <div className='m-5 mt-8 2xl:my-[60px] 2xl:mx-[55px]'>
      <div className='text-3xl font-semibold'>Partners material</div>
      <div className='mt-4 sm:mt-16'>
        <div className='flex'>
          <div className='sm:grid grid-cols-1 xl:grid-cols-3 sm:gap-11 w-full max-w-6xl'>
            <div className='rounded-lg bg-[#F8FAFD] hover:bg-[#DCEBFF] py-6 hover:border max-sm:my-4 border shadow-lg hover:border-blue-500 border-[#F8FAFD] flex justify-between  items-center 2xl:min-w-[300px] hover:cursor-pointer px-4 text-left'>
              <div className='flex items-center'>
                <img alt='brand kit' src={BrandKit}></img>
                <h1 className='text-2xl font-semibold ml-4'>
                  Brand <br /> Kit
                </h1>
              </div>
              <button
                onClick={() =>
                  DownloadFiles(`${url}files/wazuh`, 'onboarding.pdf')
                }
              >
                <img
                  alt='download'
                  className=' w-8 my-8 mr-6'
                  src={Download}
                ></img>
              </button>
            </div>
            <div className='rounded-lg bg-[#F8FAFD] hover:bg-[#DCEBFF] py-6 hover:border max-sm:my-4 border shadow-lg hover:border-blue-500 border-[#F8FAFD] flex justify-between  items-center 2xl:min-w-[300px] hover:cursor-pointer px-4 text-left'>
              <div className='flex items-center'>
                <img alt='brand kit' src={MarketingMaterial}></img>
                <h1 className='text-2xl font-semibold ml-4'>
                  Marketing <br /> material
                </h1>
              </div>
              <button
                onClick={() =>
                  DownloadFiles(`${url}files/wazuh`, 'onboarding.pdf')
                }
              >
                <img
                  alt='download'
                  className=' w-8 my-8 mr-6'
                  src={Download}
                ></img>
              </button>
            </div>
            <div className='rounded-lg bg-[#F8FAFD] hover:bg-[#DCEBFF] py-6 hover:border max-sm:my-4 border shadow-lg hover:border-blue-500 border-[#F8FAFD] flex justify-between  items-center 2xl:min-w-[300px] hover:cursor-pointer px-4 text-left'>
              <div className='flex items-center'>
                <img alt='brand kit' src={TrainingSyllabus}></img>
                <h1 className='text-2xl font-semibold ml-4'>
                  Training <br /> Syllabus
                </h1>
              </div>
              <button
                onClick={() =>
                  DownloadFiles(`${url}files/wazuh`, 'onboarding.pdf')
                }
              >
                <img
                  alt='download'
                  className=' w-8 my-8 mr-6'
                  src={Download}
                ></img>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnersMaterial;
