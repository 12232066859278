import React, { useEffect, useState } from 'react';
import {
  Tooltip,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { useGetMostGrossingPartnersMutation } from '../../redux/features/user/dealsApiSlice';
import ChartInfo from '../Popover/ChartInfo';
import Oval from '../img/Oval.svg';

const COLORS = [
  '#3D82F1',
  '#FEDD0B',
  '#D3ADF0',
  '#9B95DB',
  '#C00462',
  '#6BCEBA',
  '#7840BF',
  '#409ABF',
  '#E84DC3',
];

const MostGrossingPartners = () => {
  const [grossList, setGrossList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getMostGrossing] = useGetMostGrossingPartnersMutation();
  useEffect(() => {
    const getGrossingList = async () => {
      const result = await getMostGrossing().unwrap();
      setGrossList(result);
      setLoading(false);
    };
    getGrossingList();
  }, []);

  const CustomYAxisTick = (props) => {
    const { x, y, payload } = props;
    const formattedValue =
      payload.value >= 1000 ? `${payload.value / 1000}K` : payload.value;

    return (
      <g transform={`translate(${x - 17},${y - 11})`}>
        <text x={0} y={0} dy={16} textAnchor='middle' fill='#666'>
          {formattedValue}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='bg-white border-2 rounded-lg border-gray-300 p-4'>
          <p className='px-2 py-1'>{`${label}`}</p>
          {payload.map((pld) => (
            <div className=' px-2 py-1 flex flex-col'>
              <div style={{ color: pld.fill }}>
                {`${
                  pld.name[0].toUpperCase() + pld.name.slice(1)
                }: $ ${pld.value.toLocaleString()}`}
              </div>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <div>
        <div className='flex items-center'>
          <h1 className='text-[22px] mr-1 font-semibold text-left'>
            Top revenue partners
          </h1>
          <ChartInfo
            text={
              'Shows the partners with the top revenue counting only active contracts for recurrent services.'
            }
          />
        </div>
        <span className='form-divider h-1 mr-0 lg:mr-6 2xl:mr-3 block mt-2 mb-7'></span>
      </div>
      {loading === true ? (
        <div className='flex items-center justify-center bg-[#F6F6F6] h-[450px]'>
          <img src={Oval}></img>
        </div>
      ) : (
        <div className='rounded-lg bg-[#F6F6F6] px-2 pt-2'>
          <div className='flex items-center justify-center'>
            <ResponsiveContainer width='100%' height={423}>
              <BarChart
                width={700}
                height={300}
                data={grossList}
                margin={{
                  top: 40,
                  right: 40,
                  left: 25,
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='partner' hide='true' />

                <YAxis
                  tickFormatter={(value) =>
                    value >= 1000 ? `${value / 1000}K` : value
                  }
                  tick={CustomYAxisTick}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar radius={[10, 10, 0, 0]} dataKey='amount' fill='#3585F9' />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default MostGrossingPartners;
