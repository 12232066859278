import * as React from 'react';
const Export = (props) => (
  <svg
    width='41'
    height='41'
    viewBox='0 0 41 41'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='20.5' cy='20.5' r='20.5' fill={props.bgfill} />
    <path
      d='m16.37 20.384 4.149 3.214 4.98-7.563'
      stroke={props.mainfill}
      stroke-width='2'
    />
    <circle
      cx='21'
      cy='20'
      r='8.25'
      stroke={props.mainfill}
      stroke-width='1.5'
    />
  </svg>
);
export default Export;
