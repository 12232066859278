import React, { useEffect } from 'react';
import useAuth from './useAuth';
import { Outlet, useNavigate } from 'react-router-dom';

const IsWazuhRole = () => {
  const userinfo = useAuth();
  const navigate = useNavigate();

  const redirectDashboard = () => {
    navigate('/dashboard');
  };
  let content;
  useEffect(() => {
    if (userinfo.role !== 'wazuh') {
      redirectDashboard();
    }
  }, []);
  content = <Outlet />;
  return content;
};

export default IsWazuhRole;
