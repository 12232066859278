import React from 'react';
import WazuhPartnersPortal from './img/WazuhPartnersPortal.svg';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <div className='z-100 flex items-center justify-center h-20 px-4 sm:px-20 bg-white shadow-lg'>
      <div className='w-full flex'>
        <div className='flex items-center align-middle '>
          <Link to='/'>
            <img
              className='h-7 sm:h-auto'
              src={WazuhPartnersPortal}
              alt='wazuh-logo'
            ></img>
          </Link>
        </div>
        {/* <div className='hidden sm:flex items-center justify-end w-full'>
          <div className='px-2 hover:text-[#3585F9] hover:cursor-pointer'>
            Platform
          </div>
          <div className='px-2 hover:text-[#3585F9] hover:cursor-pointer'>
            Cloud
          </div>
          <div className='px-2 hover:text-[#3585F9] hover:cursor-pointer'>
            Documentation
          </div>
          <div className='px-2 hover:text-[#3585F9] hover:cursor-pointer'>
            Services
          </div>
          <div className='px-2'>
            <div className='bg-Accent px-4 py-2 rounded-md h-auto hover:cursor-pointer'>
              Install Wazuh
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Navbar;
