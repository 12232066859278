import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import SideBarArrow from '../img/SideBarArrow.svg';
import WhiteCross from '../img/WhiteCross.svg';
import useAuth from '../../hooks/useAuth';
import {
  useGetAllUsersByPartnerMutation,
  useUpdateUserRoleMutation,
} from '../../redux/features/user/usersApiSlice';

export default function ChangeUserRole() {
  let [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({userId: '', role: 'admin'})

  const [updateUserRole] = useUpdateUserRoleMutation();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const userinfo = useAuth();
  const [getAllUsers] = useGetAllUsersByPartnerMutation();
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const result = await getAllUsers(userinfo.partnerId).unwrap();
      if (result.length > 0) {
        setInitialValues({ userId: result[0].id, role:'admin' });
      }
      setAllUsers(result);
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleSubmitModal = async (formData) => {
    await updateUserRole(formData);
    setConfirmationOpen(true);
  };

  const cancelButtonRef = useRef(null);

  const closeConfirmationModal = () => {
    setConfirmationOpen(false);
  };

  return (
    <>
      <div className='flex mt-4'>
        <button
          type='button'
          className={`flex text-gray-800 hover:text-blue-500 items-center ${
            isOpen && 'text-blue-500 font-bold'
          }`}
          onClick={() => setModalOpen(true)}
        >
          <img
            alt='blue-arrow'
            className={`relative right-2 w-1 ${!isOpen && 'hidden'}`}
            src={SideBarArrow}
          />
          Change user role
        </button>
      </div>

      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={setModalOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            {/* Overlay Background */}
            <div className='fixed inset-0 bg-black bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white px-4 py-4 text-left align-middle shadow-xl transition-all'>
                  <Formik
                   enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={handleSubmitModal}
                  >
                     {({ values, setFieldValue })=> (
                      <Form className='flex flex-col gap-4 justify-center m-4'>
                        <div className='bg-white'>
                          <div className=''>
                            <div className=' text-center sm:mt-0 sm:text-left'>
                            <Dialog.Title
                                as='h3'
                               className='text-2xl pl-8 justify-between  font-semibold items-center bg-[#3D82F1] rounded-2xl w-full flex py-3 text-white'
                              >
                                <h1>Change <span className='wzh-remark'>user role</span></h1>
                                <img onClick={()=>setModalOpen(false)} className='mr-8 hover:cursor-pointer' src={WhiteCross}></img>
                              </Dialog.Title>
                              <div className='mt-4 mx-8'>
                                <div className='md:flex-row justify-between'>
                                  <div className='flex flex-col'>
                                    <p className='font-semibold mb-2'>
                                      Username
                                    </p>
                                    <Field
                                      className=' border border-[#B7C6D7] rounded-md  w-full px-2 py-2 text-black placeholder-[#B7C6D7] styled-select'
                                      as='select'
                                      name='userId'
                                    >
                                      {allUsers.map((user) => (
                                        <option value={user.id}>
                                          {user.username}
                                        </option>
                                      ))}
                                    </Field>
                                  </div>
                                </div>
                              </div>
                              <div className='mt-4 mx-8'>
                                <div className='md:flex-row justify-between'>
                                  <div className='flex flex-col'>
                                    <p className='font-semibold mb-2'>Role</p>
                                    <Field
                                      className=' border border-[#B7C6D7] rounded-md  w-full px-2 py-2 text-black placeholder-[#B7C6D7] styled-select'
                                      as='select'
                                      name='role'
                                    >
                                      <option value='admin'>Admin</option>
                                      <option value='user'>User</option>
                                      <option value='wazuh'>Wazuh</option>
                                    </Field>
                                  </div>
                                </div>
                              </div>
                              <div className='flex justify-between mt-8 px-8 gap-12 bg-[#F8F8F8] rounded-xl'>
                          <button
                           onClick={()=>setModalOpen(false)}
                              type='submit'
                              className='btn bg-yellow-gradient col-span-2 rounded-lg my-2 py-[10px] text-center items-center w-full'
                            >
                              Change
                            </button>
                            <button
                              type='button'
                              className='bg-[#FBFCFD] border border-[#3D82F1] hover:text-blue-700 hover:border-blue-800 text-[#3D82F1] col-span-2 rounded-lg my-2 py-[10px] text-center items-center w-full'
                              onClick={()=>setModalOpen(false)}
                              ref={cancelButtonRef}
                            >
                              Cancel
                            </button>
                          
                          </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={confirmationOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={closeConfirmationModal}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            {/* Overlay Background */}
            <div className='fixed inset-0 bg-black bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='w-full font-manrope max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <p className='text-lg text-gray-500'>
                    User updated succesfully!
                  </p>

                  {/* <div className='text-[#3D82F1] font-semibold text-sm'>
                    Once a deal is closed it can't be opened again
                  </div> */}
                  <div className='mt-6 justify-end flex'>
                    <button
                      type='button'
                      className='inline-flex ml-5 justify-center rounded-md border border-transparent bg-[#3D82F1] px-4 py-2 font-medium text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      onClick={() => closeConfirmationModal()}
                    >
                      Accept!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
