import { Popover, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import Notification from '../../components/img/Notification.svg';
import RedNotification from '../../components/img/RedNotification.svg';

const PartnerExpire = ({ type }) => {
  const [isShowing, setIsShowing] = useState(false);
  return (
    <div className='relative left-40'>
      <Popover className='relative'>
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md  text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <img
                alt='notification icon'
                onMouseEnter={() => setIsShowing(true)}
                onMouseLeave={() => setIsShowing(false)}
                src={`${type === 'expiring' ? Notification : RedNotification}`}
              ></img>
            </Popover.Button>
            <Transition
              show={isShowing}
              onMouseEnter={() => setIsShowing(true)}
              onMouseLeave={() => setIsShowing(false)}
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute -right-[140%] sm:-right-[50%] -top-[80px]  z-10 mt-3 w-screen max-w-xs sm:max-w-sm trans transform px-4 sm:px-0 lg:max-w-[280px]'>
                <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                  <div className=' bg-[#404040]'>
                    <span className='triangle-partner-expire'></span>
                    <a
                      href='##'
                      className='flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out  focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'
                    >
                      <span className='flex items-center'>
                        <span className='text-sm font-medium text-white'>
                          This plan
                          {type === 'expiring'
                            ? ' is about to expire'
                            : ' has expired'}
                          , please contact to &nbsp;
                          <a
                            href='mailto:partners@wazuh.com'
                            className='text-blue-500 hover:underline'
                          >
                            partners@wazuh.com
                          </a>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default PartnerExpire;
