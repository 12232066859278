import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/user/authSlice';
import thunk from 'redux-thunk';
import { apiSlice } from './apiSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([apiSlice.middleware, thunk]),
  devTools: true,
});
