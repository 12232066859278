import { apiSlice } from '../../apiSlice';

export const dealsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDeals: builder.mutation({
      query: (name) => ({
        url: `/api/deals/getregistereddeals/${name}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getCustomers: builder.mutation({
      query: (name) => ({
        url: `/api/deals/getcustomers/${name}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getExpiredCustomers: builder.mutation({
      query: (name) => ({
        url: `/api/deals/getexpiredcustomers/${name}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getAllExpiredCustomers: builder.mutation({
      query: (name) => ({
        url: `/api/deals/getallexpiredcustomers/${name}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getDealsPreview: builder.mutation({
      query: (name) => ({
        url: `/api/deals/getregistereddealspreview/${name}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getCustomersPreview: builder.mutation({
      query: (name) => ({
        url: `/api/deals/getcustomerspreview/${name}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getExpiredCustomersPreview: builder.mutation({
      query: (name) => ({
        url: `/api/deals/getexpiredcustomerspreview/${name}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getTest: builder.mutation({
      query: ({ id }) => ({
        url: `/test/${id}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    createDeal: builder.mutation({
      query: (formData) => ({
        url: '/api/deals/createdeal',
        method: 'POST',
        body: formData,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    createRegisteredDeal: builder.mutation({
      query: ({ dealId, userId }) => ({
        url: '/api/deals/createregistereddeal',
        method: 'POST',
        body: { dealId, userId },
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getDealById: builder.mutation({
      query: (id) => ({
        url: `/api/deals/getDealById/${id}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getAuthor: builder.mutation({
      query: (id) => ({
        url: `/api/deals/getRegisteredById/${id}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    editStage: builder.mutation({
      query: (stage) => ({
        url: `/api/deals/updateStage/${stage.Id}`,
        method: 'PUT',
        body: stage,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getMostGrossingPartners: builder.mutation({
      query: () => ({
        url: `/api/deals/getmostgrossingpartners`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getStagesByPartner: builder.mutation({
      query: (partner) => ({
        url: `/api/deals/getstagesbypartner/${partner}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getLeadById: builder.mutation({
      query: (id) => ({
        url: `/api/deals/getLeadById/${id}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getActiveSubscriptions: builder.mutation({
      query: (partner) => ({
        url: `/api/deals/getActiveSubscriptions/${partner}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getDealsByName: builder.mutation({
      query: ({ partner, searchname }) => ({
        url: `/api/deals/searchdealbyname/${partner}/${searchname}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getRenewals: builder.mutation({
      query: (name) => ({
        url: `/api/deals/getrenewals/${name}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
  }),
});

export const {
  useGetDealsMutation,
  useGetCustomersMutation,
  useGetExpiredCustomersMutation,
  useGetAllExpiredCustomersMutation,
  useGetDealsPreviewMutation,
  useGetCustomersPreviewMutation,
  useGetExpiredCustomersPreviewMutation,
  useGetTestMutation,
  useCreateDealMutation,
  useCreateRegisteredDealMutation,
  useGetDealByIdMutation,
  useGetAuthorMutation,
  useEditStageMutation,
  useGetMostGrossingPartnersMutation,
  useGetStagesByPartnerMutation,
  useGetLeadByIdMutation,
  useGetActiveSubscriptionsMutation,
  useGetDealsByNameMutation,
  useGetRenewalsMutation,
} = dealsApiSlice;
