import * as React from 'react';

const Export = (props) => (
  <svg
    width={12}
    height={12}
    viewBox='0 0 12 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2 5V4.2C2 1.8804 3.79086 0 6 0C8.20914 0 10 1.8804 10 4.2V5H11.1429C11.616 5 12 5.448 12 6V15C12 15.552 11.616 16 11.1429 16H0.857143C0.384 16 0 15.552 0 15V6C0 5.448 0.384 5 0.857143 5H2ZM1 15H11V6H1V15ZM6.99769 11.2945L7.5 12.5H4.5L5.00231 11.2945C4.69414 11.0014 4.5 10.5747 4.5 10.1C4.5 9.2168 5.172 8.5 6 8.5C6.828 8.5 7.5 9.2168 7.5 10.1C7.5 10.5747 7.30586 11.0014 6.99769 11.2945ZM9 4.36C9 2.50432 7.65685 1 6 1C4.34315 1 3 2.50432 3 4.36V5H9V4.36Z'
      fill={props.fill}
    />
  </svg>
);

export default Export;
