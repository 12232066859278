import React from 'react';
import CheckedBox from '../components/img/CheckedBox.svg';
import QuestionMark from '../components/img/QuestionMark.svg';
import UncheckedBox from '../components/img/UncheckedBox.svg';
import Minus from '../components/img/Minus.svg';
import TableBenefit from './Popover/TableBenefit';

const PlanTableRow = ({ name, checked, data, description }) => {
  return (
    <div className='mx-2 flex justify-between'>
      <div className=' border-gray-400 border-dashed xl:py-3  border-b flex items-center'>
        {/* <img className='mr-3 item' src={QuestionMark}></img> */}
        <div className='mr-3 h-12 text-center items-center flex'>
          <TableBenefit text={description} />
        </div>
        <h1 className='w-[220px] xl:w-[300px] break-words text-sm xl:text-base'>
          {name}
        </h1>
      </div>
      {checked === true ? (
        <img
          className='border-gray-400 border-dashed border-b pr-3 pl-3'
          src={CheckedBox}
        ></img>
      ) : checked === false ? (
        <img
          className='border-gray-400 border-dashed border-b w-12 px-2'
          src={Minus}
        ></img>
      ) : (
        <div className='text-[#3D82F1] text-center mt-6 items-center    font-medium border-gray-400 border-dashed border-b px-5 '>
          <h1>{data}</h1>
        </div>
      )}
    </div>
  );
};

export default PlanTableRow;
