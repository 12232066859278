import React, { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import AddUserModal from '../LargeAdminPanel/AddUserModal';
import AddPartnerModal from '../LargeAdminPanel/AddPartnerModal';
import ChangePartnerView from '../LargeAdminPanel/ChangePartnerView';
import ChangeUserRole from '../LargeAdminPanel/ChangeUserRole';
import AnalyticsButton from '../AdminPanel/AnalyticsButton';

const LargeWazuhPanel = () => {
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [isOpenRegisterPartnerModal, setIsOpenRegisterPartnerModal] =
    useState(false);
  const [isOpenChangePartnerViewModal, setIsOpenPartnerViewModal] =
    useState(false);
  const [isOpenChangeUserRole, setIsOpenChangeUserRole] = useState(false);

  const handleUserModal = () => {
    setIsOpenUserModal(!isOpenUserModal);
  };

  const openUserModal = () => {
    setIsOpenUserModal(true);
  };

  const closeUserModal = () => {
    setIsOpenUserModal(false);
  };

  const handlePartnerModal = () => {
    setIsOpenRegisterPartnerModal(!isOpenRegisterPartnerModal);
  };

  const openPartnerModal = () => {
    setIsOpenRegisterPartnerModal(true);
  };

  const closePartnerModal = () => {
    setIsOpenRegisterPartnerModal(false);
  };

  const handlePartnerViewModal = () => {
    setIsOpenPartnerViewModal(!isOpenChangePartnerViewModal);
  };

  const openPartnerViewModal = () => {
    setIsOpenPartnerViewModal(true);
  };

  const closePartnerViewModal = () => {
    setIsOpenPartnerViewModal(false);
  };

  const handleUserRoleModal = () => {
    setIsOpenChangeUserRole(!isOpenChangeUserRole);
  };

  const openUserRoleModal = () => {
    setIsOpenChangeUserRole(true);
  };

  const closeUserRoleModal = () => {
    setIsOpenChangeUserRole(false);
  };

  return (
    <div className='px-4'>
      <Popover className=''>
        {({ open }) => (
          <>
            <Popover.Button
              className={`
              ${
                open
                  ? ''
                  : 'text-opacity-90' +
                    ' hover:text-[#3D82F1] hover:cursor-pointer'
              }
             `}
            >
              <span>Wazuh Panel</span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute left-[635px] z-10 mt-3 w-screen -translate-x-1/2 transform px-4 sm:px-0 max-w-[220px]'>
                <span className='triangle-large-deal border border-blue-300 bg-white'></span>
                <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 border-blue-400 border'>
                  <div
                    onClick={handleUserModal}
                    className='relative grid hover:cursor-pointer hover:text-blue-500 gap-8 bg-white py-4 px-7'
                  >
                    Register User
                  </div>
                  <div
                    onClick={handlePartnerModal}
                    className='relative grid hover:cursor-pointer hover:text-blue-500 gap-8 bg-white py-4 px-7'
                  >
                    Register partner
                  </div>
                  <div
                    onClick={handlePartnerViewModal}
                    className='relative grid hover:cursor-pointer hover:text-blue-500 gap-8 bg-white py-4 px-7'
                  >
                    Change Partner view
                  </div>
                  <div
                    onClick={handleUserRoleModal}
                    className='relative grid hover:cursor-pointer hover:text-blue-500 gap-8 bg-white py-4 px-7'
                  >
                    Change user role
                  </div>
                  <div className='relative grid hover:cursor-pointer hover:text-blue-500 gap-8 bg-white py-4 px-7'>
                    <Link to='/wazuh-analytics' className='text-base'>
                      Sales Analytics
                    </Link>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <AddUserModal
        closeModal={closeUserModal}
        openModal={openUserModal}
        isOpen={isOpenUserModal}
      />
      <AddPartnerModal
        closeModal={closePartnerModal}
        openModal={openPartnerModal}
        isOpen={isOpenRegisterPartnerModal}
      />
      <ChangePartnerView
        closeModal={closePartnerViewModal}
        openModal={openPartnerViewModal}
        isOpen={isOpenChangePartnerViewModal}
      />
      <ChangeUserRole
        closeModal={closeUserRoleModal}
        openModal={openUserRoleModal}
        isOpen={isOpenChangeUserRole}
      />
    </div>
  );
};

export default LargeWazuhPanel;
