import * as React from 'react';
const Export = (props) => (
  <svg
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.8506 15.8708C14.1328 15.5118 15.0969 14.3472 15.2497 12.973C15.2543 12.9313 15.2656 12.8959 15.2747 12.8674C15.2752 12.8657 15.2758 12.8639 15.2763 12.8621L15.2758 11.5241C15.2689 11.5076 15.2616 11.4892 15.2551 11.4682C15.1359 11.077 14.8304 10.8602 14.4764 10.9159C14.1082 10.9738 13.8687 11.2744 13.8663 11.6816C13.8654 11.8282 13.8657 11.9747 13.866 12.1213C13.8663 12.2449 13.8665 12.3684 13.8661 12.4921C13.862 13.6674 13.0485 14.5219 11.9318 14.5239C8.97834 14.5293 6.08809 14.5307 3.34142 14.5279C2.22876 14.5268 1.41675 13.6777 1.41057 12.5089C1.41001 12.4008 1.41045 12.2928 1.4109 12.1848C1.41163 12.005 1.4124 11.8192 1.40814 11.6378C1.3996 11.2736 1.1528 10.9802 0.807953 10.9243C0.469204 10.8694 0.159707 11.0651 0.0380294 11.4115C0.0262882 11.445 0.0123125 11.4755 -3.8147e-05 11.5024L0.000571259 12.8782C0.012475 12.9384 0.0236068 12.9989 0.0347792 13.0593C0.0610649 13.2017 0.0858474 13.3361 0.120055 13.4689C0.464492 14.8072 1.58027 15.8133 2.89655 15.9725C2.93628 15.9773 2.97008 15.989 2.99722 15.9984C2.99901 15.999 3.0008 15.9996 3.00259 16.0002L12.2852 15.9958C12.3429 15.9833 12.4009 15.9716 12.4589 15.9599C12.595 15.9323 12.7235 15.9064 12.8506 15.8708Z'
      fill={props.fill}
    />
    <path
      d='M10.1107 10.0075C10.5707 9.52687 11.0463 9.02985 11.5158 8.54261C11.7266 8.32391 11.8013 8.0877 11.7443 7.82052C11.6838 7.5366 11.5194 7.35338 11.2558 7.27587C10.9837 7.19591 10.7521 7.26037 10.5478 7.47307C9.97013 8.07453 8.30263 9.76065 8.30263 9.76065L8.29568 9.44605V8.04895C8.29568 5.66874 8.29568 3.28862 8.29544 0.908456L8.29548 0.872848C8.29564 0.802101 8.29585 0.735306 8.28955 0.671824C8.26001 0.373241 8.11835 0.173662 7.85642 0.0616987C7.59141 -0.0515393 7.35305 -0.00921844 7.12774 0.191253C6.96161 0.339079 6.88767 0.54074 6.888 0.845229C6.89039 2.98825 6.89015 5.16714 6.88995 7.2743L6.88982 9.75436L6.14887 9.04931C5.65452 8.53122 5.14335 7.99546 4.63685 7.47295C4.4923 7.3238 4.3107 7.24337 4.12113 7.24337C4.10581 7.24337 4.0905 7.24388 4.0751 7.24494C3.87522 7.25862 3.69487 7.3606 3.5673 7.53201C3.34199 7.8348 3.38432 8.24088 3.67265 8.54248C4.58428 9.49606 5.49709 10.4484 6.4099 11.4008L7.02186 12.0393C7.40043 12.4343 7.7852 12.4341 8.16535 12.0385C8.4831 11.708 8.80015 11.3767 9.1172 11.0454L10.1107 10.0075Z'
      fill={props.fill}
    />
  </svg>
);
export default Export;
