import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import ProfilePartnerData from '../components/img/ProfilePartnerData.svg';
import ProfileCompany from '../components/img/ProfileCompany.svg';
import ProfileLogout from '../components/img/ProfileLogout.svg';
import ProfileIcon from '../components/img/ProfileIcon.svg';
import BlackDownArrow from '../components/img/BlackDownArrow.svg';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/features/user/userSlice';
import { useSendLogoutMutation } from '../redux/features/user/authApiSlice';
import useAuth from '../hooks/useAuth';
import { useGetPartnerByIdMutation } from '../redux/features/user/partnersApiSlice';

export default function MenuProfile() {
  const userinfo = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sendLogout, { isLoading, isSuccess, isError, error }] =
    useSendLogoutMutation();
  const [getPartner] = useGetPartnerByIdMutation();
  useEffect(() => {
    if (isSuccess) navigate('/');
  }, [isSuccess, navigate]);

  const [partnerData, setPartnerData] = useState(null);
  const [profilePicc, setProfilePicc] = useState('');

  useEffect(() => {
    const getPartnerData = async () => {
      const result = await getPartner(userinfo.partnerId).unwrap();
      setPartnerData(result);
    };
    getPartnerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) navigate('/');
  }, [isSuccess, navigate]);

  useEffect(() => {
    const getProfilePic = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/s3img/profile/${userinfo.partnerId}`
      );
      setProfilePicc(result.data);
    };
    if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
      getProfilePic();
    }
  }, []);

  return (
    <div className=''>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button className='inline-flex w-full justify-center rounded-md  px-4 py-2 text-sm font-medium text-black focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
            {({ open }) => (
              <>
                <div className='flex items-center'>
                  <div className='text-base'>
                    {userinfo && userinfo.username}
                  </div>
                  {!partnerData ? (
                    <div></div>
                  ) : (
                    <>
                      <img
                        alt='profile'
                        className='rounded-full w-10 ml-2 h-10 mr-4 object-contain'
                        src={ProfileIcon}
                      ></img>
                      <img
                        alt='profile'
                        className={`${
                          open && 'rotate-180'
                        } transition ease-out duration-300 rounded-full w-3 h-3 object-contain`}
                        src={BlackDownArrow}
                      ></img>
                    </>
                  )}
                </div>
              </>
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-10 mt-2 w-56 origin-top-right rounded-md bg-[#404040] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <span className='triangle'></span>
            <div className='px-1 py-1 '>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'hover:cursor-default text-white' : 'text-white'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm `}
                  >
                    <div className='ml-2 text-base overflow-ellipsis max-w-[200px] truncate'>{userinfo.email}</div>
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => navigate('/profile')}
                    className={`${
                      active ? 'bg-black text-white' : 'text-white'
                    } my-2 group flex w-full items-center rounded-md px-2 py-2 text-base`}
                  >
                    <img
                      alt='partner data'
                      className='mr-4 ml-2'
                      src={ProfileCompany}
                    />
                    Company
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => navigate('/my-settings')}
                    className={`${
                      active ? 'bg-black text-white' : 'text-white'
                    } my-2 group flex w-full items-center rounded-md px-2 py-2 text-base `}
                  >
                    <img
                      alt='profile user settings'
                      className='mr-4 ml-2'
                      src={ProfilePartnerData}
                    />
                    User settings
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={sendLogout}
                    className={`${
                      active ? ' bg-black text-white' : 'text-white'
                    } my-2 group flex w-full items-center rounded-md px-2 py-2 text-base`}
                  >
                    <img
                      alt='logout'
                      className='mr-5 ml-2'
                      src={ProfileLogout}
                    />
                    Logout
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
