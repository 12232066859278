import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import BlackCross from '../img/BlackCross.svg';
import SupportModalImg from '../img/SupportModal.svg';
export default function SupportModalContact() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div onClick={openModal} className=' flex hover:text-blue-500'>
        <span>Support Portal</span>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all font-manrope'>
                  <div className='flex justify-end'>
                    <img
                      className='hover:cursor-pointer'
                      onClick={closeModal}
                      src={BlackCross}
                    ></img>
                  </div>
                  <div className=' flex flex-col justify-center items-center'>
                    <img
                      alt='submitted message'
                      className='w-36 my-4 mr-[8px]'
                      src={SupportModalImg}
                    />
                    <div className=' text-center'>
                      <p className='text-3xl font-semibold max-w-[550px] sm:max-w-[700px] my-4'>
                        You will be redirected to Jira <br /> in order to create
                        tickets
                      </p>
                      <p className=' text-base font-semibold  max-w-[550px] sm:max-w-[700px] my-4'>
                        Access credentials are available to customers with an
                        active support subscription.
                        <br /> If that is not the case for you, please contact{' '}
                        <a
                          href='mailto:partners@wazuh.com '
                          className='text-blue-500 hover:underline focus:outline-none'
                        >
                          Sales
                        </a>{' '}
                        to purchase support.
                      </p>
                    </div>
                    <a
                      className='focus:outline-none'
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://wazuh-support.atlassian.net/servicedesk/customer/portal/4'
                    >
                      <button
                        onClick={closeModal}
                        type='button'
                        className='inline-flex mt-6 mb-4 justify-center rounded-md border border-transparent bg-[#3D82F1] px-8 py-2 text-base font-normal text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2'
                      >
                        Go to support portal
                      </button>
                    </a>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
