import LargeDeal from './Popover/LargeDeal';
import { Link } from 'react-router-dom';
import LargeWazuhPanel from './Popover/LargeWazuhPanel';
import useAuth from '../hooks/useAuth';
import LargeContactUs from './Popover/LargeContactUs';

const LargeSubNavbar = () => {
  const userinfo = useAuth();
  return (
    <div className='3xl:flex mt-[65px] hidden items-center justify-center h-16 px-5 bg-[#EBF2FB] shadow-lg z-10'>
      <div className='w-full flex justify-between 3xl:max-w-[1500px]'>
        <div className='flex justify-around w-[800px] mx-7 items-center text-center'>
          <Link className=' hover:text-blue-500' to='/dashboard'>
            Home
          </Link>
          <LargeDeal />
          <Link className=' hover:text-blue-500' to='/analytics'>
            Analytics
          </Link>
          <Link className=' hover:text-blue-500' to='/resources'>
            Resources
          </Link>
          {userinfo.role === 'wazuh' && <LargeWazuhPanel />}
          <LargeContactUs />
        </div>
        <div className='flex items-center'>
          <div className='hidden lg:block'></div>
          <div className='lg:hidden'></div>
        </div>
      </div>
    </div>
  );
};

export default LargeSubNavbar;
