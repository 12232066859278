import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import Lock from '../img/Lock.svg';
import OpenLock from '../img/OpenLock.svg';
import LockCopy from '../img/Lock copy';
import OpenLockCopy from '../img/OpenLock copy';
import WhiteCross from '../img/WhiteCross.svg';
import ArrowToRight from '../img/ArrowToRight';

export default function ProfileLevels({ discount, plan }) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className='relative h-5 flex'>
        <div
          onClick={openModal}
          className='text-[#3D82F1] hover:underline hover:cursor-pointer text-sm flex items-center'
        >
          Check discount tiers
          <ArrowToRight className='ml-2' mainfill={'#3D82F1'} />
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel
                  className={`${
                    plan === 'Platinum' || plan === 'Gold'
                      ? 'w-full max-w-2xl'
                      : 'w-auto'
                  }  font-manrope transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all`}
                >
                  {(plan === 'Platinum' || plan === 'Gold') && (
                    <div className='bg-mainblue p-3 flex justify-between pl-[24px]'>
                      <Dialog.Title
                        as='h3'
                        className=' text-2xl font-regular leading-6 text-white ml-2'
                      >
                        Tiers
                      </Dialog.Title>
                      <img
                        src={WhiteCross}
                        onClick={closeModal}
                        className='text-black hover:cursor-pointer mr-1'
                      ></img>
                    </div>
                  )}
                  {plan === 'Platinum' && (
                    <div className='mt-5 mb-8 px-3 sm:px-[34px]'>
                      <div>
                        <div className='flex items-center mb-5'>
                          {discount >= 0 && discount < 22 ? (
                            <OpenLockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#3D82F1'
                            />
                          ) : discount >= 22 ? (
                            <OpenLockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#595959'
                            />
                          ) : (
                            <LockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#595959'
                            />
                          )}
                          <div className='ml-5'>
                            <h1
                              className={`${
                                discount >= 0 &&
                                discount < 22 &&
                                'text-[#3D82F1] '
                              } font-semibold text-lg`}
                            >
                              Tier 01
                            </h1>
                            <h1>Access an 18% off in your plan.</h1>
                          </div>
                        </div>
                        <div className='flex items-center mb-5'>
                          {discount < 22 ? (
                            <LockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#595959'
                            />
                          ) : discount >= 22 && discount < 26 ? (
                            <OpenLockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#3D82F1'
                            />
                          ) : (
                            <OpenLockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#595959'
                            />
                          )}
                          <div className='ml-5'>
                            <h1
                              className={`${
                                discount >= 22 &&
                                discount <= 25 &&
                                'text-[#3D82F1]'
                              } text-lg font-semibold`}
                            >
                              Tier 02
                            </h1>
                            <h1>
                              You can access 22% off after the 3th customer.
                            </h1>
                          </div>
                        </div>
                        <div className='flex items-center mb-5'>
                          {discount > 25 && discount <= 29 ? (
                            <OpenLockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#3D82F1'
                            />
                          ) : discount > 29 ? (
                            <OpenLockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#595959'
                            />
                          ) : (
                            <LockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#595959'
                            />
                          )}
                          <div className='ml-5'>
                            <h1
                              className={`${
                                discount >= 26 &&
                                discount < 30 &&
                                'text-[#3D82F1]'
                              } text-lg font-semibold`}
                            >
                              Tier 03
                            </h1>
                            <h1>
                              You can access 26% off after the 7th customer.
                            </h1>
                          </div>
                        </div>
                        <div className='flex items-center mb-5'>
                          {discount > 29 ? (
                            <OpenLockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#3D82F1'
                            />
                          ) : (
                            <LockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#595959'
                            />
                          )}
                          <div className='ml-5'>
                            <h1
                              className={`${
                                discount > 29 && 'text-[#3D82F1]'
                              } text-lg font-semibold`}
                            >
                              Tier 04
                            </h1>
                            <h1>
                              You can access 30% off after the 10th customer.
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {plan === 'Gold' && (
                    <div className='mt-5 mb-5 ml-5'>
                      <div>
                        <div className='flex items-center mb-4'>
                          {discount < 14 && discount > 0 ? (
                            <OpenLockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#3D82F1'
                            />
                          ) : discount >= 14 ? (
                            <OpenLockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#595959'
                            />
                          ) : (
                            <LockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#595959'
                            />
                          )}
                          <div className='ml-5'>
                            <h1
                              className={`${
                                discount < 14 &&
                                discount > 0 &&
                                'text-[#3D82F1] '
                              } font-semibold text-lg`}
                            >
                              Tier 01
                            </h1>
                            <h1>Access an 10% off in your plan.</h1>
                          </div>
                        </div>
                        <div className='flex items-center mb-4'>
                          {discount < 14 ? (
                            <LockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#595959'
                            />
                          ) : discount >= 14 && discount < 18 ? (
                            <OpenLockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#3D82F1'
                            />
                          ) : (
                            <OpenLockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#595959'
                            />
                          )}
                          <div className='ml-5'>
                            <h1
                              className={`${
                                discount >= 14 &&
                                discount < 18 &&
                                'text-[#3D82F1]'
                              } text-lg font-semibold`}
                            >
                              Tier 02
                            </h1>
                            <h1>
                              You can access 14% off after the 3th customer.
                            </h1>
                          </div>
                        </div>
                        <div className='flex items-center mb-4'>
                          {discount >= 18 && discount < 22 ? (
                            <OpenLockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#3D82F1'
                            />
                          ) : discount >= 18 && discount > 22 ? (
                            <OpenLockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#595959'
                            />
                          ) : (
                            <LockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#595959'
                            />
                          )}
                          <div className='ml-5'>
                            <h1
                              className={`${
                                discount >= 18 &&
                                discount < 22 &&
                                'text-[#3D82F1]'
                              } text-lg font-semibold`}
                            >
                              Tier 03
                            </h1>
                            <h1>
                              You can access 18% off after the 5th customer.
                            </h1>
                          </div>
                        </div>
                        <div className='flex items-center mb-4'>
                          {discount >= 22 ? (
                            <OpenLockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#3D82F1'
                            />
                          ) : (
                            <LockCopy
                              className='h-6 w-6 min-w-[24px] min-h-[24px]'
                              fill='#595959'
                            />
                          )}
                          <div className='ml-5'>
                            <h1
                              className={`${
                                discount >= 22 && 'text-[#3D82F1]'
                              } text-lg font-semibold`}
                            >
                              Tier 04
                            </h1>
                            <h1>
                              You can access 22% off after the 10th customer.
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {plan !== 'Platinum' && plan !== 'Gold' && (
                    <div className='m-5'>
                      {' '}
                      <h1>
                        Discounts by levels are given to Partners that are{' '}
                        <b>Gold</b> or <b>Platinum</b>, contact{' '}
                        <a
                          className='text-blue-500 hover:underline focus:outline-none'
                          href='mailto:partners@wazuh.com'
                        >
                          partners@wazuh.com
                        </a>{' '}
                        for more details.
                      </h1>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
