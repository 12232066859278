import React from 'react';
import ChartInfo from '../Popover/ChartInfo';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  Tooltip,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';
import Oval from '../img/Oval.svg';

const RevenuePerYear = ({ customers, loading }) => {
  const sumAmountPerYear = (data) => {
    const amountsByYear = {};

    data.forEach((item) => {
      const year = new Date(item.Start_Date__c).getFullYear();

      if (!amountsByYear[year]) {
        amountsByYear[year] = {
          year: year.toString(),
          startsWithR: 0,
          doesNotStartWithR: 0,
          totalAmount: 0,
          itemCountStartsWithR: 0,
          itemCountDoesNotStartWithR: 0,
          percentageStartsWithR: 0,
          percentageDoesNotStartWithR: 0,
        };
      }

      if (/R\d+/.test(item.Name)) {
        amountsByYear[year].startsWithR += item.Amount;
        amountsByYear[year].itemCountStartsWithR += 1;
      } else {
        amountsByYear[year].doesNotStartWithR += item.Amount;
        amountsByYear[year].itemCountDoesNotStartWithR += 1;
      }

      amountsByYear[year].totalAmount =
        amountsByYear[year].startsWithR + amountsByYear[year].doesNotStartWithR;

      // Calculate percentages with one decimal place
      if (amountsByYear[year].totalAmount !== 0) {
        amountsByYear[year].percentageStartsWithR = parseFloat(
          (
            (amountsByYear[year].startsWithR /
              amountsByYear[year].totalAmount) *
            100
          ).toFixed(1)
        );
        amountsByYear[year].percentageDoesNotStartWithR = parseFloat(
          (
            (amountsByYear[year].doesNotStartWithR /
              amountsByYear[year].totalAmount) *
            100
          ).toFixed(1)
        );
      }
    });

    return Object.values(amountsByYear);
  };
  const summedData = sumAmountPerYear(customers);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='bg-white border-2 rounded-lg border-gray-300 p-4'>
          <p className='px-2'>{`${label}`}</p>
          {payload.map((pld, index) => {
            let renewalPercentage = pld.payload.percentageStartsWithR;
            let newDealPercentage = pld.payload.percentageDoesNotStartWithR;
            let renewalDecimal = (pld.payload.percentageStartsWithR % 1)
              .toString()
              .split('.')[1];
            let newDealDecimal = (pld.payload.percentageDoesNotStartWithR % 1)
              .toString()
              .split('.')[1];
            if (renewalDecimal > newDealDecimal) {
              renewalPercentage = Math.ceil(renewalPercentage);
              newDealPercentage = Math.floor(newDealPercentage);
            } else {
              renewalPercentage = Math.floor(renewalPercentage);
              newDealPercentage = Math.ceil(newDealPercentage);
            }
            return (
              <div className=' px-2 flex flex-col'>
                {index < payload.length && (
                  <span className='form-divider h-1 mr-0 lg:mr-6 2xl:mr-3 hidden md:block my-2'></span>
                )}
                <div style={{ color: pld.fill }}>
                  {`${pld.name}: $ ${pld.value.toLocaleString()}`}
                </div>
                <div style={{ color: pld.fill }}>
                  {`${
                    pld.name === 'Renewals'
                      ? `Count: ${pld.payload.itemCountStartsWithR}`
                      : `Count: ${pld.payload.itemCountDoesNotStartWithR}`
                  }`}
                </div>
                <div style={{ color: pld.fill }}>
                  {`${
                    pld.name === 'Renewals'
                      ? `Total percentage: ${renewalPercentage}%`
                      : `Total percentage: ${newDealPercentage}%`
                  }`}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const CustomYAxisTick = (props) => {
    const { x, y, payload } = props;
    const formattedValue =
      payload.value >= 1000 ? `${payload.value / 1000}K` : payload.value;

    return (
      <g transform={`translate(${x - 17},${y - 11})`}>
        <text x={0} y={0} dy={16} textAnchor='middle' fill='#666'>
          {formattedValue}
        </text>
      </g>
    );
  };

  const CustomLegend = ({ payload }) => {
    return (
      <div className='flex items-center justify-center gap-12'>
        {payload.map((entry, index) => (
          <div key={`legend-${index}`} className='mr-4 flex items-center'>
            <div
              className='w-[14px] h-[10.5px] mr-2'
              style={{ backgroundColor: entry.color }}
            ></div>
            <span style={{ color: entry.color }}>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className=''>
      <div className=''>
        <div className='flex items-center'>
          <h1 className='text-[22px] font-semibold text-left mr-1'>
            Revenue per year
          </h1>
          <ChartInfo
            text={
              'Calculates the revenue per year that the partner has earned on successfuly closed contracts, separated between Recurrent and New deals. Only registers currently active contracts for recurrent services, does not include professional hours and trainings.'
            }
          />
        </div>

        <span className='form-divider h-1 mr-0 lg:mr-6 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
      </div>
      {loading === true ? (
        <div className='flex items-center justify-center bg-[#F6F6F6] h-[450px]'>
          <img src={Oval}></img>
        </div>
      ) : loading === false && customers.length <= 0 ? (
        <div className='flex items-center justify-center bg-[#F6F6F6] h-[450px]'>
          Register your first customer to view this chart
        </div>
      ) : (
        <div className='rounded-lg p-3 bg-[#F6F6F6]'>
          <div className='justify-start flex items-center'>
            <ResponsiveContainer width='99%' height={424}>
              <BarChart
                width={600}
                height={300}
                data={summedData}
                margin={{
                  top: 40,
                  right: 40,
                  left: 25,
                  bottom: 20,
                }}
              >
                <defs>
                  <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor='#3585F9' stopOpacity={0.8} />
                    <stop offset='95%' stopColor='#8884d8' stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='year' />
                <YAxis
                  dataKey='totalAmount'
                  tickFormatter={(value) =>
                    value >= 1000 ? `${value / 1000}K` : value
                  }
                  tick={CustomYAxisTick}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  content={<CustomLegend />}
                  wrapperStyle={{ marginLeft: 40 }}
                />

                <Bar
                  dataKey='startsWithR'
                  stackId='a'
                  fill='#48E3FF'
                  name='Renewals'
                  radius={[10, 10, 0, 0]}
                >
                  {summedData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      radius={
                        entry.doesNotStartWithR ? [0, 0, 0, 0] : [10, 10, 0, 0]
                      }
                    />
                  ))}
                </Bar>
                <Bar
                  dataKey='doesNotStartWithR'
                  stackId='a'
                  fill='#3240FF'
                  name='New deals'
                >
                  {summedData.map((entry, index) => (
                    <Cell key={`cell-${index}`} radius={[10, 10, 0, 0]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default RevenuePerYear;
