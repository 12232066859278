import { Popover, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import Interrogation from '../../components/img/Interrogation.svg';

const CloudInfo = ({ text }) => {
  const [isShowing, setIsShowing] = useState(false);
  return (
    <div className=''>
      <Popover className='relative'>
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
               `}
            >
              <img
                className='w-3 xl:w-5'
                alt='notification icon'
                onMouseEnter={() => setIsShowing(true)}
                onMouseLeave={() => setIsShowing(false)}
                src={Interrogation}
              ></img>
            </Popover.Button>
            <Transition
              show={isShowing}
              onMouseEnter={() => setIsShowing(true)}
              onMouseLeave={() => setIsShowing(false)}
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute ml-2 bottom-10 -right-1 z-10 mt-3 w-screen max-w-sm  transform px-4 sm:px-0 lg:max-w-[350px]'>
                <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                  <div className=' bg-[#404040]'>
                    <div
                      style={{
                        right: '5px',
                        clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
                        position: 'absolute',
                      }}
                      className='block h-5 w-5 bg-inherit border-inherit absolute -bottom-2 -rotate-[45deg] rounded-br-lg'
                    ></div>
                    <a
                      href='##'
                      className='flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out  focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'
                    >
                      <span className='flex items-center'>
                        <span className='text-sm font-medium text-white'>
                          {text}
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default CloudInfo;
