import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import useAuth from '../../../hooks/useAuth';
import { useRegisterUserMutation } from '../../../redux/features/user/authApiSlice';
import {
  useGetAllUsersByPartnerMutation,
  useDeleteUserMutation,
} from '../../../redux/features/user/usersApiSlice';
import AddUser from '../../../components/Forms/Add User/AddUser';
import User from '../../../components/User';

const ManageUsers = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [onCreateTriggered, setOnCreateTriggered] = useState(false);
  const userinfo = useAuth();
  const [registerUser] = useRegisterUserMutation();
  const [getAllUsers] = useGetAllUsersByPartnerMutation();
  const [deleteUser] = useDeleteUserMutation();

  const onCreate = async (username, email) => {
    try {
      await registerUser({
        username: username,
        email: email,
        role: 'user',
        partnerId: `${userinfo.partnerId}`,
      }).unwrap();

      const getUsers = await getAllUsers(userinfo.partnerId).unwrap();
      setAllUsers(getUsers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      const result = await getAllUsers(userinfo.partnerId).unwrap();
      setAllUsers(result);
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onCreateTriggered) {
      const getAllUsers = async () => {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/getallusersbypartner/${userinfo.partnerId}`
        );
        setAllUsers(result.data);
        setOnCreateTriggered(false);
      };
      getAllUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCreateTriggered]);

  const onEdit = async (id, username, email) => {
    try {
      const { data: response } = await axios
        .put(`${process.env.REACT_APP_API_URL}/api/users/edituser/${id}`, {
          username: username,
          email: email,
        })
        .then(() => {
          const getUsers = async () => {
            const result = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/users/getallusersbypartner/${userinfo.partnerId}`
            );
            setAllUsers(result.data);
          };
          getUsers();
        });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const onDelete = async (id) => {
    try {
      await deleteUser(id).unwrap();
      const getUsers = await getAllUsers(userinfo.partnerId).unwrap();
      setAllUsers(getUsers);
    } catch (error) {
      console.log(error);
    }
  };

  const users2 = allUsers.filter((obj) => obj.role === 'user');
  const admins2 = allUsers.filter((obj) => obj.role === 'admin');
  const numberedAdmins = admins2.map((obj, index) => {
    return { ...obj, number: `Admin ${index + 1}` };
  });

  const numberedUsers = users2.map((obj, index) => {
    return { ...obj, number: `User ${index + 1}` };
  });

  const numberedObjects = [...numberedAdmins, ...numberedUsers];

  return (
    <div className='mb-8'>
      <h1 className='text-[22px] mt-4 font-semibold'>Authorized Users</h1>
      <span className='form-divider h-1 mr-0 lg:mr-11 2xl:mr-3 hidden md:block mt-2 mb-2'></span>
      <div className='grid grid-cols-1'>
        <div className=' flex flex-col'>
          {numberedObjects.map((user, key) => {
            // if (key % 2 == 0) {
            return (
              <User
                key={key}
                userId={userinfo.id}
                rolemap={user.role}
                id={user.id}
                email={user.email}
                username={user.username}
                onEdit={onEdit}
                onDelete={onDelete}
                key1={user.number}
              />
            );
            // }
          })}
        </div>
        {/* <div>
          {numberedObjects.map((user, key) => {
            if (key % 2 == 1) {
              return (
                <User
                  key={key}
                  userId={userinfo.id}
                  rolemap={user.role}
                  id={user.id}
                  email={user.email}
                  username={user.username}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  key1={user.number}
                />
              );
            }
          })}
        </div> */}
        {(userinfo.role === 'admin' || userinfo.role === 'wazuh') && (
          <div className='mt-10 2xl:col-span-2 mb-3'>
            <AddUser onCreate={onCreate} />
          </div>
        )}
      </div>
      {/* <div className='grid grid-cols-1  xl:grid-cols-2 2xl:hidden'>
        {numberedObjects.map((user, key) => {
          return (
            <User
              key={key}
              userId={userinfo.id}
              rolemap={user.role}
              id={user.id}
              email={user.email}
              username={user.username}
              onEdit={onEdit}
              onDelete={onDelete}
              key1={user.number}
            />
          );
        })}
        {(userinfo.role === 'admin' || userinfo.role === 'wazuh') && (
          <div className='mt-10 xl:col-span-2 mb-5'>
            <AddUser onCreate={onCreate} />
          </div>
        )}
      </div> */}
    </div>
  );
};

export default ManageUsers;
