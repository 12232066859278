import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  useGetDealByIdMutation,
  useGetAuthorMutation,
} from '../redux/features/user/dealsApiSlice';

//Icons
import User from '../components/img/User.js';
import Email from '../components/img/EmailIcon.js';
import Back from '../components/img/Back.svg';
import Flag from '../components/img/Flag.js';
import Calendar from '../components/img/Calendar.js';
import Training from '../components/img/Training.js';
import Hours from '../components/img/Hours.js';
import Oval from '../components/img/Oval.svg';
import PartnerExpire from '../components/Popover/PartnerExpire';
import Amount from '../components/img/Amount.js';
import Discount from '../components/img/Discount.js';
import Quantity from '../components/img/Quantity.js';
import ContactSection from '../components/AnalyticsPanel/ContactSection.js';
import useAuth from '../hooks/useAuth.js';
import {
  useGetPartnerByIdMutation,
  useGetPartnerOwnerMutation,
} from '../redux/features/user/partnersApiSlice.js';

const Company11 = () => {
  const [customer, setCustomer] = useState(null);
  const [index, setIndex] = useState(0);
  const [author, setAuthor] = useState('');
  const location = useLocation();
  const [getDealById] = useGetDealByIdMutation();
  const [getAuthor] = useGetAuthorMutation();
  const userinfo = useAuth();
  const [partnerData, setPartnerData] = useState(null);
  const [partnerOwnerData, setPartnerOwnerData] = useState(null);
  const [getPartner] = useGetPartnerByIdMutation();
  const [getPartnerOwner] = useGetPartnerOwnerMutation();

  useEffect(() => {
    const getPartnerData = async () => {
      const partnerInfo = await getPartner(userinfo.partnerId).unwrap();
      setPartnerData(partnerInfo);
    };
    getPartnerData();
  }, []);

  useEffect(() => {
    const getPartnerOwnerData = async () => {
      const partnerOwnerInfo = await getPartnerOwner(
        partnerData[0].salesforceId
      ).unwrap();
      setPartnerOwnerData(partnerOwnerInfo);
    };
    getPartnerOwnerData();
  }, [partnerData]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const customerId = location.pathname.split('/')[2];

  const today = new Date();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getDealById(customerId).unwrap();
        setCustomer(res);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [customerId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAuthor(customerId).unwrap();
        if (res.length > 0) {
          setAuthor(res[0].username);
        } else setAuthor(null);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [customerId]);

  function reformatDate(dateStr) {
    let dArr = dateStr.split('-'); // ex input "2010-01-18"
    return dArr[1] + '/' + dArr[2] + '/' + dArr[0]; //ex out: "18/01/10"
  }

  const isDealExpired = () => {
    return (
      Math.ceil(
        (new Date(customer[0].Contract_Expiration_Date__c) - today) /
          (1000 * 60 * 60 * 24)
      ) < 0
    );
  };

  const isDealExpiringSoon = () => {
    return (
      Math.ceil(
        (new Date(customer[0].Contract_Expiration_Date__c) - today) /
          (1000 * 60 * 60 * 24)
      ) > 0 &&
      Math.ceil(
        (new Date(customer[0].Contract_Expiration_Date__c) - today) /
          (1000 * 60 * 60 * 24)
      ) <= 30
    );
  };

  return (
    <div className=''>
      {!customer ? (
        <div className='flex mt-[50vh] -translate-y-32 justify-center items-center'>
          <img alt='loading' className='w-32' src={Oval} />
        </div>
      ) : (
        <>
          <div
            className={`text-lg sm:text-2xl 2xl:text-3xl justify-between font-semibold p-5 h-[72px] 2xl:h-[76px] xl:pl-[22px] 2xl:px-[40px] flex items-center rounded-2xl m-3 lg:m-7 ${
              isDealExpired() ? 'bg-[#A2ABBA]' : 'bg-[#3D82F1]'
            }  text-white`}
          >
            <div className='items-center'>
              <div className='font-semibold text-2xl 2xl:text-3xl flex items-center'>
                {customer[0].Partner_Client__c}
              </div>
            </div>
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-3'>
            <div className='xl:mx-0 max-lg:pl-5 my-5 2xl:m-5 lg:pl-[30px] xl:pl-[37px] 2xl:pl-0 2xl:mx-[55px] col-span-2'>
              <h1 className='text-xl 2xl:text-[22px] font-semibold text-left max-md:mb-[30px]'>
                Information
              </h1>

              <span className='form-divider h-1 mr-0 lg:mr-16 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-y-9'>
                <div className='flex items-center'>
                  <div>
                    <User
                      className='mr-2 w-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={isDealExpired() ? '#A2ABBA' : '#3D82F1'}
                    />
                  </div>
                  <div className='flex'>
                    <h1
                      className={`font-semibold leading-tight 2xl:text-lg ${
                        isDealExpired() ? 'text-[#A2ABBA]' : 'text-[#3D82F1]'
                      }`}
                    >
                      Contact person:&nbsp;
                    </h1>
                    <h3 className='2xl:text-lg leading-tight'>
                      {customer[0].Contact_Name__c}
                    </h3>
                  </div>
                </div>
                <div className='flex items-center'>
                  <div>
                    <Email
                      className='mr-2 w-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={isDealExpired() ? '#A2ABBA' : '#3D82F1'}
                    />
                  </div>
                  <div className='flex'>
                    <h1
                      className={`font-semibold leading-tight 2xl:text-lg ${
                        isDealExpired() ? 'text-[#A2ABBA]' : 'text-[#3D82F1]'
                      }`}
                    >
                      Email:&nbsp;
                    </h1>
                    <h3 className='2xl:text-lg leading-tight'>
                      {customer[0].Contact_Email__c}
                    </h3>
                  </div>
                </div>
                <div className='flex items-center'>
                  <div>
                    <Calendar
                      className='mr-2 w-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={isDealExpired() ? '#A2ABBA' : '#3D82F1'}
                    />
                  </div>
                  <div className='flex'>
                    <h1
                      className={`font-semibold leading-tight 2xl:text-lg ${
                        isDealExpired() ? 'text-[#A2ABBA]' : 'text-[#3D82F1]'
                      }`}
                    >
                      Start date:&nbsp;
                    </h1>
                    <h3 className='2xl:text-lg leading-tight'>
                      {reformatDate(customer[0].Start_Date__c)}
                    </h3>
                  </div>
                </div>
                <div className='flex items-center'>
                  <div>
                    <Calendar
                      className='mr-2 w-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={isDealExpired() ? '#A2ABBA' : '#3D82F1'}
                    />
                  </div>
                  <div className='flex'>
                    <h1
                      className={`font-semibold leading-tight 2xl:text-lg ${
                        isDealExpired() ? 'text-[#A2ABBA]' : 'text-[#3D82F1]'
                      }`}
                    >
                      Expiration date:&nbsp;
                    </h1>
                    <h3 className='2xl:text-lg leading-tight'>
                      {reformatDate(customer[0].Contract_Expiration_Date__c)}
                    </h3>
                    {isDealExpiringSoon() ? (
                      <div className='relative flex -left-36'>
                        <PartnerExpire type={'expiring'} />
                      </div>
                    ) : isDealExpired() ? (
                      <div className='relative flex -left-36'>
                        <PartnerExpire type={'expired'} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {/* <div className='flex'>
                  <div>
                    <img
                      alt='calendar'
                      className='mr-2 w-6'
                      src={Calendar}
                    ></img>
                  </div>
                  <div className='flex flex-col'>
                    <h1 className='font-semibold leading-tight text-lg'>
                      Expiry date
                    </h1>
                    <h3 className='mt-1'>
                      {customer[0].Contract_Expiration_Date__c}
                    </h3>
                    {Math.ceil(
                      (new Date(customer[0].Contract_Expiration_Date__c) -
                        today) /
                        (1000 * 60 * 60 * 24)
                    ) > 0 &&
                    Math.ceil(
                      (new Date(customer[0].Contract_Expiration_Date__c) -
                        today) /
                        (1000 * 60 * 60 * 24)
                    ) <= 30 ? (
                      <div className='relative flex -top-12 -left-12'>
                        <PartnerExpire type={'expiring'} />
                      </div>
                    ) : Math.ceil(
                        (new Date(customer[0].Contract_Expiration_Date__c) -
                          today) /
                          (1000 * 60 * 60 * 24)
                      ) < 0 ? (
                      <div className='relative flex -top-12 -left-12'>
                        <PartnerExpire type={'expired'} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div> */}
                <div className='flex items-center'>
                  <div>
                    <Quantity
                      className='mr-2 w-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={isDealExpired() ? '#A2ABBA' : '#3D82F1'}
                    />
                  </div>
                  <div className='flex'>
                    <h1
                      className={`font-semibold leading-tight 2xl:text-lg ${
                        isDealExpired() ? 'text-[#A2ABBA]' : 'text-[#3D82F1]'
                      }`}
                    >
                      Quantity:&nbsp;
                    </h1>
                    <h3 className='2xl:text-lg leading-tight'>
                      {customer.ProductInformation[index].Quantity}
                    </h3>
                  </div>
                </div>
                <div className='flex items-center'>
                  <div>
                    <Amount
                      className='mr-2 w-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={isDealExpired() ? '#A2ABBA' : '#3D82F1'}
                    />
                  </div>
                  <div className='flex'>
                    <h1
                      className={`font-semibold leading-tight 2xl:text-lg ${
                        isDealExpired() ? 'text-[#A2ABBA]' : 'text-[#3D82F1]'
                      }`}
                    >
                      List price:&nbsp;
                    </h1>
                    <h3 className='2xl:text-lg leading-tight'>
                      {'$ ' +
                        numberWithCommas(
                          customer.ProductInformation[index].UnitPrice
                        )}
                    </h3>
                  </div>
                </div>
                <div className='flex items-center'>
                  <div>
                    <Discount
                      className='mr-2 w-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={isDealExpired() ? '#A2ABBA' : '#3D82F1'}
                    />
                  </div>
                  <div className='flex'>
                    <h1
                      className={`font-semibold leading-tight 2xl:text-lg ${
                        isDealExpired() ? 'text-[#A2ABBA]' : 'text-[#3D82F1]'
                      }`}
                    >
                      Discount:&nbsp;
                    </h1>
                    <h3 className='2xl:text-lg leading-tight'>
                      {customer.ProductInformation[index].Discount === null
                        ? '0% off'
                        : customer.ProductInformation[index].Discount + '% off'}
                    </h3>
                  </div>
                </div>
                <div className='flex items-center'>
                  <div>
                    <Amount
                      className='mr-2 w-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={isDealExpired() ? '#A2ABBA' : '#3D82F1'}
                    />
                  </div>
                  <div className='flex'>
                    <h1
                      className={`font-semibold leading-tight 2xl:text-lg ${
                        isDealExpired() ? 'text-[#A2ABBA]' : 'text-[#3D82F1]'
                      }`}
                    >
                      Partner price:&nbsp;
                    </h1>
                    <h3 className='2xl:text-lg leading-tight'>
                      {'$ ' +
                        numberWithCommas(
                          customer.ProductInformation[index].TotalPrice
                        )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className=' col-span-1 pt-5'>
              <h1 className='text-xl 2xl:text-[22px] font-semibold text-left max-md:mb-[30px]'>
                Service requested
              </h1>

              <span className='form-divider h-1 mr-0 lg:mr-16 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
              <div className='flex flex-col'>
                <div className='grid grid-cols-1 gap-y-9'>
                  <div className='flex items-center'>
                    <div>
                      <Flag
                        className='mr-2 w-[41px]'
                        bgfill={'#EBF2FB'}
                        mainfill={isDealExpired() ? '#A2ABBA' : '#3D82F1'}
                      />
                    </div>
                    <div className='flex items-center'>
                      <h1
                        className={`font-semibold leading-tight 2xl:text-lg ${
                          isDealExpired() ? 'text-[#A2ABBA]' : 'text-[#3D82F1]'
                        }`}
                      >
                        Plan:&nbsp;
                      </h1>
                      <h3 className=' text-sm leading-tight max-w-[400px]'>
                        {customer.ProductInformation !== undefined &&
                        customer.ProductInformation.length > 1 ? (
                          <select
                            className='styled-select w-32 sm:w-auto max-w-[270px] overflow-ellipsis pr-6 text-lg text-blue-500 border pl-2 rounded-md'
                            onChange={(e) => setIndex(e.target.value)}
                          >
                            {customer.ProductInformation.map(
                              (product, index) => (
                                <option key={index} value={index}>
                                  {product.ProductName}
                                </option>
                              )
                            )}
                          </select>
                        ) : customer.ProductInformation !== undefined &&
                          customer.ProductInformation.length === 1 ? (
                          <h3 className='2xl:text-lg leading-tight'>
                            {customer.ProductInformation[0].ProductName}
                          </h3>
                        ) : (
                          '-'
                        )}
                      </h3>
                    </div>
                  </div>

                  <div className='flex items-center'>
                    <div>
                      <Training
                        className='mr-2 w-[41px]'
                        bgfill={'#EBF2FB'}
                        mainfill={isDealExpired() ? '#A2ABBA' : '#3D82F1'}
                      />
                    </div>
                    <div className='flex'>
                      <h1
                        className={`font-semibold leading-tight 2xl:text-lg ${
                          isDealExpired() ? 'text-[#A2ABBA]' : 'text-[#3D82F1]'
                        }`}
                      >
                        Training:&nbsp;
                      </h1>
                      <h3 className='2xl:text-lg leading-tight'>
                        {customer[0].Training__c}
                      </h3>
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <div>
                      <Hours
                        className='mr-2 w-[41px]'
                        bgfill={'#EBF2FB'}
                        mainfill={isDealExpired() ? '#A2ABBA' : '#3D82F1'}
                      />
                    </div>
                    <div className='flex'>
                      <h1
                        className={`font-semibold leading-tight 2xl:text-lg ${
                          isDealExpired() ? 'text-[#A2ABBA]' : 'text-[#3D82F1]'
                        }`}
                      >
                        Professional hours:&nbsp;
                      </h1>
                      <h3 className='2xl:text-lg leading-tight'>
                        {customer[0].Professional_Hours__c}
                      </h3>
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <div>
                      <User
                        className='mr-2 w-[41px]'
                        bgfill={'#EBF2FB'}
                        mainfill={isDealExpired() ? '#A2ABBA' : '#3D82F1'}
                      />
                    </div>
                    <div className='flex'>
                      <h1
                        className={`font-semibold leading-tight 2xl:text-lg ${
                          isDealExpired() ? 'text-[#A2ABBA]' : 'text-[#3D82F1]'
                        }`}
                      >
                        Registered by:&nbsp;
                      </h1>
                      <h3 className='2xl:text-lg leading-tight'>
                        {author === null ? 'Wazuh Sales' : author}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='mx-4 lg:mx-10 2xl:mx-14 mt-16'>
            <ContactSection ownerData={partnerOwnerData} />
          </div>
          {/* {JSON.stringify(partnerOwnerData)} */}
          <div className='flex mt-12 mx-5 lg:mx-0  2xl:mx-[55px] lg:pl-[30px] xl:pl-[37px] 2xl:pl-0 2xl:max-w-[1500px] mb-5 2xl:mb-5'>
            <Link
              className='btn bg-yellow-gradient px-11 py-[5px] mb-4 rounded-lg flex items-center'
              to='/my-customers'
            >
              <img alt='back' className='w-3 mr-2' src={Back}></img>
              <h3 className=''>Back</h3>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Company11;
