import * as React from 'react';
const Export = (props) => (
  <svg
    width='41'
    height='41'
    viewBox='0 0 41 41'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='20.5' cy='20.5' r='20.5' fill={props.bgfill} />
    <path
      d='m30.51 24-5.4-3.818c-.702-.496-1.61.073-1.61.999v7.635c0 .926.908 1.495 1.61 1l5.4-3.819c.654-.462.654-1.535 0-1.997Z'
      fill={props.mainfill}
    />
    <path
      d='M28.404 20c0-4.083-3.321-7.404-7.404-7.404S13.596 15.917 13.596 20s3.321 7.404 7.404 7.404c.157 0 .312-.005.466-.015.41-.025.781.285.781.697a.63.63 0 0 1-.572.638A8.75 8.75 0 0 1 12.25 20 8.75 8.75 0 0 1 21 11.25a8.75 8.75 0 0 1 8.713 9.558c-.045.497-.61.725-1.021.442a.744.744 0 0 1-.31-.674 7.46 7.46 0 0 0 .022-.576Z'
      fill={props.mainfill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.962 19.327h3.366v-4.712a.673.673 0 0 1 1.346 0V20a.673.673 0 0 1-.673.673h-4.039a.673.673 0 0 1 0-1.346Z'
      fill={props.mainfill}
    />
  </svg>
);
export default Export;
