import React, { Fragment, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import RightArrow from '../components/img/RightArrow.svg';
import Stage from '../components/Stage';
import Oval from '../components/img/Oval.svg';
import BlueInfo from '../components/img/BlueInfo.svg';
import TableType from '../components/TableType';
import PendingApproval from '../components/Popover/PendingApproval';
import WhiteRightArrow from '../components/img/WhiteRightArrow.svg';

const RegisteredDealsTable = ({
  loading,
  data,
  stage,
  setSort,
  setOrientation,
}) => {
  const location = useLocation();
  const pathname = location.pathname.split('/')[1];
  const navigate = useNavigate();
  const handleRowClick = (type, id) => {
    if (type === 'Opportunity') {
      navigate(`/deal/${id}`);
    } else if (type === 'Customer') {
      navigate(`/customer/${id}`);
    } else {
      navigate(`/lead/${id}`);
    }
  };
  const [lastSort, setLastSort] = useState('Partner_Client__c');
  const [lastOrientation, setLastOrientation] = useState('ascending');
  // const [dealsList, setDealsList] = useState(null);

  // console.log('data: ', data);

  // useEffect(() => {
  //   const getDeals = async () => {
  //     const result = await axios.get(
  //       'http://localhost:3000/api/deals/getdeals'
  //     );
  //     setDealsList(result.data.records);
  //   };
  //   getDeals();
  // }, []);

  function reformatDate(dateStr) {
    let dArr = dateStr.split('-'); // ex input "2010-01-18"
    return dArr[1] + '/' + dArr[2] + '/' + dArr[0]; //ex out: "18/01/10"
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const setSortAndOrientation = (orientation, sort) => {
    if (orientation === lastOrientation && sort === lastSort) {
      setOrientation('descending');
      setSort(sort);
      setLastOrientation('descending');
    } else {
      setOrientation('ascending');
      setSort(sort);
      setLastOrientation('ascending');
    }
    setLastSort(sort);
  };

  return (
    <div>
      {!data.length && loading === true ? (
        <div className='flex justify-center m-12'>
          <img alt='loading' src={Oval} />
        </div>
      ) : stage == 'Closed Lost' ? (
        <div className='flex'>
          <div className='mx-9 lg:mx-9 2xl:mx-14 bg-[#E6F1FA] flex p-2 mt-4'>
            <img className='mr-2' src={BlueInfo}></img> Your Closd Lost deals
            after Partners Portal release (June ’23) will be shown in this
            section
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{ scrollbarGutter: 'stable' }}
            className='hidden md:grid grid-cols-24 mx-8 md:max-h-[calc(100vh-205px)] lg:max-h-[calc(100vh-270px)] xl:max-h-[calc(100vh-230px)] 2xl:max-h-[calc(100vh-300px)] 3xl:max-h-[650px] overflow-y-auto 2xl:mr-[30px]'
          >
            <div className=' bg-[#292929] text-white col-span-6 rounded-l-lg pl-4  text-sm  xl:text-lg font-semibold py-2 sticky top-0'>
              <div className='flex items-center'>
                <button
                  className='flex ml-5 items-center'
                  onClick={() => {
                    setSortAndOrientation('ascending', 'Partner_Client__c');
                  }}
                >
                  Company
                  {lastSort === 'Partner_Client__c' && (
                    <img
                      className={`${
                        lastOrientation === 'ascending'
                          ? 'rotate-90'
                          : 'rotate-[270deg]'
                      } ml-2`}
                      src={WhiteRightArrow}
                    ></img>
                  )}
                </button>
              </div>
            </div>
            <div className='bg-[#292929] text-white col-span-6 text-sm  xl:text-lg font-semibold py-2 sticky top-0'>
              <button
                className='flex items-center'
                onClick={() => {
                  setSortAndOrientation('ascending', 'Type');
                }}
              >
                Plan
                {lastSort === 'Type' && (
                  <img
                    className={`${
                      lastOrientation === 'ascending'
                        ? 'rotate-90'
                        : 'rotate-[270deg]'
                    } ml-2`}
                    src={WhiteRightArrow}
                  ></img>
                )}
              </button>
            </div>
            <div className='bg-[#292929] text-white col-span-6 text-sm  xl:text-lg font-semibold py-2 sticky top-0'>
              <button
                className='flex items-center'
                onClick={() => {
                  setSortAndOrientation('ascending', 'CloseDate');
                }}
              >
                Expiration date
                {lastSort === 'CloseDate' && (
                  <img
                    className={`${
                      lastOrientation === 'ascending'
                        ? 'rotate-90'
                        : 'rotate-[270deg]'
                    } ml-2`}
                    src={WhiteRightArrow}
                  ></img>
                )}
              </button>
            </div>
            <div className=' bg-[#292929] text-white text-sm  xl:text-lg col-span-5 font-semibold py-2 sticky top-0 z-[9]'>
              <button
                className='flex items-center'
                onClick={() => {
                  setSortAndOrientation('ascending', 'StageName');
                }}
              >
                Stage
                {lastSort === 'StageName' && (
                  <img
                    className={`${
                      lastOrientation === 'ascending'
                        ? 'rotate-90'
                        : 'rotate-[270deg]'
                    } ml-2`}
                    src={WhiteRightArrow}
                  ></img>
                )}
              </button>
            </div>
            <div className='bg-[#292929] text-white rounded-r-lg col-span-1 text-sm  xl:text-lg font-semibold py-2 sticky top-0'></div>
            {data.map((deal) => (
              <Fragment key={deal.Id}>
                {deal.StageName === 'Pending approval' ? (
                  <>
                    <div
                      className={`rowWrapper rowWrapperProspect hover:cursor-pointer ${
                        pathname === 'dashboard' ? 'row-limiter' : ''
                      }`}
                      onClick={() => handleRowClick(deal.dealType, deal.Id)}
                    >
                      <div className='items-center text-sm xl:text-base hover:rounded-l-lg flex pl-4  col-span-6 py-2 my-2 pr-6 text-[#A2ABBA]'>
                        <div className='ml-5 overflow-ellipsis max-w-[300px] truncate'>
                          {deal.Partner_Client__c}
                        </div>
                      </div>
                      <div className='col-span-6 text-sm xl:text-base -200 items-center flex  py-2 my-2 pr-6 text-[#A2ABBA]'>
                        {deal.Type}
                      </div>
                      <div className='items-center text-sm xl:text-base col-span-6  flex  py-2 my-2 pr-6 text-[#A2ABBA]'>
                        {reformatDate(deal.CloseDate)}
                      </div>
                      <div className='col-span-5 text-sm xl:text-base items-center flex  py-2 my-2 pr-6 text-[#A2ABBA]'>
                        <>
                          {deal.StageName}
                          <PendingApproval />
                        </>
                      </div>
                      <div className='items-center justify-center  flex  py-2 my-2'>
                        <Link to={`/deal/${deal.Id}`}>
                          <img
                            alt='right arrow'
                            className='hover:bg-blue-300 rounded-3xl p-2'
                            src={RightArrow}
                          ></img>
                        </Link>
                      </div>
                    </div>
                    <span
                      className={`table-divider cols-span-24 h-1 mx-4 ${
                        pathname === 'dashboard' ? 'border-row-limiter' : ''
                      }`}
                    ></span>
                  </>
                ) : (
                  <>
                    <div
                      className={`rowWrapper rowWrapperProspect hover:cursor-pointer ${
                        pathname === 'dashboard' ? 'row-limiter' : ''
                      }`}
                      onClick={() => handleRowClick(deal.dealType, deal.Id)}
                    >
                      <div className='items-center text-sm xl:text-base hover:rounded-l-lg flex pl-4  col-span-6 py-2 my-2 pr-6'>
                        <div className='ml-5 overflow-ellipsis max-w-[300px] truncate'>
                          {deal.Partner_Client__c}
                        </div>
                      </div>
                      <div className='col-span-6 text-sm xl:text-base -200 items-center flex  py-2 my-2 pr-6'>
                        {deal.Type}
                      </div>
                      <div className='items-center text-sm xl:text-base col-span-6  flex  py-2 my-2 pr-6'>
                        {reformatDate(deal.CloseDate)}
                      </div>
                      <div className='col-span-5 text-sm xl:text-base items-center flex  py-2 my-2 pr-6'>
                        {/* <Stage stage={deal.StageName} /> */}
                        {deal.StageName}
                      </div>
                      <div className='items-center justify-center  flex  py-2 my-2'>
                        <Link to={`/deal/${deal.Id}`}>
                          <img
                            alt='right arrow'
                            className='hover:bg-blue-300 rounded-3xl p-2'
                            src={RightArrow}
                          ></img>
                        </Link>
                      </div>
                    </div>
                    <span
                      className={`table-divider cols-span-24 h-1 mx-4 ${
                        pathname === 'dashboard' ? 'border-row-limiter' : ''
                      }`}
                    ></span>
                  </>
                )}

                {/* <span className='col-span-12 h-2 bg-blue-400 hover:bg-blue-400'></span> */}
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisteredDealsTable;
