import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import ProfileIcon from '../components/img/ProfileIcon.svg';
import { useGetPartnerByIdMutation } from '../redux/features/user/partnersApiSlice';
import { useSendLogoutMutation } from '../redux/features/user/authApiSlice';
import Logout from '../components/img/MobileMenu/Logout.svg';
import { useNavigate } from 'react-router-dom';

const MobileUserData = () => {
  const [partnerData, setPartnerData] = useState(null);
  const [profilePicc, setProfilePicc] = useState('');

  const userinfo = useAuth();
  const [getPartner] = useGetPartnerByIdMutation();
  const [sendLogout, { isLoading, isSuccess, isError, error }] =
    useSendLogoutMutation();
  const navigate = useNavigate();

  useEffect(() => {
    const getPartnerData = async () => {
      const result = await getPartner(userinfo.partnerId).unwrap();
      setPartnerData(result);
    };
    getPartnerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getProfilePic = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/s3img/profile/${userinfo.partnerId}`
      );
      setProfilePicc(result.data);
    };
    if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
      getProfilePic();
    }
  }, []);

  useEffect(() => {
    if (isSuccess) navigate('/');
  }, [isSuccess, navigate]);
  return (
    <div>
      <div className='flex justify-between'>
        {!partnerData ? (
          <div></div>
        ) : (
          <>
            <div className='flex items-center ml-4'>
              <img
                alt='profile'
                className='rounded-full w-10 ml-4 h-10 mr-2 object-contain'
                src={ProfileIcon}
              ></img>
              <div className='flex-col mt-2 sm:ml-6'>
                <h3 className=' font-semibold'>Hi {userinfo.username}!</h3>
                <h3 className=''>{userinfo.email}</h3>
              </div>
            </div>
            <div className='flex flex-col text-center items-end justify-center sm:justify-end'>
              <button
                onClick={sendLogout}
                className='flex items-end ml-4  flex-col px-4 py-2 text-[#3D82F1] hover:underline'
              >
                <img alt='logout' className=' w-5 h-5' src={Logout}></img>
                <p className='hidden sm:block'>Log out</p>
              </button>
            </div>
          </>
        )}
      </div>
      <div className=' border-b-2 border-gray-300 mx-4 mt-4'></div>
    </div>
  );
};

export default MobileUserData;
