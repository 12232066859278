import React, { useState, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Navbar from '../components/Navbar';
import Lock from '../components/img/Lock.svg';
import eye from '../components/img/eye.svg';
import eyeclosed from '../components/img/eyeClosed.svg';
import PasswordValid from '../components/img/PasswordValid.svg';
import PasswordInvalid from '../components/img/PasswordInvalid.svg';
import { Popover, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [err, setErr] = useState(null);
  const [fullfill, setFullfill] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const id = location.pathname.split('/')[2];
  const token = location.pathname.split('/')[3];
  const password_regex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%.]).{8,}$/;
  const passwordInitialValues = {
    newpassword: '',
    confirmnewpassword: '',
  };
  const passwordValidationSchema = Yup.object({
    newpassword: Yup.string()
      .required('New password is required!')
      .matches(password_regex, 'Weak password.'),
    confirmnewpassword: Yup.string().required(
      'Confirm New password is required!'
    ),
  });

  const handleUpdatePassword = async (formData) => {
    try {
      const resetPassword = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/password/reset-password/${id}/${token}`,
        formData
      );
      setFullfill(resetPassword.data);
      setTimeout(() => setFullfill(null), 5000);
      setTimeout(() => navigate('/'), 5000);
    } catch (error) {
      console.log(error);
      setErr(error.response.data);
      setTimeout(() => setErr(null), 5000);
    }
  };
  const [seeNewPassword, setSeeNewPassword] = useState(false);
  const [seeConfirmPassword, setSeeConfirmPassword] = useState(false);

  return (
    <div className='flex flex-col h-screen bg-[#EBF2FB] font-manrope'>
      <Navbar />
      <div className='bg-white flex flex-1 m-4 sm:m-16 rounded-xl'>
        <div className='flex flex-1 justify-center sm:justify-around items-center flex-col sm:flex-row'>
          <div className='flex flex-col items-center'>
            <h1 className=' text-xl sm:text-2xl font-semibold mb-4'>
              Reset your password
            </h1>
            <Formik
              validationSchema={passwordValidationSchema}
              initialValues={passwordInitialValues}
              onSubmit={handleUpdatePassword}
            >
              {({ errors, touched, isValid, dirty, values }) => (
                <Form>
                  {errors.newpassword && touched.newpassword ? (
                    <p className='font-semibold mb-2 text-red-500'>
                      {errors.newpassword}
                    </p>
                  ) : (
                    <h1 className='font-semibold'>New Password</h1>
                  )}
                  <div className='flex mt-3'>
                    <span className='bg-[#FBFCFD] inline-flex items-center px-3 text-sm text-gray-900 bg-gray-[#DBE3EB] rounded-l-md border border-r-0 border-gray-300'>
                      <img alt='lock' src={Lock} />
                    </span>
                    <Field
                      onFocus={() => setIsPopoverOpen(true)}
                      onBlur={() => setIsPopoverOpen(false)}
                      name='newpassword'
                      type={seeNewPassword ? 'text' : 'password'}
                      className='rounded-none  bg-gray-50 border border-l-0  text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-[200px] 2xl:w-[225px] text-sm border-gray-300 p-2.5'
                      placeholder='Password'
                    ></Field>
                    <span
                      onClick={() => setSeeNewPassword(!seeNewPassword)}
                      className='hover:cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-[#DBE3EB] rounded-r-md border border-l-0 border-gray-300'
                    >
                      {seeNewPassword ? (
                        <img alt='open eye' src={eye} />
                      ) : (
                        <img alt='closed eye' src={eyeclosed} />
                      )}
                    </span>
                    <div className='mt-2 ml-1'>
                      <div className='flex items-center'>
                        {/* {errors.newpassword && touched.newpassword ? (
                      <PasswordErrorPopOver />
                    ) : (
                      <PasswordPopOver />
                    )} */}
                        <div className='relative'>
                          <Popover className='relative'>
                            {({ open }) => (
                              <>
                                <Popover.Button
                                  className={`
                  ${open ? '' : 'text-opacity-90'}
                  group inline-flex items-center rounded-md text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                >
                                  {/* <img alt='notification icon' src={BlueNotification}></img> */}
                                </Popover.Button>
                                <Transition
                                  show={isPopoverOpen}
                                  as={Fragment}
                                  enter='transition ease-out duration-200'
                                  enterFrom='opacity-0 translate-y-1'
                                  enterTo='opacity-100 translate-y-0'
                                  leave='transition ease-in duration-150'
                                  leaveFrom='opacity-100 translate-y-0'
                                  leaveTo='opacity-0 translate-y-1'
                                >
                                  <Popover.Panel className='absolute -right-8 -top-[210px] sm:-top-[175px] xl:-right-7 2xl:-right-0 z-10 w-[300px]  transform px-4 sm:px-0 lg:max-w-[300px]'>
                                    <div className='block h-5 w-5 border-inherit absolute -top-2 triangle-password bg-[#404040]'></div>
                                    <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                                      <div className='bg-[#404040] py-2 px-2'>
                                        <a
                                          href='##'
                                          className='flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'
                                        >
                                          <span className='block text-sm text-white'>
                                            Your password must have the
                                            following:
                                            <br />
                                            <div className='flex'>
                                              {values.newpassword.length >=
                                              8 ? (
                                                <img
                                                  className='mr-1'
                                                  src={PasswordValid}
                                                ></img>
                                              ) : (
                                                <img
                                                  className='mr-1'
                                                  src={PasswordInvalid}
                                                ></img>
                                              )}
                                              Minimum 8 characters in length.
                                            </div>
                                            <div className='flex'>
                                              {/^(?=.*[A-Z])/.test(
                                                values.newpassword
                                              ) ? (
                                                <img
                                                  className='mr-1'
                                                  src={PasswordValid}
                                                ></img>
                                              ) : (
                                                <img
                                                  className='mr-1'
                                                  src={PasswordInvalid}
                                                ></img>
                                              )}
                                              At least 1 uppercase letter.
                                            </div>
                                            <div className='flex'>
                                              {/^(?=.*[a-z])/.test(
                                                values.newpassword
                                              ) ? (
                                                <img
                                                  className='mr-1'
                                                  src={PasswordValid}
                                                ></img>
                                              ) : (
                                                <img
                                                  className='mr-1'
                                                  src={PasswordInvalid}
                                                ></img>
                                              )}
                                              At least 1 lowercase letter.
                                            </div>
                                            <div className='flex'>
                                              {/^(?=.*[0-9])/.test(
                                                values.newpassword
                                              ) ? (
                                                <img
                                                  className='mr-1'
                                                  src={PasswordValid}
                                                ></img>
                                              ) : (
                                                <img
                                                  className='mr-1'
                                                  src={PasswordInvalid}
                                                ></img>
                                              )}
                                              At least 1 number.
                                            </div>
                                            <div className='flex'>
                                              {/^(?=.*?[#!@$%.])/.test(
                                                values.newpassword
                                              ) ? (
                                                <img
                                                  className='mr-1'
                                                  src={PasswordValid}
                                                ></img>
                                              ) : (
                                                <img
                                                  className='mr-1'
                                                  src={PasswordInvalid}
                                                ></img>
                                              )}
                                              At least 1 special character
                                              (#!@$%.).
                                            </div>
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </>
                            )}
                          </Popover>
                        </div>
                        {/* <PasswordPopOver
                      password={values.newpassword}
                      isOpen={isPopoverOpen}
                    /> */}
                        {/* <p className='mb-1 ml-1'>Password must be 8+ characters</p> */}
                      </div>
                    </div>
                  </div>
                  {errors.confirmnewpassword && touched.confirmnewpassword ? (
                    <p className='font-semibold text-red-500 mt-4'>
                      {errors.confirmnewpassword}
                    </p>
                  ) : (
                    <h1 className='font-semibold mt-4'>Confirm New Password</h1>
                  )}
                  <div className='flex mt-3'>
                    <span className='bg-[#FBFCFD] inline-flex items-center px-3 text-sm text-gray-900 bg-gray-[#DBE3EB] rounded-l-md border border-r-0 border-gray-300'>
                      <img alt='lock' src={Lock} />
                    </span>
                    <Field
                      name='confirmnewpassword'
                      type={seeConfirmPassword ? 'text' : 'password'}
                      className='rounded-none  bg-gray-50 border border-l-0  text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-[200px] 2xl:w-[225px] text-sm border-gray-300 p-2.5'
                      placeholder='New Password'
                    ></Field>
                    <span
                      onClick={() => setSeeConfirmPassword(!seeConfirmPassword)}
                      className='hover:cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-[#DBE3EB] rounded-r-md border border-l-0 border-gray-300'
                    >
                      {seeConfirmPassword ? (
                        <img alt='open eye' src={eye} />
                      ) : (
                        <img alt='closed eye' src={eyeclosed} />
                      )}
                    </span>
                  </div>
                  {err && (
                    <p className='text-white text-center bg-red-300 p-3 mt-2 rounded-lg'>
                      {err === 'invalid signature'
                        ? 'Link expired, please request a new one!'
                          ? err === 'jwt expired'
                          : 'Link expired, please request a new one!'
                        : err}
                    </p>
                  )}
                  {fullfill && (
                    <p className='text-white text-center bg-blue-300 p-3 mt-2 rounded-lg'>
                      {fullfill}
                    </p>
                  )}
                  <div className='flex sm:block justify-center '>
                    {isValid && dirty ? (
                      <button
                        type='submit'
                        className='mt-4 sm:mt-9 text-white bg-[#3D82F1] hover:bg-blue-600 py-2 px-4 rounded-lg text-center w-full'
                      >
                        Change Password
                      </button>
                    ) : (
                      <div className='mt-4 sm:mt-9 text-white bg-[#B7C6D7] hover:cursor-not-allowed py-2 px-4 rounded-lg text-center'>
                        Change Password
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className='h-16 bg-black text-white  flex justify-around items-center'>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://wazuh.com/terms-of-use/'
          className='hover:text-blue-500 hover:underline cursor-pointer'
        >
          Legal Resources
        </a>
        {/* <h1 className='hover:text-blue-500 hover:underline cursor-pointer'>
          Privacy
        </h1>
        <h1 className='hover:text-blue-500 hover:underline cursor-pointer'>
          Contact us
        </h1> */}
      </div>
    </div>
  );
};

export default ResetPassword;
