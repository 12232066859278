import { Popover, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import WhiteQuestionMark from '../../components/img/WhiteQuestionMark.svg';

const ReopenDealInfo = () => {
  const [isShowing, setIsShowing] = useState(false);
  return (
    <div className=''>
      <Popover className='relative mt-1'>
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${
                  open
                    ? 'focus:outline-none'
                    : 'text-opacity-90 focus:outline-none'
                }
                 `}
            >
              <img
                onMouseEnter={() => setIsShowing(true)}
                onMouseLeave={() => setIsShowing(false)}
                src={WhiteQuestionMark}
              ></img>
            </Popover.Button>
            <Transition
              show={isShowing}
              onMouseEnter={() => setIsShowing(true)}
              onMouseLeave={() => setIsShowing(false)}
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute ml-2 -left-[190px] z-10 mt-3 w-screen  transform max-w-[205px]'>
                <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                  <div className=' bg-[#404040] p-3'>
                    <div className='block h-5 w-5 bg-inherit border-inherit absolute top-5 triangle4'></div>
                    <a
                      href='##'
                      className='flow-root rounded-md transition duration-150 ease-in-out  focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'
                    >
                      <span className='flex items-center  '>
                        <span className='text-sm text-white'>
                          Opportunity will be opened and pending of approval.
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default ReopenDealInfo;
