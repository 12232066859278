import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

import Trash from '../img/Trash.svg';

export default function DeleteModal({ handleDelete }) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const deleteUser = () => {
    handleDelete();
    setIsOpen(false);
  };

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <button className='ml-2'>
        <img
          alt='trash icon'
          onClick={openModal}
          className='sm:mt-8'
          src={Trash}
        ></img>
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full font-manrope max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    Delete User
                  </Dialog.Title>
                  <div className='mt-2'>
                    <p className='text-sm text-gray-500'>
                      Are you sure you want to delete this user?
                    </p>
                  </div>

                  <div className='mt-4 justify-end flex'>
                    <button
                      type='button'
                      className='inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-[#3D82F1] hover:underline focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      onClick={closeModal}
                    >
                      No
                    </button>
                    <button
                      type='button'
                      className='inline-flex ml-5 justify-center rounded-md border border-transparent bg-[#3D82F1] px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      onClick={deleteUser}
                    >
                      Yes!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
