import { Popover, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import QuestionMark from '../../components/img/QuestionMark.svg';

const TableBenefit = ({ text }) => {
  const [isShowing, setIsShowing] = useState(false);
  return (
    <div className=''>
      <Popover className='relative'>
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md  text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <img
                alt='question mark'
                onMouseEnter={() => setIsShowing(true)}
                onMouseLeave={() => setIsShowing(false)}
                src={QuestionMark}
              ></img>
            </Popover.Button>
            <Transition
              show={isShowing}
              onMouseEnter={() => setIsShowing(true)}
              onMouseLeave={() => setIsShowing(false)}
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute -left-2 text-left -top-[80px]  z-10 mt-3 max-w-sm trans transform px-0 sm:px-0 w-[330px] sm:w-screen'>
                <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                  <div className=' bg-[#404040]'>
                    <div className='triangle-table-benefits'></div>
                    <a
                      href='##'
                      className='flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out  focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'
                    >
                      <span className='flex items-center'>
                        <span className='sm:text-sm text-xs font-medium text-white'>
                          {text}
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default TableBenefit;
