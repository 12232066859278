import * as React from 'react';
const Export = (props) => (
  <svg
    width='41'
    height='41'
    viewBox='0 0 41 41'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='20.5' cy='20.5' r='20.5' fill={props.bgfill} />
    <circle
      cx='21'
      cy='20'
      r='8.25'
      stroke={props.mainfill}
      strokeWidth='1.5'
    />
    <path
      d='m17.04 25.184-1.008-1.008 9.367-9.36 1.008 1.015-9.367 9.353Zm7.207.216c-.475 0-.91-.115-1.303-.346a2.73 2.73 0 0 1-.936-.936 2.506 2.506 0 0 1-.353-1.31c0-.47.12-.902.36-1.296a2.75 2.75 0 0 1 .95-.943 2.458 2.458 0 0 1 1.282-.353c.475 0 .91.118 1.303.353.394.23.706.542.936.936.235.389.353.823.353 1.303s-.118.917-.353 1.31c-.23.39-.542.701-.936.936-.394.23-.828.346-1.303.346Zm0-1.303c.235 0 .449-.058.64-.173a1.35 1.35 0 0 0 .469-.475c.12-.197.18-.41.18-.641 0-.23-.06-.444-.18-.64a1.287 1.287 0 0 0-.468-.469 1.185 1.185 0 0 0-.641-.18c-.23 0-.444.06-.641.18a1.365 1.365 0 0 0-.468.468c-.115.197-.173.41-.173.641 0 .23.058.444.173.64.12.198.276.356.468.476.197.115.41.173.64.173Zm-6.055-4.313c-.476 0-.91-.115-1.304-.346a2.71 2.71 0 0 1-.943-.936 2.546 2.546 0 0 1-.345-1.31c0-.47.12-.902.36-1.296a2.75 2.75 0 0 1 .95-.943c.394-.235.82-.353 1.282-.353.475 0 .907.118 1.296.353.393.23.708.542.943.936.235.389.353.823.353 1.303s-.118.917-.353 1.31a2.7 2.7 0 0 1-.943.936c-.39.23-.821.346-1.296.346Zm0-1.303c.23 0 .444-.058.64-.173a1.34 1.34 0 0 0 .468-.475c.12-.197.18-.41.18-.641 0-.23-.06-.444-.18-.64a1.287 1.287 0 0 0-.468-.469c-.196-.12-.41-.18-.64-.18-.23 0-.444.06-.641.18a1.365 1.365 0 0 0-.468.468c-.12.197-.18.41-.18.641 0 .23.06.444.18.64.12.198.276.356.468.476.192.115.405.173.64.173Z'
      fill={props.mainfill}
    />
  </svg>
);
export default Export;
