import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SideBarArrow from '../components/img/SideBarArrow.svg';
import BlueDownArrow from '../components/img/BlueDownArrow.svg';
import AdminButtons from './AdminPanel/AdminButtons';
import useAuth from '../hooks/useAuth';
import SupportModalContact from './Modals/SupportModalContact';

const DashboardSidebar = () => {
  const [submenu, setSubMenu] = useState(false);
  const [submenuContact, setSubmenuContact] = useState(false);
  const [submenuProspects, setSubmenuProspects] = useState(false);
  const [submenuCustomers, setSubmenuCustomers] = useState(false);
  const [submenuDealsPanel, setSubMenuDealsPanel] = useState(false);

  const userinfo = useAuth();

  let location = useLocation();

  const navigate = useNavigate();

  const path = location.pathname.split('/')[1];

  const handleSubMenu = () => {
    setSubMenu(!submenu);
  };

  const handleSubMenuContact = () => {
    setSubmenuContact(!submenuContact);
  };

  const handleSubMenuProspects = () => {
    if (submenuProspects === false) {
      navigate('/prospects/active');
    }
    setSubmenuProspects(!submenuProspects);
  };

  const handleSubMenuDealsPanel = () => {
    setSubMenuDealsPanel(!submenuDealsPanel);
  };

  const closeSubmenuProspects = () => {
    setSubmenuProspects(false);
  };

  const handleSubMenuCustomers = () => {
    setSubmenuCustomers(!submenuCustomers);
  };

  const closeSubmenuCustomers = () => {
    setSubmenuCustomers(false);
  };

  const closeSubMenuContact = () => {
    setSubmenuContact(false);
  };

  const closeSubMenu = () => {
    setSubMenu(false);
  };

  const submenuPaths = [
    'registered-deals',
    'my-customers',
    'deal-registration',
    'expired-customers',
    'deal',
    'customer',
    'lead',
  ];

  const submenuContactPaths = ['request-a-demo', 'sales-team'];

  useEffect(() => {
    if (submenuPaths.includes(path)) {
    } else closeSubMenu();
  }, [path]);

  const pathContact = location.pathname.split('/')[2];

  useEffect(() => {
    if (submenuContactPaths.includes(pathContact)) {
    } else closeSubMenuContact();
  }, [pathContact]);

  const list = [
    {
      name: 'Home',
      link: '/dashboard',
    },
    {
      name: 'Deals Panel',
      function: handleSubMenuDealsPanel,
      submenuvalue: submenuDealsPanel,
      submenu: [
        {
          name: 'Deal registration',
          link: '/deal-registration',
        },
        {
          name: 'Prospects',
          function: handleSubMenuProspects,
          submenuvalue: submenuProspects,
          submenu: [
            {
              name: 'Active',
              link: '/prospects/active',
            },

            {
              name: 'Lost',
              link: '/prospects/lost',
            },
          ],
        },
        {
          name: 'Customers',
          function: handleSubMenuCustomers,
          submenuvalue: submenuCustomers,
          submenu: [
            {
              name: 'Active',
              link: '/customers/active',
            },

            {
              name: 'Expired',
              link: '/customers/expired',
            },
            {
              name: 'Renewals',
              link: '/customers/renewals',
            },
          ],
        },
      ],
    },

    // {
    //   name: 'Customers',

    //   function: handleSubMenu,
    //   submenu: [
    //     {
    //       name: 'Deal registration',
    //       link: '/deal-registration',
    //     },
    //     {
    //       name: 'Prospects',
    //       link: '/registered-deals',
    //     },
    //     {
    //       name: 'Active customers',
    //       link: '/my-customers',
    //     },
    //     {
    //       name: 'Expired contracts',
    //       link: '/expired-customers',
    //     },
    //   ],
    // },

    // {
    //   name: 'Partners material',
    //   link: '/partners-material',
    // },
    // {
    //   name: 'Request a demo',
    //   link: '/demo',
    // },
    {
      name: 'Analytics',
      link: '/analytics',
    },
    {
      name: 'Resources',
      link: '/resources',
    },
    {
      name: 'Wazuh Portal',
    },
    {
      name: 'Contact Us',
      function: handleSubMenuContact,
      submenu: [
        {
          name: 'Wazuh Sales Team',
          link: 'contact-us/sales-team',
        },
        {
          name: 'Request a Demo',
          link: 'contact-us/request-a-demo',
        },
        {
          name: 'Support portal',
        },
      ],
    },
  ];

  return (
    <div className='relative top-[65px]'>
      <div className='fixed hide-scrollbar 3xl:hidden hidden lg:block h-[calc(100vh-65px)] left-0 w-72 bg-[#F6F6F6] z-0 overflow-y-auto'>
        <div className='my-5'>
          <div className='flex flex-col h-full'>
            <div>
              {list.map((item) => (
                <>
                  {item.name === 'Wazuh Portal' ? (
                    <> {userinfo.role === 'wazuh' && <AdminButtons />}</>
                  ) : (
                    <div key={item.name} className='flex'>
                      <div className='my-[13px] 2xl:my-[15px] w-full flex flex-col font-manrope text-base text-gray-800 hover:text-redpigment ease-in duration-200 cursor-pointer'>
                        <Link
                          className={`${
                            (location.pathname === item.link ||
                              (item.name === 'Deals Panel' &&
                                (location.pathname === '/deal-registration' ||
                                  location.pathname.split('/')[1] ===
                                    'prospects' ||
                                  location.pathname.split('/')[1] ===
                                    'customers')) ||
                              (item.name === 'Contact Us' &&
                                location.pathname.split('/')[1] ===
                                  'contact-us')) &&
                            'bg-[#3D82F1] '
                          } flex   pl-8  font-normal hover:text-blue-500 py-2`}
                          to={item.link}
                          onClick={item.function}
                        >
                          {location.pathname === item.link ||
                          (item.name === 'Deals Panel' &&
                            (location.pathname === '/deal-registration' ||
                              location.pathname.split('/')[1] === 'prospects' ||
                              location.pathname.split('/')[1] ===
                                'customers')) ||
                          (item.name === 'Contact Us' &&
                            location.pathname.split('/')[1] ===
                              'contact-us') ? (
                            <div className=' font-bold flex text-white'>
                              <img
                                alt='blue-arrow'
                                className='relative right-2 w-1'
                                src={SideBarArrow}
                              />
                              <span>{item.name}</span>
                            </div>
                          ) : (
                            <div
                              className={`${
                                (item.name === 'Customers' &&
                                  submenu === true) ||
                                (item.name === 'Contact Us' &&
                                  submenuContact === true &&
                                  'font-semibold') ||
                                (item.name === 'Deals Panel' &&
                                  submenuDealsPanel === true &&
                                  'font-semibold') ||
                                (item.name === 'Deals Panel' &&
                                  location.pathname === '/deal-registration') ||
                                location.pathname.split('/')[1] ===
                                  'prospects' ||
                                (item.name === 'Customers' &&
                                  submenuCustomers === true &&
                                  'font-semibold')
                              }`}
                            >
                              <span>{item.name}</span>
                            </div>
                          )}
                          {/* {item.name === 'Customers' && item.submenu && (
                            <img
                              className={`ml-2 mt-1 transition ease-out duration-300 ${
                                submenu === true && 'rotate-180 '
                              }`}
                              src={BlueDownArrow}
                            />
                          )} */}
                          {item.name === 'Deals Panel' && item.submenu && (
                            <img
                              className={`ml-2 w-[15px] h-[18px] mt-1 transition ease-out duration-300 ${
                                submenuDealsPanel === true && 'rotate-180 '
                              }`}
                              src={BlueDownArrow}
                            />
                          )}
                          {item.name === 'Contact Us' && item.submenu && (
                            <img
                              className={`ml-2 w-[15px] h-[18px] mt-1 transition ease-out duration-300 ${
                                submenuContact === true && 'rotate-180 '
                              }`}
                              src={BlueDownArrow}
                            />
                          )}
                          {item.name === 'Prospects' && item.submenu && (
                            <img
                              className={`ml-2 w-[15px] h-[18px] mt-1 transition ease-out duration-300 ${
                                submenuProspects === true && 'rotate-180 '
                              }`}
                              src={BlueDownArrow}
                            />
                          )}
                          {item.name === 'Customers' && item.submenu && (
                            <img
                              className={`ml-2 w-[15px] h-[18px] mt-1 transition ease-out duration-300 ${
                                submenuCustomers === true && 'rotate-180 '
                              }`}
                              src={BlueDownArrow}
                            />
                          )}
                        </Link>
                        {submenuDealsPanel && (
                          <div className='pl-12'>
                            {item.name === 'Deals Panel' && item.submenu && (
                              <div className='border-l-2 border-[rgb(183,198,215)] mt-4 pl-6'>
                                {item.submenu.map((item) => (
                                  <div>
                                    {!item.submenu && (
                                      <Link
                                        className='flex my-4'
                                        to={item.link}
                                      >
                                        {location.pathname === item.link ? (
                                          <div className=' font-bold flex text-blue-500'>
                                            <img
                                              alt='blue-arrow'
                                              className='relative right-2 w-1'
                                              src={SideBarArrow}
                                            />
                                            <span>{item.name}</span>
                                          </div>
                                        ) : (
                                          <span>{item.name}</span>
                                        )}
                                      </Link>
                                    )}
                                    {item.submenu && (
                                      <div
                                        onClick={item.function}
                                        className='flex my-4'
                                      >
                                        <span
                                          className={`${
                                            item.submenuvalue === true &&
                                            'font-semibold'
                                          } hover:text-blue-500`}
                                        >
                                          {item.name}
                                        </span>
                                        <img
                                          className={`ml-2 mt-1 transition ease-out duration-300 ${
                                            item.submenuvalue === true &&
                                            'rotate-180 '
                                          }`}
                                          src={BlueDownArrow}
                                        />
                                      </div>
                                    )}
                                    {item.submenu &&
                                      item.submenuvalue === true && (
                                        <div className='pl-2'>
                                          {item.submenu.map((item) => (
                                            <Link
                                              className={`${
                                                location.pathname ===
                                                  item.link &&
                                                'font-semibold text-blue-500'
                                              } flex my-4`}
                                              to={item.link}
                                            >
                                              {location.pathname ===
                                                item.link && (
                                                <img
                                                  alt='blue-arrow'
                                                  className='relative right-2 w-1'
                                                  src={SideBarArrow}
                                                ></img>
                                              )}
                                              {item.name}
                                            </Link>
                                          ))}
                                        </div>
                                      )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                        {submenu && (
                          <div>
                            {item.name === 'Customers' && item.submenu && (
                              <div className='border-l-2 border-blue-500 mt-4 pl-6'>
                                {item.submenu.map((item) => (
                                  <div
                                    key={item.name}
                                    className='my-4 font-normal hover:text-blue-500'
                                  >
                                    <Link className='flex' to={item.link}>
                                      {location.pathname === item.link ? (
                                        <div className=' font-bold flex text-blue-500'>
                                          <img
                                            alt='blue-arrow'
                                            className='relative right-2 w-1'
                                            src={SideBarArrow}
                                          />
                                          <span>{item.name}</span>
                                        </div>
                                      ) : (location.pathname.split('/')[1] ===
                                          'deal' ||
                                          location.pathname.split('/')[1] ===
                                            'lead') &&
                                        item.name === 'Prospects' ? (
                                        <div className=' font-bold flex text-blue-500'>
                                          <img
                                            alt='blue-arrow'
                                            className='relative right-2 w-1'
                                            src={SideBarArrow}
                                          />
                                          <span>{item.name}</span>
                                        </div>
                                      ) : location.pathname.split('/')[1] ===
                                          'customer' &&
                                        item.name === 'Active customers' ? (
                                        <div className=' font-bold flex text-blue-500'>
                                          <img
                                            alt='blue-arrow'
                                            className='relative right-2 w-1'
                                            src={SideBarArrow}
                                          />
                                          <span>{item.name}</span>
                                        </div>
                                      ) : (
                                        <span>{item.name}</span>
                                      )}
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                        {submenuContact && (
                          <div className='pl-12'>
                            {item.name === 'Contact Us' && item.submenu && (
                              <div className='border-l-2 border-[rgb(183,198,215)] mt-4 pl-6'>
                                {item.submenu.map((item) => (
                                  <div
                                    key={item.name}
                                    className='my-4  font-normal hover:text-blue-500'
                                  >
                                    <Link className='flex' to={item.link}>
                                      {location.pathname.split('/')[2] ===
                                        'sales-team' &&
                                      item.name === 'Wazuh Sales Team' ? (
                                        <div className=' font-bold flex text-blue-500'>
                                          <img
                                            alt='blue-arrow'
                                            className='relative right-2 w-1'
                                            src={SideBarArrow}
                                          />
                                          <span>{item.name}</span>
                                        </div>
                                      ) : location.pathname.split('/')[2] ===
                                          'request-a-demo' &&
                                        item.name === 'Request a Demo' ? (
                                        <div className=' font-bold flex text-blue-500'>
                                          <img
                                            alt='blue-arrow'
                                            className='relative right-2 w-1'
                                            src={SideBarArrow}
                                          />
                                          <span>{item.name}</span>
                                        </div>
                                      ) : item.name === 'Support portal' ? (
                                        <SupportModalContact />
                                      ) : (
                                        <span>{item.name}</span>
                                      )}
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                        {submenuProspects && (
                          <div>
                            {item.name === 'Prospects' && item.submenu && (
                              <div className='border-l-2 border-blue-500 mt-4 pl-6'>
                                {item.submenu.map((item) => (
                                  <div
                                    key={item.name}
                                    className='my-4  font-normal hover:text-blue-500'
                                  >
                                    <Link className='flex' to={item.link}>
                                      {location.pathname.split('/')[2] ===
                                        'active' && item.name === 'Active' ? (
                                        <div className=' font-bold flex text-blue-500'>
                                          <img
                                            alt='blue-arrow'
                                            className='relative right-2 w-1'
                                            src={SideBarArrow}
                                          />
                                          <span>{item.name}</span>
                                        </div>
                                      ) : location.pathname.split('/')[2] ===
                                          'lost' && item.name === 'Lost' ? (
                                        <div className=' font-bold flex text-blue-500'>
                                          <img
                                            alt='blue-arrow'
                                            className='relative right-2 w-1'
                                            src={SideBarArrow}
                                          />
                                          <span>{item.name}</span>
                                        </div>
                                      ) : (
                                        <span>{item.name}</span>
                                      )}
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                        {submenuCustomers && (
                          <div>
                            {item.name === 'Customers' && item.submenu && (
                              <div className='border-l-2 border-blue-500 mt-4 pl-6'>
                                {item.submenu.map((item) => (
                                  <div
                                    key={item.name}
                                    className='my-4  font-normal hover:text-blue-500'
                                  >
                                    <Link className='flex' to={item.link}>
                                      {location.pathname.split('/')[1] ===
                                        'my-customers' &&
                                      item.name === 'Active' ? (
                                        <div className=' font-bold flex text-blue-500'>
                                          <img
                                            alt='blue-arrow'
                                            className='relative right-2 w-1'
                                            src={SideBarArrow}
                                          />
                                          <span>{item.name}</span>
                                        </div>
                                      ) : location.pathname.split('/')[1] ===
                                          'expired-customers' &&
                                        item.name === 'Expired' ? (
                                        <div className=' font-bold flex text-blue-500'>
                                          <img
                                            alt='blue-arrow'
                                            className='relative right-2 w-1'
                                            src={SideBarArrow}
                                          />
                                          <span>{item.name}</span>
                                        </div>
                                      ) : location.pathname.split('/')[1] ===
                                          'renewal-customers' &&
                                        item.name === 'Renewals' ? (
                                        <div className=' font-bold flex text-blue-500'>
                                          <img
                                            alt='blue-arrow'
                                            className='relative right-2 w-1'
                                            src={SideBarArrow}
                                          />
                                          <span>{item.name}</span>
                                        </div>
                                      ) : (
                                        <span>{item.name}</span>
                                      )}
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSidebar;
