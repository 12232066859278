import { Popover, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import QuestionMark from '../../components/img/QuestionMark.svg';

const ProductInfo = ({ text }) => {
  const [isShowing, setIsShowing] = useState(false);
  return (
    <div className=''>
      <Popover className='relative'>
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group text-sx ml-2 inline-flex items-center rounded-md font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <img
                alt='notification icon'
                onMouseEnter={() => setIsShowing(true)}
                onMouseLeave={() => setIsShowing(false)}
                src={QuestionMark}
              ></img>
            </Popover.Button>
            <Transition
              show={isShowing}
              onMouseEnter={() => setIsShowing(true)}
              onMouseLeave={() => setIsShowing(false)}
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute ml-2 -top-9 left-8 z-10 mt-3 w-screen max-w-sm  transform px-4 sm:px-0 lg:max-w-[250px]'>
                <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 top-5'>
                  <div className=' bg-[#404040] text-white text-sm'>
                    <div className='block h-5 w-5 bg-inherit border-inherit absolute top-6 triangle3'></div>
                    <a
                      href='##'
                      className='flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out  focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'
                    >
                      <span className='flex items-center'>
                        <ul className='list-disc ml-5'>
                          {text.map((item, index) => (
                            <div>
                              <li key={index}>{item}</li>
                            </div>
                          ))}
                        </ul>
                      </span>
                    </a>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default ProductInfo;
