import * as React from 'react';

const Export = (props) => (
  <svg
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.9286 6.25C14.52 6.25 15 6.81 15 7.5V18.75C15 19.44 14.52 20 13.9286 20H1.07143C0.48 20 0 19.44 0 18.75V7.5C0 6.81 0.48 6.25 1.07143 6.25H10V5.25C10 2.3505 12.2386 0 15 0C17.7614 0 20 2.3505 20 5.25V6.25H18.75V5.45C18.75 3.1304 17.0711 1.25 15 1.25C12.9289 1.25 11.25 3.1304 11.25 5.45V6.25H13.9286ZM1.25 18.75H13.75V7.5H1.25V18.75ZM8.74712 14.1181L9.375 15.625H5.625L6.25288 14.1181C5.86768 13.7517 5.625 13.2184 5.625 12.625C5.625 11.521 6.465 10.625 7.5 10.625C8.535 10.625 9.375 11.521 9.375 12.625C9.375 13.2184 9.13232 13.7517 8.74712 14.1181Z'
      fill={props.fill}
    />
  </svg>
);

export default Export;
