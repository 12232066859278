import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  useGetLeadByIdMutation,
  useGetAuthorMutation,
} from '../redux/features/user/dealsApiSlice.js';

//Icons
import User from '../components/img/User.js';
import Email from '../components/img/EmailIcon.js';
import Back from '../components/img/Back.svg';
import Flag from '../components/img/Flag.js';
import Calendar from '../components/img/Calendar.js';
import Oval from '../components/img/Oval.svg';
import ReOpenDeal from '../components/Modals/ReOpenDeal.js';
import CloseDeal from '../components/Modals/CloseDeal.js';
import CloseDealInfo from '../components/Popover/CloseDealnfo.js';
import ReopenDealInfo from '../components/Popover/ReopenDealInfo.js';

const SingleLead = () => {
  const [lead, setLead] = useState(null);
  const [stageNameProp, setStageNameProp] = useState(null);
  const [author, setAuthor] = useState('');
  const [reloadOnChange, setReloadOnChange] = useState(false);
  const location = useLocation();
  const [getLeadById] = useGetLeadByIdMutation();
  const [getAuthor] = useGetAuthorMutation();

  const leadId = location.pathname.split('/')[2];

  function reformatDate(dateStr) {
    let dArr = dateStr.split('-'); // ex input "2010-01-18"
    return dArr[1] + '/' + dArr[2] + '/' + dArr[0]; //ex out: "18/01/10"
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getLeadById(leadId).unwrap();
        setLead(res);
        setStageNameProp(res[0].Status);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [leadId, reloadOnChange]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAuthor(leadId).unwrap();
        if (res.length > 0) {
          setAuthor(res[0].username);
        } else setAuthor(null);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [leadId, reloadOnChange]);

  // const handleSubmit = async (stage) => {
  //   try {
  //     const res = await editStage({ StageName: stage, Id: dealId }).unwrap();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const leadTypeToString = (leadType) => {
    if (leadType === null) {
      return '-';
    } else if (leadType.includes('Lead Type: cloud')) {
      return 'Cloud';
    } else if (leadType.includes('Lead Type: onpremises')) {
      return 'On Premises';
    } else {
      return '-';
    }
  };

  const returnExpirationDate = (createdAt, notes) => {
    if (notes.includes('Close Date:')) {
      return '';
    }
  };

  return (
    <div className=''>
      {!lead ? (
        <div className='flex mt-[50vh] -translate-y-32 justify-center items-center'>
          <img alt='loading' className='w-32' src={Oval} />
        </div>
      ) : (
        <div>
          <div className='text-2xl sm:text-3xl font-semibold p-5 lg:pl-[30px] xl:pl-[22px] 2xl:pl-[40px] lg:pr-[33px] 2xl:pr-[33px] flex items-center justify-between rounded-2xl m-3 lg:m-7 bg-[#5E6872] text-white'>
            <div className='items-center'>
              <div className='font-semibold text-2xl 2xl:text-3xl flex items-center'>
                {lead[0]?.Name}
              </div>
            </div>
          </div>
          <div className='mt-5 max-lg:pl-5 lg:pl-[30px] xl:pl-[37px] 2xl:pl-0 xl:mx-0 2xl:mx-[55px]'>
            <h1
              className={` text-xl 2xl:text-[22px] font-semibold text-left max-md:mb-[30px] ${
                lead[0].Status === 'Closed Trash' ||
                lead[0].Status === 'Closed Archived'
                  ? 'text-[#A2ABBA]'
                  : 'text-black'
              } `}
            >
              Information
            </h1>
            <span className='form-divider h-1 mr-0 lg:mr-16 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
            <div className='flex flex-col'>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-y-9'>
                <div className='flex items-center'>
                  <div>
                    <User
                      bgfill={'#EBF2FB'}
                      mainfill={'#A2ABBA'}
                      className='mr-2 w-[41px]'
                    />
                  </div>
                  <div className='flex'>
                    <h1
                      className={`font-semibold leading-tight text-lg text-[#A2ABBA]`}
                    >
                      Contact person:&nbsp;
                    </h1>
                    <h3 className={`text-lg leading-tight text-[#A2ABBA]`}>
                      {lead[0].Name}
                    </h3>
                  </div>
                </div>

                <div className='flex items-center'>
                  <div>
                    <Email
                      className='mr-2 w-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={'#A2ABBA'}
                    />
                  </div>
                  <div className='flex'>
                    <h1
                      className={`font-semibold leading-tight text-lg text-[#A2ABBA]`}
                    >
                      Email:&nbsp;
                    </h1>
                    <h3 className={`text-lg leading-tight text-[#A2ABBA]`}>
                      {lead[0]?.Email}
                    </h3>
                  </div>
                </div>
                <div className='flex items-center'>
                  <div>
                    <Calendar
                      className='mr-2 w-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={'#A2ABBA'}
                    />
                  </div>
                  <div className='flex'>
                    <h1
                      className={`font-semibold leading-tight text-lg text-[#A2ABBA]`}
                    >
                      Expiration date:&nbsp;
                    </h1>
                    <h3 className={`text-lg leading-tight text-[#A2ABBA]`}>
                      {lead[0].CreatedDate}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='pt-4 max-lg:pl-5  lg:pl-[30px] xl:pl-[37px] 2xl:pl-0 2xl:mx-[55px]'>
            <h1
              className={`text-xl 2xl:text-[22px] font-semibold text-left max-md:mb-[30px] mt-10 text-[#A2ABBA]`}
            >
              Service requested
            </h1>

            <span className='form-divider h-1 mr-0 lg:mr-16 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
            <div className='flex items-center'>
              <div>
                <Flag
                  className='mr-2 w-[41px]'
                  bgfill={'#EBF2FB'}
                  mainfill={'#A2ABBA'}
                />
              </div>
              <div className='flex'>
                <h1
                  className={`font-semibold leading-tight text-lg text-[#A2ABBA]`}
                >
                  Plan:&nbsp;
                </h1>
                <h3 className={`text-lg leading-tight text-[#A2ABBA]`}>
                  {leadTypeToString(lead[0]?.Sales_Notes__c)}
                </h3>
              </div>
            </div>
          </div>
          <div className='px-5 mt-8 lg:pl-[30px] xl:pl-[37px] 2xl:pl-0 2xl:mx-[55px] 2xl:max-w-[1500px]'>
            <div className='flex items-center col-span-3'>
              <div>
                <User
                  bgfill={'#EBF2FB'}
                  mainfill={'#A2ABBA'}
                  className='mr-2 w-[41px]'
                />
              </div>
              <div className='flex'>
                <h1
                  className={`font-semibold leading-tight text-lg text-[#A2ABBA]`}
                >
                  Registered by:&nbsp;
                </h1>
                <h3 className={`text-lg leading-tight text-[#A2ABBA]`}>
                  {author === null ? 'Wazuh Sales' : author}
                </h3>
              </div>
            </div>
          </div>
          <div className='flex mt-12 mx-5 lg:mx-0  2xl:mx-[55px] lg:pl-[30px] xl:pl-[37px] 2xl:pl-0 2xl:max-w-[1500px]'>
            <Link
              className='flex btn bg-yellow-gradient px-5 py-2 rounded-xl'
              to='/registered-deals'
            >
              <img alt='back' className='w-4 mr-1' src={Back}></img>
              <h3 className='text-[18px]'>Back</h3>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleLead;
