import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const LargeDeal = () => {
  const userinfo = useAuth();

  return (
    <div className='px-4'>
      <Popover className=''>
        {({ open }) => (
          <>
            <Popover.Button
              className={`
              ${
                open
                  ? ''
                  : 'text-opacity-90' +
                    ' hover:text-[#3D82F1] hover:cursor-pointer'
              }
             `}
            >
              <span>Deals Panel</span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel
                className={`absolute ${
                  userinfo.role === 'wazuh' ? 'left-[240px]' : 'left-[292px]'
                }  z-10 mt-3 w-screen -translate-x-1/2 transform px-4 sm:px-0 max-w-[220px]`}
              >
                <span className='triangle-large-deal border border-blue-300 bg-white'></span>
                <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 border-blue-400 border'>
                  <div className='relative grid gap-8 bg-white py-4 px-7'>
                    <Link
                      to='/deal-registration'
                      className='text-base hover:cursor-pointer hover:text-blue-500'
                    >
                      Deal Registration
                    </Link>
                  </div>
                  <div className='relative grid gap-8 bg-white py-4 px-7'>
                    <Link
                      to='/prospects/active'
                      className='text-base hover:cursor-pointer hover:text-blue-500'
                    >
                      Active Prospects
                    </Link>
                  </div>
                  <div className='relative grid gap-8 bg-white py-4 px-7'>
                    <Link
                      to='/prospects/lost'
                      className='text-base hover:cursor-pointer hover:text-blue-500'
                    >
                      Lost Prospects
                    </Link>
                  </div>
                  <div className='relative grid gap-8 bg-white py-4 px-7'>
                    <Link
                      to='/customers/active'
                      className='text-base hover:cursor-pointer hover:text-blue-500'
                    >
                      Active Customers
                    </Link>
                  </div>
                  <div className='relative grid gap-8 bg-white py-4 px-7'>
                    <Link
                      to='/customers/expired'
                      className='text-base hover:cursor-pointer hover:text-blue-500'
                    >
                      Expired Customers
                    </Link>
                  </div>
                  <div className='relative grid gap-8 bg-white py-4 px-7'>
                    <Link
                      to='/customers/renewals'
                      className='text-base hover:cursor-pointer hover:text-blue-500'
                    >
                      Renewals Customers
                    </Link>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default LargeDeal;
