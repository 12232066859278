import './App.css';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';

//Pages
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import DealRegistration from './pages/DealRegistration';
import RegisteredDeals from './pages/RegisteredDeals';
import MyCustomers from './pages/MyCustomers';
import PartnersMaterial from './pages/PartnersMaterial';
import StartDemo from './pages/StartDemo';
import Help from './pages/Help';
import Register from './pages/Register';
import Profile from './pages/Profile';
import Company1 from './pages/Company1';
import Company11 from './pages/Company11';
import SingleLead from './pages/SingleLead';

//Components
import DashboardNavbar from './components/DashboardNavbar';
import DashboardSidebar from './components/DashboardSidebar';
import AskDemo from './pages/AskDemo';
import MySettings from './pages/MySettings';
import IsLoggedIn from './hooks/IsLoggedIn';
import PersistLogin from './redux/features/user/PersistLogin';
import LargeSubNavbar from './components/LargeSubNavbar';
import ExpiredCustomers from './pages/ExpiredCustomers';
import ResetPassword from './pages/ResetPassword';
import NotFound from './pages/NotFound';
import Analytics from './pages/Analytics';
import WazuhUsersPanel from './pages/WazuhUsersPanel';
import IsWazuhRole from './hooks/IsWazuhRole';
import ContactUs from './pages/ContactUs';
import ActiveProspects from './pages/ActiveProspects';
import LostProspects from './pages/LostProspects';
import RenewalCustomers from './pages/RenewalCustomers';
import SearchResult from './pages/SearchResult';

function App() {
  const AppLayout = () => (
    <div className='3xl:flex 3xl:justify-center font-manrope 3xl:bg-white'>
      <div className='3xl:relative 3xl:w-[1400px]'>
        <DashboardSidebar />
        <DashboardNavbar />
        <LargeSubNavbar />
        <div className='lg:ml-72 3xl:ml-0 font-manrope flex flex-col pt-16 3xl:pt-0'>
          <Outlet />
        </div>
      </div>
    </div>
  );

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route element={<ProtectedRoutes />}> */}
        <Route element={<PersistLogin />}>
          <Route element={<AppLayout />}>
            <Route path='/Company11' element={<Company11 />} />
            <Route path='/Company1' element={<Company1 />} />
            <Route path='/lead/:id' element={<SingleLead />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/deal-registration' element={<DealRegistration />} />
            <Route path='/registered-deals' element={<RegisteredDeals />} />
            <Route path='/prospects/active' element={<ActiveProspects />} />
            <Route path='/prospects/lost' element={<LostProspects />} />
            <Route path='/customers/active' element={<MyCustomers />} />
            <Route path='/customers/expired' element={<ExpiredCustomers />} />
            <Route path='/customers/renewals' element={<RenewalCustomers />} />
            <Route path='/partners-material' element={<PartnersMaterial />} />
            <Route path='/demo' element={<StartDemo />} />
            <Route path='/resources' element={<Help />} />
            <Route path='/contact-us/:section' element={<ContactUs />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/askdemo' element={<AskDemo />} />
            <Route path='/deal/:id' element={<Company1 />} />
            <Route path='/customer/:id' element={<Company11 />} />
            <Route path='/my-settings' element={<MySettings />} />
            <Route path='/analytics' element={<Analytics />} />
            <Route path='/search/:searchName' element={<SearchResult />} />
            <Route element={<IsWazuhRole />}>
              <Route path='/wazuh-analytics' element={<WazuhUsersPanel />} />
            </Route>
          </Route>
          <Route path='*' element={<NotFound />}></Route>
        </Route>
        {/* </Route> */}
        <Route element={<IsLoggedIn />}>
          <Route exact path='/' element={<Home />} />
        </Route>
        <Route path='/register-user' element={<Register />} />
        <Route path='/reset-password/:id/:token' element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
