import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SideBarArrow from '../img/SideBarArrow.svg';
import BlueDownArrow from '../img/BlueDownArrow.svg';
import AddUserModal from './AddUserModal';
import AddPartnerModal from './AddPartnerModal';
import ChangePartnerView from './ChangePartnerView';
import ChangeUserRole from './ChangeUserRole';
import AnalyticsButton from './AnalyticsButton';

const AdminButtons = () => {
  const [submenu, setSubmenu] = useState(false);
  const handleSubMenu = () => {
    setSubmenu(!submenu);
  };

  const location = useLocation();

  const adminlist = [
    {
      name: 'Wazuh Panel',

      function: handleSubMenu,
      submenu: [
        {
          name: 'Register User',
          link: '/deal-registration',
        },
        {
          name: 'Register Partner',
          link: '/registered-deals',
        },
        {
          name: 'Change Partner View',
          link: '/my-customers',
        },
      ],
    },
  ];

  return (
    <div>
      <button
        onClick={handleSubMenu}
        className={`my-4 flex items-center pl-8 font-manrope text-base text-gray-800 cursor-pointer hover:text-blue-500 ${
          submenu && 'text-blue-500 font-semibold'
        }`}
      >
        <div>Wazuh Panel</div>
        <img
          className={`ml-2 mt-1 transition ease-out duration-300 ${
            submenu === true && 'rotate-180 '
          }`}
          src={BlueDownArrow}
        ></img>
      </button>
      <div className='pl-12'>
        <div
          className={`${
            !submenu && 'hidden'
          } border-l-2 border-[rgb(183,198,215)] mt-4 pl-6`}
        >
          {/* {adminlist.map((item) => (
        <div key={item.name} className='flex'>
          <div className='my-4 flex flex-col font-manrope text-base font-bold text-gray-800 hover:text-redpigment ease-in duration-200 cursor-pointer'>
            <Link
              className='flex  font-normal hover:text-blue-500'
              to={item.link}
              onClick={item.function}
            >
              {location.pathname === item.link ? (
                <div className=' font-bold flex text-blue-500'>
                  <img
                    alt='blue-arrow'
                    className='relative right-2 w-1'
                    src={SideBarArrow}
                  />
                  {item.name}
                </div>
              ) : (
                <div
                  className={`${
                    item.name === 'Customers' &&
                    submenu === true &&
                    'font-semibold'
                  }`}
                >
                  <>{item.name}</>
                </div>
              )}
              {item.name === 'Customers' && (
                <img
                  className={`ml-2 mt-1 transition ease-out duration-300 ${
                    submenu === true && 'rotate-180 '
                  }`}
                  src={BlueDownArrow}
                />
              )}
            </Link>
            {submenu && (
              <div>
                {item.submenu && (
                  <div className='border-l-2 border-blue-500 mt-4 pl-6'>
                    {item.submenu.map((item) => (
                      <div
                        key={item.name}
                        className='my-6  font-normal hover:text-blue-500'
                      >
                        <Link className='flex' to={item.link}>
                          {location.pathname === item.link ? (
                            <div className=' font-bold flex text-blue-500'>
                              <img
                                alt='blue-arrow'
                                className='relative right-2 w-1'
                                src={SideBarArrow}
                              />
                              {item.name}
                            </div>
                          ) : location.pathname.split('/')[1] === 'deal' &&
                            item.name === 'Prospects' ? (
                            <div className=' font-bold flex text-blue-500'>
                              <img
                                alt='blue-arrow'
                                className='relative right-2 w-1'
                                src={SideBarArrow}
                              />
                              {item.name}
                            </div>
                          ) : location.pathname.split('/')[1] === 'customer' &&
                            item.name === 'Active customers' ? (
                            <div className=' font-bold flex text-blue-500'>
                              <img
                                alt='blue-arrow'
                                className='relative right-2 w-1'
                                src={SideBarArrow}
                              />
                              {item.name}
                            </div>
                          ) : (
                            <>{item.name}</>
                          )}
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ))} */}
          <AddUserModal />
          <AddPartnerModal />
          <ChangePartnerView />
          <ChangeUserRole />
          <AnalyticsButton />
        </div>
      </div>
    </div>
  );
};

export default AdminButtons;
