import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import PasswordChanged from '../../components/img/PasswordChanged.svg';

const UpdatePasswordModal = ({ submitPasswordModal, closePasswordModal }) => {
  return (
    <>
      <Transition appear show={submitPasswordModal} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closePasswordModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <div className='mt-4 flex flex-col justify-center items-center font-manrope'>
                    <img
                      alt='submitted message'
                      className='w-48'
                      src={PasswordChanged}
                    />
                    <div className='text-center mt-4'>
                      <p className='text-3xl font-semibold'>
                        Password updated!
                      </p>
                      <p className='text-lg max-w-[300px] sm:max-w-none'>
                        Your password has been successfully changed
                      </p>
                    </div>
                    <Link to='/dashboard'>
                      <button
                        onClick={closePasswordModal}
                        type='button'
                        className='inline-flex mt-8 mb-4 justify-center rounded-md border border-transparent bg-[#3D82F1] px-8 py-2 text-base font-normal text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2'
                      >
                        Back to Home
                      </button>
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default UpdatePasswordModal;
