import { useState, useEffect } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { Transition } from '@headlessui/react';
import { features } from '../features';
import Flag from 'react-world-flags';
import ChartInfo from '../Popover/ChartInfo';
import { useGetRecentUsersByCountryMutation } from '../../redux/features/user/usersApiSlice';

const WorldMap = () => {
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [users, setUsers] = useState([]);
  const [getUsersByCountry] = useGetRecentUsersByCountryMutation();

  useEffect(() => {
    const getUsers = async () => {
      const result = await getUsersByCountry().unwrap();
      setUsers(result);
    };
    getUsers();
  }, []);

  const handleMouseEnter = (geo, event) => {
    const countryData = users.find(
      (item) => item.country === geo.properties.name
    );
    if (countryData) {
      setTooltipContent({
        flag: geo.id,
        country: geo.properties.name,
        users: countryData.users,
      });
      setTooltipPosition({ x: event.clientX, y: event.clientY });
    }
  };

  const handleMouseMove = (event) => {
    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;
    setTooltipPosition({
      x: event.clientX + scrollX - 50,
      y: event.clientY + scrollY - 100,
    });
  };

  const handleMouseLeave = () => {
    setTooltipContent(null);
  };

  return (
    <div>
      <div>
        <div className='flex items-center'>
          <h1 className='text-[22px] mr-1 font-semibold text-left'>
            Monthly console visits
          </h1>
          <ChartInfo
            text={
              'Shows the location and amount of the active users of the portal in the past 30 days.'
            }
          />
        </div>
        <span className='form-divider h-1 mr-0 lg:mr-6 2xl:mr-3 block mt-2 mb-7'></span>
      </div>
      <div className='rounded-lg bg-[#F6F6F6] px-2 pt-2'>
        <div className='flex items-center justify-center'>
          <div className='w-[90%] mr-12'>
            <ComposableMap data-tip=''>
              <Geographies geography={features}>
                {({ geographies }) =>
                  geographies.map((geo) => {
                    const countryData = users.find(
                      (item) => item.country === geo.properties.name
                    );
                    const isDataPresent = countryData !== undefined;
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        onMouseEnter={(e) => handleMouseEnter(geo, e)}
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          default: {
                            fill: isDataPresent ? '#3D82F1' : '#C4C4C4',
                            outline: 'none',
                          },
                          hover: {
                            fill: isDataPresent ? '#3585F9' : '#A3A3A3',
                            outline: 'none',
                          },
                        }}
                      />
                    );
                  })
                }
              </Geographies>
            </ComposableMap>
            <Transition
              show={tooltipContent !== null}
              enter='transition-opacity duration-100'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div
                className='absolute bg-white p-2 border-2 rounded-lg  border-gray-300'
                style={{
                  top: tooltipPosition.y,
                  left: tooltipPosition.x,
                }}
              >
                {tooltipContent && (
                  <div>
                    <div className='flex items-center'>
                      <Flag className='h-4 mr-1' code={tooltipContent.flag} />

                      <p>{tooltipContent.country}</p>
                    </div>
                    <p>Users: {tooltipContent.users}</p>
                  </div>
                )}
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorldMap;
