import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  useRef,
} from 'react';
import Navbar from '../components/Navbar';
import PartnersLogo from '../components/img/PartnersLogo.svg';
import wazuhlogo from '../components/img/wazuh-log.svg';
import EmailIcon from '../components/img/EmailIcon.svg';
import '../index.css';
import { useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Modal from '../components/Forms/Partner Registration/Modal';
import { useRegisterUserMutation } from '../redux/features/user/authApiSlice';
import {
  useGetPartnersMutation,
  useCreatePartnerMutation,
} from '../redux/features/user/partnersApiSlice';
import { Dialog, Transition } from '@headlessui/react';
import { useDeletePartnerByIdMutation } from '../redux/features/user/partnersApiSlice';
import ChangeUserPassword from '../components/Forms/User Settings/ChangeUserPassword';
import ChangePartnerName from '../components/Forms/User Settings/ChangePartnerName';
import ChangePartnerSalesforceId from '../components/Forms/User Settings/ChangePartnerSalesforceId';
import SendResourcesEmail from '../components/Forms/User Settings/SendResourcesEmail';

const registerValidationSchema = Yup.object({
  username: Yup.string()
    .required('User is required')
    .max(30, 'User must be 30 characters or less.'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .max(70, 'Email name must be 70 characters or less.'),
  // password: Yup.string().required('Password is required!'),
});

const initialData = {
  username: '',
  email: '',
  role: 'user',
  partnerId: '',
};

const Register = () => {
  const [err, setErr] = useState(null);
  const [partnersList, setPartnersList] = useState([]);
  const [registerUser] = useRegisterUserMutation();
  const [getPartners] = useGetPartnersMutation();
  const [createPartner] = useCreatePartnerMutation();
  const [deletePartner] = useDeletePartnerByIdMutation();
  const [registerOpen, setRegisterOpen] = useState(false);
  const location = useLocation();
  const path = location.pathname.split('/')[1];
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDeletePartnerOpen, setModalDeletePartnerOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const fetchPartners = useCallback(async () => {
    const result = await getPartners(path).unwrap();
    setPartnersList(result);
  });

  useEffect(() => {
    fetchPartners();
  }, []);

  const handleSubmit = async (formData, { resetForm }) => {
    try {
      await registerUser(formData).unwrap();
      setRegisterOpen(true);
      resetForm();
    } catch (error) {
      setErr(error.response.data);
    }
  };

  const handleDeletePartner = async (data) => {
    try {
      await deletePartner({
        id: path,
        body: data,
      }).unwrap();
      await fetchPartners();
    } catch (error) {
      console.log(error);
    }
  };

  const closeRegisterModal = () => {
    setRegisterOpen(false);
  };

  function closeDeletePartnerModal() {
    setModalDeletePartnerOpen(false);
  }

  function openDeletePartnerModal() {
    setModalDeletePartnerOpen(true);
  }

  const modalInitialValues = {
    name: '',
    salesforceId: '',
  };

  const deletePartnerValues = {
    partnerId: '',
  };

  const handleSubmitModal = async (formData) => {
    await createPartner({
      id: path,
      body: formData,
    });
    await fetchPartners();
    // console.log(formData);
  };

  const closeConfirmationModal = () => {
    setConfirmationOpen(false);
    fetchPartners();
  };

  return (
    <div className='flex flex-col lg:h-screen bg-[#EBF2FB] font-manrope'>
      <Navbar />
      <div className='bg-white flex flex-1 m-16 rounded-xl'>
        <div className='flex flex-1 flex-col lg:flex-row justify-around items-center'>
          <div className='flex flex-col items-center mt-4 lg:mt-0'>
            <img alt='partners logo' src={PartnersLogo}></img>
            <div className='mt-2'>
              <img alt='wazuh logo' src={wazuhlogo}></img>
            </div>
            <div className='text-2xl xl:text-3xl font-medium mt-4'>
              Partners Portal
            </div>
          </div>
          <div className='flex flex-col w-auto'>
            <div className='text-center flex-col flex items-center content-center'>
              <h1 className='text-2xl font-semibold'>Register</h1>
            </div>
            <Formik
              validationSchema={registerValidationSchema}
              initialValues={initialData}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form className='grid grid-cols-1 lg:grid-cols-2 mt-8 gap-4'>
                  <div>
                    {errors.username && touched.username ? (
                      <p className='font-semibold mb-2 text-red-500'>
                        {errors.username}
                      </p>
                    ) : (
                      <p className='font-semibold mb-2'>Username</p>
                    )}
                    <Field
                      className=' border border-[#B7C6D7] rounded-md w-64  px-2 py-2 text-black placeholder-[#B7C6D7]'
                      placeholder='Username'
                      type='text'
                      name='username'
                    ></Field>
                  </div>
                  <div>
                    {errors.email && touched.email ? (
                      <p className='font-semibold mb-2 text-red-500'>
                        {errors.email}
                      </p>
                    ) : (
                      <p className='font-semibold mb-2 '>Email</p>
                    )}

                    <div className='flex'>
                      <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300'>
                        <img alt='email icon' src={EmailIcon} />
                      </span>
                      <Field
                        name='email'
                        type='email'
                        className='rounded-none rounded-r-lg bg-gray-50 border  text-gray-900 focus:ring-blue-500 focus:border-blue-500 w-[211px] text-sm border-gray-300 p-2.5'
                        placeholder='Email'
                      ></Field>
                    </div>
                  </div>
                  <div className='flex flex-col'>
                    <p className='font-semibold mb-2 mt-4'>Partner</p>
                    <Field
                      className=' border border-[#B7C6D7] rounded-md  w-64 px-2 py-2 text-black placeholder-[#B7C6D7]'
                      as='select'
                      name='partnerId'
                    >
                      <option disabled selected value=''>
                        -
                      </option>
                      {partnersList.map((partner) => (
                        <option value={partner.id}>{partner.name}</option>
                      ))}
                    </Field>
                  </div>
                  <div className='flex flex-col'>
                    <p className='font-semibold mb-2 mt-4'>Role</p>
                    <Field
                      className=' border border-[#B7C6D7] rounded-md  w-64 px-2 py-2 text-black placeholder-[#B7C6D7]'
                      as='select'
                      name='role'
                    >
                      <option selected value='user'>
                        User
                      </option>
                      <option value='admin'>Admin</option>
                      <option value='wazuh'>Wazuh</option>
                    </Field>
                  </div>
                  {/* {errors.password && touched.password ? (
                    <p className='font-semibold mb-2 text-red-500'>
                      {errors.password}
                    </p>
                  ) : (
                    <p className='font-semibold mb-2'>Password</p>
                  )} */}

                  {/* <div className='flex'>
                    <span className='bg-[#FBFCFD] inline-flex items-center px-3 text-sm text-gray-900 bg-gray-[#DBE3EB] rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600'>
                      <img src={Lock} />
                    </span>
                    <Field
                      name='password'
                      type='password'
                      id='website-admin'
                      className='rounded-none  bg-gray-50 border border-l-0  text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      placeholder='Password'
                    ></Field>
                    <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-[#DBE3EB] rounded-r-md border border-l-0 border-gray-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600'>
                      <img src={CrossEye} />
                    </span>
                  </div> */}

                  {err && <p className='text-red-500'>{err}</p>}
                  {/* Beginning Modal */}
                  <button
                    type='button'
                    onClick={() => setModalOpen(true)}
                    className='bg-gray-700 hover:bg-gray-900 cursor-pointer  w-64 rounded-lg text-white py-2 text-center items-center'
                  >
                    Add Partner
                  </button>
                  <Transition.Root show={modalOpen} as={Fragment}>
                    <Dialog
                      as='div'
                      className='relative z-10'
                      initialFocus={cancelButtonRef}
                      onClose={setModalOpen}
                    >
                      <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                      >
                        {/* Overlay Background */}
                        <div className='fixed inset-0 bg-black bg-opacity-75 transition-opacity' />
                      </Transition.Child>

                      <div className='fixed inset-0 z-10 overflow-y-auto'>
                        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                          <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                          >
                            <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl'>
                              <Formik
                                initialValues={modalInitialValues}
                                onSubmit={handleSubmitModal}
                              >
                                {(formProps) => (
                                  <Form>
                                    <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                                      <div className='sm:flex sm:items-start'>
                                        <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                                          <Dialog.Title
                                            as='h3'
                                            className='text-lg font-medium leading-6 text-gray-900'
                                          >
                                            Register new Partner
                                          </Dialog.Title>
                                          <div className='mt-2'>
                                            <div className='grid grid-cols-3 gap-4'>
                                              <div className='flex gap-4'>
                                                <div>
                                                  <p className='font-semibold  text-sm mb-2'>
                                                    Name
                                                  </p>
                                                  <Field
                                                    className=' border border-[#B7C6D7] rounded-md  w-44 px-2 py-2 text-black placeholder-[#B7C6D7]'
                                                    placeholder='Name'
                                                    type='text'
                                                    name='name'
                                                  ></Field>
                                                </div>
                                                <div>
                                                  <p className='font-semibold  text-sm mb-2'>
                                                    Partner Salesforce Id
                                                  </p>
                                                  <Field
                                                    className=' border border-[#B7C6D7] rounded-md  w-44 px-2 py-2 text-black placeholder-[#B7C6D7]'
                                                    placeholder='Salesforce Id'
                                                    type='text'
                                                    name='salesforceId'
                                                  ></Field>
                                                </div>
                                              </div>
                                              {/* <div>
                                                <p className='font-semibold  text-sm mb-2'>
                                                  Picture
                                                </p>
                                                <Field
                                                  className='border w- border-[#B7C6D7] rounded-md w-44 px-2 py-2 text-black placeholder-[#B7C6D7]'
                                                  type='file'
                                                  name='pic'
                                                  value={undefined}
                                                  onChange={(event) =>
                                                    formProps.setFieldValue(
                                                      'pic',
                                                      event.target.files[0]
                                                    )
                                                  }
                                                ></Field>
                                              </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                                      <button
                                        type='submit'
                                        className='inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm'
                                        onClick={() => setModalOpen(false)}
                                      >
                                        Register
                                      </button>
                                      <button
                                        type='button'
                                        className='mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                        onClick={() => setModalOpen(false)}
                                        ref={cancelButtonRef}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </Form>
                                )}
                              </Formik>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition.Root>
                  <Transition.Root show={confirmationOpen} as={Fragment}>
                    <Dialog
                      as='div'
                      className='relative z-10'
                      initialFocus={cancelButtonRef}
                      onClose={closeConfirmationModal}
                    >
                      <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                      >
                        {/* Overlay Background */}
                        <div className='fixed inset-0 bg-black bg-opacity-75 transition-opacity' />
                      </Transition.Child>

                      <div className='fixed inset-0 z-10 overflow-y-auto'>
                        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                          <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                          >
                            <Dialog.Panel className='w-full font-manrope max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                              <p className='text-lg text-gray-500'>
                                Partner registered succesfully!
                              </p>

                              {/* <div className='text-[#3D82F1] font-semibold text-sm'>
                    Once a deal is closed it can't be opened again
                  </div> */}
                              <div className='mt-6 justify-end flex'>
                                <button
                                  type='button'
                                  className='inline-flex ml-5 justify-center rounded-md border border-transparent bg-[#3D82F1] px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                                  onClick={() => closeConfirmationModal()}
                                >
                                  Accept!
                                </button>
                              </div>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition.Root>
                  {/* End Modal */}
                  {/* <Modal fetchPartners={fetchPartners} /> */}
                  <button
                    type='submit'
                    className='bg-[#3D82F1] hover:bg-blue-600  w-64 rounded-lg text-white py-2 text-center items-center'
                  >
                    Register
                  </button>
                  <button
                    onClick={openDeletePartnerModal}
                    type='button'
                    className='bg-[#8f1616] hover:bg-red-700  w-64 rounded-lg  text-white py-2 text-center items-center'
                  >
                    Delete partner
                  </button>
                  <Transition
                    appear
                    show={modalDeletePartnerOpen}
                    as={Fragment}
                  >
                    <Dialog
                      as='div'
                      className='relative z-10'
                      onClose={closeDeletePartnerModal}
                    >
                      <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                      >
                        <div className='fixed inset-0 bg-black bg-opacity-25' />
                      </Transition.Child>

                      <div className='fixed inset-0 overflow-y-auto'>
                        <div className='flex min-h-full items-center justify-center p-4 text-center'>
                          <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 scale-95'
                            enterTo='opacity-100 scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 scale-100'
                            leaveTo='opacity-0 scale-95'
                          >
                            <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                              <Dialog.Title
                                as='h3'
                                className='text-lg font-medium leading-6 mb-2 text-gray-900'
                              >
                                Delete Partner
                              </Dialog.Title>
                              <div>
                                <Formik
                                  initialValues={deletePartnerValues}
                                  onSubmit={handleDeletePartner}
                                >
                                  <Form className='flex justify-between'>
                                    <Field
                                      className=' border border-[#B7C6D7] rounded-md  w-64 px-2 py-2 text-black placeholder-[#B7C6D7]'
                                      as='select'
                                      name='partnerId'
                                    >
                                      <option disabled selected value=''>
                                        -
                                      </option>
                                      {partnersList.map((partner) => (
                                        <option value={partner.id}>
                                          {partner.name}
                                        </option>
                                      ))}
                                    </Field>
                                    <button
                                      type='submit'
                                      className='bg-[#8f1616] hover:bg-red-700 px-2 rounded-lg  text-white py-2 text-center items-center'
                                    >
                                      Delete
                                    </button>
                                  </Form>
                                </Formik>
                              </div>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition>
                  <ChangeUserPassword />
                  <ChangePartnerName />
                  <ChangePartnerSalesforceId />
                  <SendResourcesEmail/>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      {/* User registered confirmation modal */}
      <Transition.Root show={registerOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeRegisterModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            {/* Overlay Background */}
            <div className='fixed inset-0 bg-black bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='w-full font-manrope max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <p className='text-lg text-gray-500'>
                    User registered succesfully!
                  </p>

                  {/* <div className='text-[#3D82F1] font-semibold text-sm'>
                    Once a deal is closed it can't be opened again
                  </div> */}
                  <div className='mt-6 justify-end flex'>
                    <button
                      type='button'
                      className='inline-flex ml-5 justify-center rounded-md border border-transparent bg-[#3D82F1] px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      onClick={() => closeRegisterModal()}
                    >
                      Accept!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Footer */}
      <div className='h-16 bg-black text-white  flex justify-around items-center'>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://wazuh.com/terms-of-use/'
          className='hover:text-blue-500 hover:underline cursor-pointer'
        >
          Legal Resources
        </a>
        {/* <h1 className='hover:text-blue-500 hover:underline cursor-pointer'>
          Privacy
        </h1>
        <h1 className='hover:text-blue-500 hover:underline cursor-pointer'>
          Contact us
        </h1> */}
      </div>
    </div>
  );
};

export default Register;
