import * as React from 'react';
const Export = (props) => (
  <svg
    width='41'
    height='41'
    viewBox='0 0 41 41'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='20.5' cy='20.5' r='20.5' fill={props.bgfill} />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.5 11c.343 0 .671.141.907.39l4 4.285c.22.232.343.541.343.861V29.75c0 .69-.56 1.25-1.25 1.25h-15c-.69 0-1.25-.56-1.25-1.25v-17.5c0-.69.56-1.25 1.25-1.25h11Zm4 6.25h-4.375a.625.625 0 0 1-.625-.625V12.25h-10v17.5h15v-12.5ZM16.875 26a.625.625 0 1 1 0-1.25h6.25a.625.625 0 1 1 0 1.25h-6.25Zm0-3.75a.625.625 0 1 1 0-1.25h6.25a.625.625 0 1 1 0 1.25h-6.25Z'
      fill={props.mainfill}
    />
  </svg>
);
export default Export;
