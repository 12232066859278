import * as React from 'react';
const Export = (props) => (
  <svg
    width='8'
    height='8'
    viewBox='0 0 8 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.1736 3.85H0.535936C0.32335 3.85 0.151015 4.0051 0.151015 4.19643C0.151015 4.38776 0.32335 4.54286 0.535936 4.54286H6.1736L3.33552 7.38094C3.20023 7.51623 3.20023 7.73557 3.33552 7.87086C3.47081 8.00615 3.69016 8.00615 3.82545 7.87086L6.76499 4.93132C6.94389 4.75242 7.04393 4.52454 7.06512 4.29085C7.07455 4.26083 7.07959 4.22916 7.07959 4.19643C7.07959 4.1637 7.07455 4.13203 7.06512 4.10201C7.04393 3.86832 6.94389 3.64044 6.76499 3.46154L3.82545 0.521999C3.69016 0.38671 3.47081 0.38671 3.33552 0.521999C3.20023 0.657288 3.20023 0.876634 3.33552 1.01192L6.1736 3.85Z'
      fill={props.mainfill}
    />
  </svg>

  //   <svg
  //     width='41'
  //     height='41'
  //     viewBox='0 0 41 41'
  //     fill='none'
  //     xmlns='http://www.w3.org/2000/svg'
  //     {...props}
  //   >
  //     <circle cx='20.5' cy='20.5' r='20.5' fill={props.bgfill} />
  //     <path
  //       d='M20.066 26.3v.25h1.447v-1.182a3.696 3.696 0 0 0 1.193-.347 2.955 2.955 0 0 0 1.229-1.11c.293-.478.435-1.033.435-1.653 0-.713-.175-1.313-.552-1.771-.373-.455-.962-.795-1.733-1.04l-1.769-.57c-.517-.168-.867-.364-1.076-.575a1.052 1.052 0 0 1-.296-.759c0-.287.075-.532.22-.743.153-.222.362-.398.635-.527.27-.128.584-.195.947-.196.38.005.717.088 1.018.246l.002.001c.302.155.547.372.738.653l.001.002c.195.28.322.61.377.998l.037.262.26-.053.946-.192.237-.048-.04-.238c-.104-.622-.316-1.168-.64-1.632A3.29 3.29 0 0 0 22.437 15a3.404 3.404 0 0 0-.925-.315V13.45h-1.446v1.214c-.364.055-.7.16-1.009.316-.486.25-.87.6-1.149 1.053-.276.452-.41.972-.41 1.551 0 .626.17 1.17.52 1.614.35.441.863.765 1.517.984l2.06.687h.003c.538.174.882.372 1.07.577.18.195.282.466.282.84 0 .496-.178.885-.535 1.187-.361.302-.845.463-1.474.463-.616 0-1.129-.168-1.552-.495-.418-.332-.694-.781-.824-1.361l-.053-.236-.237.044-.92.172-.242.045.04.243c.106.616.328 1.157.671 1.617l.001.002c.347.457.784.812 1.309 1.063.293.138.604.238.932.3v.97Z'
  //       fill={props.mainfill}
  //       stroke={props.mainfill}
  //       stroke-width='.5'
  //     />
  //     <circle
  //       cx='21'
  //       cy='20'
  //       r='8.25'
  //       stroke={props.mainfill}
  //       stroke-width='1.5'
  //     />
  //   </svg>
);
export default Export;
