import React, { Fragment, useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useGetPartnersMutation } from '../../../redux/features/user/partnersApiSlice';
import { useLocation } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';

const SendResourcesEmail = () => {
  let [isOpen, setIsOpen] = useState(false);
  const [res, setRes] = useState(null);
  const location = useLocation();
  const path = location.pathname.split('/')[1];
  const [partnerId, setPartnerId] = useState(-1);

  const MaterialsObj = [
    {
      Name:'Brand Kit'
    },
    {
      Name:'Training Syllabus'
    },
    {
      Name:'Demo credentials'
    },
    {
      Name: 'Wazuh overview - English version',
    },
    {
      Name: 'Wazuh overview - Spanish version',
    },
    {
      Name: 'Cloud and On-prem',
    },
    {
      Name: 'Wazuh Service Offerings',
    },
    {
      Name: 'Wazuh File Integrity Monitoring',
    },
    {
      Name: 'Wazuh Office 365',
    },
    {
      Name: 'Wazuh Brochure',
    },
    {
      Name: 'Wazuh Product Overview',
    },
    {
      Name: 'Wazuh Partners Program',
    },
    {
      Name: 'Wazuh Partners Program Terms',
    },
    {
      Name: 'Sales Training for Wazuh Partners',
    },
    {
      Name: 'Wazuh and Alignet',
    },
    {
      Name: 'Sales Course',
    },
    {
      Name: 'Wazuh vs AlertLogic',
    },
    {
      Name: 'Wazuh vs Alienvault OSSIM',
    },
    {
      Name: 'Wazuh vs Alienvault USM',
    },
    {
      Name: 'Wazuh vs Amazon Guard Duty',
    },
    {
      Name: 'Wazuh vs ArcSight',
    },
    {
      Name: 'Wazuh vs AWS Inspector',
    },
    {
      Name: 'Wazuh vs AWS Security Hub',
    },
    {
      Name: 'Wazuh vs Camel Secure',
    },
    {
      Name: 'Wazuh vs Centreon Monitoring',
    },
    {
      Name: 'Wazuh vs Chronicle',
    },
    {
      Name: 'Wazuh vs Cisco Secure X',
    },
    {
      Name: 'Wazuh vs Crowdstrike',
    },
    {
      Name: 'Wazuh vs Cybereason',
    },
    {
      Name: 'Wazuh vs Cynet',
    },
    {
      Name: 'Wazuh vs Cyrebro',
    },
    {
      Name: 'Wazuh vs DarkTrace',
    },
    {
      Name: 'Wazuh vs Datadog',
    },
    {
      Name: 'Wazuh vs Elastic',
    },
    {
      Name: 'Wazuh vs Exabeam',
    },
    {
      Name: 'Wazuh vs FortiSIEM',
    },
    {
      Name: 'Wazuh vs FortiXDR',
    },
    {
      Name: 'Wazuh vs IBM QRadar',
    },
    {
      Name: 'Wazuh vs Logpoint',
    },
    {
      Name: 'Wazuh vs LogRhythm',
    },
    {
      Name: 'Wazuh vs Lumu',
    },
    {
      Name: 'Wazuh vs ManageEngine EventLog Analyzer',
    },
    {
      Name: 'Wazuh vs McAfee',
    },
    {
      Name: 'Wazuh vs Microsoft 365 Defender',
    },
    {
      Name: 'Wazuh vs Microsoft Sentinel',
    },
    {
      Name: 'Wazuh vs Palo Alto Cortex',
    },
    {
      Name: 'Wazuh vs Rapid7',
    },
    {
      Name: 'Wazuh vs Security Onion',
    },
    {
      Name: 'Wazuh vs Securonix',
    },
    {
      Name: 'Wazuh vs Sentinelone',
    },
    {
      Name: 'Wazuh vs Sophos',
    },
    {
      Name: 'Wazuh vs Splunk',
    },
    {
      Name: 'Wazuh vs Stellar Cyber',
    },
    {
      Name: 'Wazuh vs SureLog',
    },
    {
      Name: 'Wazuh vs Tenable',
    },
    {
      Name: 'Wazuh vs Trellix',
    },
    {
      Name: 'Wazuh vs Trend Micro',
    },
    {
      Name: 'Wazuh vs UTMStack',
    },
    {
      Name: 'Wazuh vs Xcitium',
    },
  ];

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const modalInitialValues = {
    resources: [],
  };

  const handleSubmit = async (formData) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/files/sendupdatedresourcemail`,
        formData
      );
      setRes(res.data);
      // const res = await updatePartnerSalesforceId(formData).unwrap();
      // setRes(res);

      // console.log('partner name updated successfully');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {' '}
      <button
        onClick={openModal}
        type='button'
        className='bg-[#3D82F1] hover:bg-blue-600  w-64 rounded-lg  text-white py-2 text-center items-center'
      >
        Send Resources Email
      </button>
      <Transition
        appear
        show={isOpen}
        //   show={modalDeletePartnerOpen}
        as={Fragment}
      >
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-6xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 mb-2 text-gray-900'
                  >
                    Send Resources Email
                  </Dialog.Title>
                  <div>
                    <Formik
                      enableReinitialize={true}
                      initialValues={modalInitialValues}
                      onSubmit={handleSubmit}
                    >
                      <Form className=''>
                        <div className='grid grid-cols-3'>
                          {MaterialsObj.map((object) => (
                            <label className='w-[1600px]'>
                              <Field
                                type='checkbox'
                                name='resources'
                                value={object.Name}
                              />
                              {object.Name}
                            </label>
                          ))}
                        </div>
                        <button
                          type='submit'
                          className='bg-[#4d3ed8] w-full hover:bg-purple-700 px-2 rounded-lg  text-white py-2 text-center items-center'
                        >
                          {res ? res : 'Submit'}
                        </button>
                      </Form>
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default SendResourcesEmail;
