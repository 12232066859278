import LevelsModal from './Modals/LevelsModal';

const MobileLevels = ({ discount, plan }) => {
  return (
    <>
      <div className='mr-8  mt-4 flex w-full'>
        <div className='sm:min-w-full mr-4 h-5 xl:h-7 bg-gray-200 rounded-full w-[80%] sm:w-[299px]'>
          {plan === 'Platinum' ? (
            <>
              {discount === 0 || discount === null ? (
                <div
                  className={`h-5 xl:h-7 items-center text-xs xl:text-sm   bg-yellow-gradient rounded-full w-[0%] flex relative`}
                >
                  <div className=' hidden w-[35px] h-[35px] xl:w-[45px] xl:h-[45px] rounded-full bg-yellow-gradient border-4 border-white absolute right-0 items-center justify-center'>
                    <h1 className='font-bold'>00</h1>
                  </div>
                  <div className='ml-3'>-</div>
                </div>
              ) : discount > 0 && discount <= 21 ? (
                <div
                  className={`h-5 xl:h-7 items-center text-xs xl:text-sm   bg-yellow-gradient rounded-full w-[50%] flex relative`}
                >
                  <div className=' w-[35px] h-[35px] xl:w-[45px] xl:h-[45px] rounded-full bg-yellow-gradient border-4 border-white absolute right-0 items-center flex justify-center'>
                    <h1 className='font-bold'>01</h1>
                  </div>
                  <div className='ml-3'>18% off list price</div>
                </div>
              ) : discount > 21 && discount <= 25 ? (
                <div
                  className={`h-5 xl:h-7 items-center text-xs xl:text-sm   bg-yellow-gradient rounded-full w-[75%] flex relative`}
                >
                  <div className=' w-[35px] h-[35px] xl:w-[45px] xl:h-[45px] rounded-full bg-yellow-gradient border-4 border-white absolute right-0 items-center flex justify-center'>
                    <h1 className='font-bold'>02</h1>
                  </div>
                  <div className='ml-3'>22% off list price</div>
                </div>
              ) : discount > 25 && discount <= 29 ? (
                <div
                  className={`h-5 xl:h-7 items-center text-xs xl:text-sm   bg-yellow-gradient rounded-full w-[85%] flex relative`}
                >
                  <div className=' w-[35px] h-[35px] xl:w-[45px] xl:h-[45px] rounded-full bg-yellow-gradient border-4 border-white absolute right-0 items-center flex justify-center'>
                    <h1 className='font-bold'>03</h1>
                  </div>
                  <div className='ml-3'>26% off list price</div>
                </div>
              ) : (
                <div
                  className={`h-5 xl:h-7 items-center text-xs xl:text-sm   bg-yellow-gradient rounded-full w-[100%] flex relative`}
                >
                  <div className=' w-[35px] h-[35px] xl:w-[45px] xl:h-[45px] rounded-full bg-yellow-gradient border-4 border-white absolute right-0 items-center flex justify-center'>
                    <h1 className='font-bold'>04</h1>
                  </div>
                  <div className='ml-3'>30% off list price</div>
                </div>
              )}
            </>
          ) : plan === 'Gold' ? (
            <>
              {discount === 0 || discount === null ? (
                <div
                  className={`h-5 xl:h-7 bg-yellow-gradient rounded-full w-[0%] flex`}
                >
                  <div className='ml-3'>-</div>
                </div>
              ) : discount > 0 && discount <= 13 ? (
                <div
                  className={`h-5 xl:h-7 items-center text-xs xl:text-sm   bg-yellow-gradient rounded-full w-[50%] flex relative`}
                >
                  <div className=' w-[35px] h-[35px] xl:w-[45px] xl:h-[45px] rounded-full bg-yellow-gradient border-4 border-white absolute right-0 items-center flex justify-center'>
                    <h1 className='font-bold'>01</h1>
                  </div>
                  <div className='ml-3'>10% off list price</div>
                </div>
              ) : discount > 13 && discount <= 17 ? (
                <div
                  className={`h-5 xl:h-7 items-center text-xs xl:text-sm   bg-yellow-gradient rounded-full w-[60%] flex relative`}
                >
                  <div className=' w-[35px] h-[35px] xl:w-[45px] xl:h-[45px] rounded-full bg-yellow-gradient border-4 border-white absolute right-0 items-center flex justify-center'>
                    <h1 className='font-bold'>02</h1>
                  </div>
                  <div className='ml-3'>14% off list price</div>
                </div>
              ) : discount > 17 && discount <= 21 ? (
                <div
                  className={`h-5 xl:h-7 items-center text-xs xl:text-sm   bg-yellow-gradient rounded-full w-[75%] flex relative`}
                >
                  <div className=' w-[35px] h-[35px] xl:w-[45px] xl:h-[45px] rounded-full bg-yellow-gradient border-4 border-white absolute right-0 items-center flex justify-center'>
                    <h1 className='font-bold'>03</h1>
                  </div>
                  <div className='ml-3'>18% off list price</div>
                </div>
              ) : (
                <div
                  className={`h-5 xl:h-7 items-center text-xs xl:text-sm   bg-yellow-gradient rounded-full w-[75%] flex relative`}
                >
                  <div className=' w-[35px] h-[35px] xl:w-[45px] xl:h-[45px] rounded-full bg-yellow-gradient border-4 border-white absolute right-0 items-center flex justify-center'>
                    <h1 className='font-bold'>04</h1>
                  </div>
                  <div className='ml-3'>22% off list price</div>
                </div>
              )}
            </>
          ) : plan == 'Old MSSP' ? (
            <div
              className={`h-5 xl:h-7 items-center text-xs xl:text-sm   bg-[#FEDD0B] rounded-full w-full flex relative`}
            >
              <div className='ml-3'>{discount}% off list price</div>
            </div>
          ) : plan == null ? (
            <div className='ml-3'>-</div>
          ) : (
            <div className='ml-3'>{plan}</div>
          )}
        </div>
      </div>
      <div className='flex mx-2 pt-4 text-xs xl:text-sm'>
        <div className='flex text-center items-center justify-between sm:w-[344px]'>
          <LevelsModal plan={plan} discount={discount} />
          <div className='flex items-center text-white'>
            <h1 className=' text-white ml-1'>Tier: </h1>
            {plan === 'Platinum' ? (
              <div>
                {' '}
                {discount === 0 || discount === null
                  ? '0'
                  : discount > 0 && discount <= 21
                  ? '1'
                  : discount > 21 && discount <= 25
                  ? '2'
                  : discount > 25 && discount <= 29
                  ? '3'
                  : '4'}
              </div>
            ) : plan === 'Gold' ? (
              <div>
                {discount === 0 || discount === null
                  ? '0'
                  : discount > 0 && discount <= 13
                  ? '1'
                  : discount > 13 && discount <= 17
                  ? '2'
                  : discount > 17 && discount <= 21
                  ? '3'
                  : '4'}
              </div>
            ) : plan == null ? (
              <div>-</div>
            ) : (
              <div>-</div>
            )}
            /4
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileLevels;
