import React from 'react';
import WazuhPartnersPortal from './img/WazuhPartnersPortal.svg';
import MenuProfile from './Menu';
import MobileMenu from './MobileMenu';
import { Link } from 'react-router-dom';

const DashboardNavbar = () => {
  return (
    <div className='flex fixed w-full items-center justify-center h-16 px-5 bg-white shadow-lg z-10 3xl:max-w-[1400px]'>
      <div className='w-full flex justify-between'>
        <div className='flex items-center'>
          <Link to='/dashboard'>
            <img
              className='w-48 sm:w-auto lg:pl-3'
              src={WazuhPartnersPortal}
              alt='wazuh-logo'
            ></img>
          </Link>
        </div>
        <div className='flex items-center'>
          <div className='hidden lg:block'>
            <MenuProfile />
          </div>
          <div className='lg:hidden'>
            <div className='z-[30]'>
              <MobileMenu />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardNavbar;
