import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { useEditStageMutation } from '../../redux/features/user/dealsApiSlice';
import { useGetPartnerByIdMutation } from '../../redux/features/user/partnersApiSlice';

export default function ReOpenDeal({
  dealName,
  dealId,
  setReload,
  reloadValue,
}) {
  let [isOpen, setIsOpen] = useState(false);
  let [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [editStage] = useEditStageMutation();
  const [partnerData, setPartnerData] = useState([]);
  const [getByIdPartner] = useGetPartnerByIdMutation();
  const userinfo = useAuth();

  useEffect(() => {
    const getPartnerData = async () => {
      const result = await getByIdPartner(userinfo.partnerId).unwrap();
      setPartnerData(result);
    };
    getPartnerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  function closeSuccessModal() {
    setIsSuccessOpen(false);
  }

  const changeStage = async () => {
    try {
      await editStage({
        StageName: 'MQL - Discover',
        Id: dealId,
        email: userinfo.email,
        username: userinfo.username,
        dealname: dealName,
        partner: partnerData[0].name,
      })
        .unwrap()
        .then(setIsOpen(false))
        .then(setIsSuccessOpen(true))
        .then(setReload(!reloadValue));
    } catch (error) {
      console.log(error);
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  const handleSubmit = (stage) => {
    openModal();
  };

  return (
    <>
      <button
        onClick={() => handleSubmit('Discover')}
        className='bg-[#FEDD0B] hover:bg-[#FCE765] px-3 py-1 rounded-lg text-black text-sm 2xl:text-base'
      >
        Re-open deal
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto '>
            <div className='flex min-h-full  items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full font-manrope max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  {/* <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    Change Stage1
                  </Dialog.Title> */}
                  <div className=''>
                    <p className='font-semibold text-lg'>
                      Are you sure you want to re open this deal?
                    </p>
                  </div>
                  <div className='text-[#3D82F1] text-base'>
                    Once the sales team approves the deal, the customer will be
                    added to the list.
                  </div>
                  <div className='mt-6 justify-end flex'>
                    <button
                      type='button'
                      className='inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-[#3D82F1] hover:underline focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type='button'
                      className='inline-flex ml-5 justify-center rounded-md border border-transparent bg-[#3D82F1] px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      onClick={changeStage}
                    >
                      Accept!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isSuccessOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeSuccessModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto '>
            <div className='flex min-h-full  items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full font-manrope max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  {/* <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    Change Stage1
                  </Dialog.Title> */}
                  <div className=''>
                    <p className='font-semibold text-lg'>
                      Deal re opened succesfully!
                    </p>
                  </div>
                  <div className='mt-6 justify-end flex'>
                    <button
                      type='button'
                      className='inline-flex ml-5 justify-center rounded-md border border-transparent bg-[#3D82F1] px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      onClick={closeSuccessModal}
                    >
                      Accept!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
