import React, { useState, useEffect } from 'react';
import { useGetCustomersMutation } from '../redux/features/user/dealsApiSlice';
import {
  useGetPartnerByIdMutation,
  useGetPartnerOwnerMutation,
} from '../redux/features/user/partnersApiSlice';
import useAuth from '../hooks/useAuth';
import {
  useGetDealsMutation,
  useGetAllExpiredCustomersMutation,
} from '../redux/features/user/dealsApiSlice';
import PartnerCurrentContracts from '../components/AnalyticsPanel/PartnerCurrentContracts';
import CloseRate from '../components/AnalyticsPanel/CloseRate';
import OpportunitiesPerYear from '../components/AnalyticsPanel/OpportunitiesPerYear';
import RevenuePerYear from '../components/AnalyticsPanel/RevenuePerYear';
import ContactSection from '../components/AnalyticsPanel/ContactSection';
import AnalyticsBreadcrumb from '../components/AnalyticsBreadcrumb';

const Analytics = () => {
  const [partnerData, setPartnerData] = useState(null);
  const [partnerOwnerData, setPartnerOwnerData] = useState(null);
  const [partnerDataLoading, setPartnerDataLoading] = useState(true);
  const [customers, SetCustomers] = useState([]);
  const [allExpiredcustomers, SetAllExpiredCustomers] = useState([]);
  const [deals, SetDeals] = useState([]);
  const [callLoadingCustomers, setCallLoadingCustomers] = useState(true);
  const [callLoadingDeals, setCallLoadingDeals] = useState(true);
  const [getCustomers] = useGetCustomersMutation();
  const [getDeals] = useGetDealsMutation();
  const [getAllExpiredCustomers] = useGetAllExpiredCustomersMutation();
  const [getPartner] = useGetPartnerByIdMutation();
  const [getPartnerOwner] = useGetPartnerOwnerMutation();
  const userinfo = useAuth();

  const allDealsLoading = callLoadingDeals || callLoadingCustomers;
  useEffect(() => {
    const getPartnerData = async () => {
      const partnerInfo = await getPartner(userinfo.partnerId).unwrap();
      setPartnerData(partnerInfo);
      await setPartnerDataLoading(false);
    };
    getPartnerData();
  }, []);

  useEffect(() => {
    const getPartnerOwnerData = async () => {
      const partnerOwnerInfo = await getPartnerOwner(
        partnerData[0].salesforceId
      ).unwrap();
      setPartnerOwnerData(partnerOwnerInfo);
    };
    getPartnerOwnerData();
  }, [partnerData]);

  useEffect(() => {
    const getCustomersData = async () => {
      if (partnerData && partnerData.length > 0) {
        const result = await getAllExpiredCustomers(
          partnerData[0].salesforceId
        ).unwrap();
        SetAllExpiredCustomers(result);
      }
    };
    getCustomersData();
  }, [partnerData]);

  useEffect(() => {
    const getDealsData = async () => {
      if (partnerData && partnerData.length > 0) {
        const result = await getDeals(partnerData[0].salesforceId).unwrap();
        SetDeals(result);
        await setCallLoadingDeals(false);
      }
    };
    getDealsData();
  }, [partnerData]);

  useEffect(() => {
    const getCustomersData = async () => {
      if (partnerData && partnerData.length > 0) {
        const result = await getCustomers(partnerData[0].salesforceId).unwrap();
        // console.log(result, 'customers result');
        SetCustomers(result);
        await setCallLoadingCustomers(false);
      }
    };
    getCustomersData();
  }, [partnerData]);

  const CloudTypes = [
    'Customer New Cloud',
    'Customer Renewal Cloud',
    'Customer Upgrade Cloud',
    'Customer Renewal/Upgrade Cloud',
    'Customer Renewal/Downgrade Cloud',
    'Partner Customer New Cloud',
    'Partner Customer Renewal Cloud',
    'Partner Customer Renewal/Upgrade Cloud',
    'Partner Customer Renewal/Downgrade Cloud',
  ];

  const PremisesTypes = [
    'Customer New On-premise',
    'Customer Renewal On-premise',
    'Customer Renewal/Expansion On-premise',
    'Customer Renewal/Reduction On-premise',
    'Customer Expansion On-premise',
    'Partner Customer New On premises',
    'Partner Customer Renewal On premises',
    'Partner Customer Renewal/ Upgrade On premises',
  ];

  const filteredDeals = deals
    .filter(
      (obj) => CloudTypes.includes(obj.Type) || PremisesTypes.includes(obj.Type)
    )
    .map((obj) => {
      return {
        ...obj,
        Type: CloudTypes.includes(obj.Type) ? 'Cloud' : 'On premises',
      };
    });

  const filteredCustomers = customers
    .filter(
      (obj) => CloudTypes.includes(obj.Type) || PremisesTypes.includes(obj.Type)
    )
    .map((obj) => {
      return {
        ...obj,
        Type: CloudTypes.includes(obj.Type) ? 'Cloud' : 'On premises',
      };
    });

  // const allDeals = [...allExpiredcustomers, ...customers, ...deals];
  const allDeals = [...filteredCustomers, ...filteredDeals];

  return (
    <div className='bg-white'>
      <div className='text-2xl 2xl:text-3xl font-semibold p-5 h-[72px] 2xl:h-[76px] xl:pl-[22px] 2xl:px-[40px] text-white bg-[#3D82F1] flex items-center rounded-2xl m-3 lg:m-7'>
        Analytics
      </div>
      {userinfo.role === 'wazuh' && (
        <div className='ml-8 lg:ml-[52px] 2xl:ml-[70px] mt-4 flex text-xl font-semibold'>
          Partner /{' '}
          <div className=' ml-1'>
            <AnalyticsBreadcrumb partnerName={partnerData} />
          </div>
        </div>
      )}
      <div className='grid grid-cols-1 xl:grid-cols-2  gap-9 mx-4 lg:mx-0 lg:ml-[52px] 2xl:ml-[70px] lg:pr-[63px] my-10'>
        <div className='col-span-1 '>
          <PartnerCurrentContracts
            customers={filteredCustomers}
            loading={callLoadingCustomers}
          />
        </div>
        <div className='col-span-1 '>
          <RevenuePerYear
            customers={filteredCustomers}
            loading={callLoadingCustomers}
          />
        </div>
        <div className='col-span-1 '>
          <OpportunitiesPerYear deals={allDeals} loading={callLoadingDeals} />
        </div>
        <div className='col-span-1 '>
          <CloseRate allDeals={allDeals} loading={allDealsLoading} />
        </div>
        <div className='col-span-1 xl:col-span-2 flex flex-col'>
          <ContactSection ownerData={partnerOwnerData} />
        </div>
      </div>
    </div>
  );
};

export default Analytics;
