import * as React from 'react';
const Export = (props) => (
  <svg
    width='41'
    height='41'
    viewBox='0 0 41 41'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='20.5' cy='20.5' r='20.5' fill={props.bgfill} />
    <path
      d='M20.788 20.663c2.211-.884 4.402-.884 6.613 0a.715.715 0 0 0 .98-.663v-8.571a.714.714 0 0 0-.396-.64c-2.093-1.046-4.665-1.046-7.688-.038-2.69.897-4.88.897-6.597.039a.714.714 0 0 0-1.034.639v17.857a.714.714 0 1 0 1.429 0V20.79c2.016.895 4.261.846 6.693-.127Zm-6.693-8.176c1.887.601 4.111.467 6.654-.38 2.489-.83 4.55-.893 6.203-.218v7.106c-2.23-.662-4.47-.548-6.694.342-2.2.88-4.132.88-5.844.024a.71.71 0 0 0-.32-.075v-6.799Z'
      fill={props.mainfill}
    />
  </svg>
);
export default Export;
