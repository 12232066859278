import { apiSlice } from '../../apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTrainings: builder.mutation({
      query: () => ({
        url: '/api/trainings/gettrainings',
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    submitTraining: builder.mutation({
      query: (stage) => ({
        url: `/api/trainings/submittraining`,
        method: 'POST',
        body: stage,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getTrainingsLeft: builder.mutation({
      query: (partner) => ({
        url: `/api/trainings/gettrainingsleft/${partner}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
  }),
});

export const {
  useGetTrainingsMutation,
  useSubmitTrainingMutation,
  useGetTrainingsLeftMutation,
} = authApiSlice;
