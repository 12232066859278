import { useState } from 'react';
import MobileDealsCard from './MobiledDealsCard';
import Search from '../components/img/Search.svg';
import Plus from '../components/img/Plus.svg';
import { downloadExcel } from 'react-export-table-to-excel';
import Export from './img/Export';
import { Link } from 'react-router-dom';

const MobileRegisteredDeals = ({ deals }) => {
  const [query, setQuery] = useState('');
  const [stageType, setStageType] = useState('');

  const search = (data) => {
    return data.filter(
      (item) =>
        item.Name.toLowerCase().includes(query.toLowerCase()) ||
        item.Type.toLowerCase().includes(query.toLowerCase()) ||
        item.CloseDate.toLowerCase().includes(query.toLowerCase()) ||
        item.StageName.toLowerCase().includes(query.toLowerCase())
    );
  };

  const searchByStage = (data) => {
    return data.filter((item) =>
      item.StageName.toLowerCase().includes(stageType.toLowerCase())
    );
  };

  const filteredDeals = searchByStage(search(deals));

  const body = searchByStage(search(deals)).map((data) => [
    data.Name,
    data.Type,
    data.CloseDate,
    data.StageName,
  ]);

  const header = ['Company', 'Service', 'Close Date', 'Stage'];

  function handleDownloadExcel() {
    downloadExcel({
      fileName: 'Excel Deals',
      sheet: 'Deals',
      tablePayload: {
        header,
        body: body,
      },
    });
  }

  return (
    <div className='md:hidden mt-4 mx-6'>
      <div className='flex my-2 h-8'>
        <span className='inline-flex relative  items-center px-2 text-gray-900 bg-gray-[#DBE3EB] rounded-l-md  border-r-0  '>
          <img alt='search' src={Search} />
        </span>
        <input
          type='text'
          id='website-admin'
          className='rounded-md -ml-8 pl-8 py-1 bg-gray-50 border  text-gray-900 focus:ring-mainblue focus:border-mainblue block flex-1 min-w-0  w-24 text-sm border-gray-300'
          placeholder='Search'
          onChange={(e) => setQuery(e.target.value)}
        />
        <button
          onClick={handleDownloadExcel}
          className='text-[#292929] block md:hidden items-center bg-[#EBD0FC] hover:bg-[#D3ADF0] rounded-lg px-[10px] ml-4 h-8'
        >
          <Export className='w-6 h-5' fill='#292929' />
        </button>
        <Link to='/deal-registration' className='flex'>
          <button className='ml-8 py-1 px-[14px] rounded-lg bg-[#FEDD0B] hover:bg-[#FCE765]'>
            <img src={Plus} />
          </button>
        </Link>
      </div>
      {/* <div className='flex my-1'>
        <select
          onChange={(e) => setStageType(e.target.value)}
          className=' border border-[#B7C6D7] rounded-md w-full px-2 py-2 text-black placeholder-[#B7C6D7] styled-select'
        >
          <option value=''>All Stage type</option>
          <option value='Discover'>Pending approval</option>
        
          <option value='Selection Process'>Selection Process</option>
          <option value='Selection Achieved'>Selection Achieved</option>
          <option value='In Procurement'>In Procurement</option>
          <option value='Closed Won'>Closed Won</option>
          <option value='Closed Lost'>Closed Lost</option>
          <option value='Closed Disqualified'>Closed Disqualified</option>
        </select>
      </div> */}
      <div className='bg-[#292929] text-white font-semibold grid grid-cols-6 rounded-lg mt-4 py-2'>
        <div className='ml-4 col-span-3'>Comapny</div>
        <div className='ml-4'>Service</div>
      </div>
      {filteredDeals.map((deal) => (
        <MobileDealsCard key={deal.Id} deal={deal} />
      ))}
    </div>
  );
};

export default MobileRegisteredDeals;
