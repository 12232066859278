import React, { useState } from 'react';
import WhiteRightArrow from '../components/img/WhiteRightArrow.svg';
import BlackRightArrow from '../components/img/BlackRightArrow.svg';
import Clock from '../components/img/Clock.svg';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import CountriesList from '../components/CountriesList';
import HelpRequestModal from '../components/Modals/HelpRequest';
import DemoRequestModal from '../components/Modals/DemoRequest';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import ContactSalesFormImage from '../components/img/ContactSalesFormImage.png';
import RequestADemoFormImage from '../components/img/RequestADemoFormImage.png';

const helpValidationSchema = Yup.object({
  user: Yup.string()
    .required('User is required')
    .max(30, 'User must be 30 characters or less.'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .max(70, 'Email name must be 70 characters or less.'),
  body: Yup.string().required('Comment is required'),
  company_name: Yup.string().required('Company name is required'),
  timezone: Yup.string().required('Timezone name is required'),
});

const ContactUs = () => {
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [openDemoModal, setOpenDemoModal] = useState(false);
  const userinfo = useAuth();

  const initialData = {
    user: userinfo.username,
    email: userinfo.email,
    body: '',
    company_name: '',
    timezone: 'United States',
  };

  const SubmitRequest = async (formData) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/mails/consult`,
        formData
      );
      setOpenRequestModal(true);
    } catch (err) {
      console.err(err);
    }
  };

  const CloseSubmitRequest = () => {
    setOpenRequestModal(false);
  };

  const SubmitDemoRequest = async (formData) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/mails/request-a-demo`,
        formData
      );
      setOpenDemoModal(true);
    } catch (error) {
      console.err(error);
    }
  };

  const CloseDemoRequest = () => {
    setOpenDemoModal(false);
  };

  const location = useLocation();
  const path = location.pathname.split('/')[2];
  const handleSubmit = async (formData) => {
    if (path === 'sales-team') {
      await SubmitRequest(formData);
    } else if (path === 'request-a-demo') {
      await SubmitDemoRequest(formData);
    }
  };

  return (
    <div>
      <div className='text-2xl 2xl:text-3xl font-semibold p-5 h-[72px] 2xl:h-[76px] xl:pl-[22px] 2xl:px-[40px] text-white bg-[#3D82F1] flex items-center rounded-2xl m-3 lg:m-7'>
        {path === 'sales-team'
          ? 'Contact Sales Team'
          : path === 'request-a-demo'
          ? 'Request a demo'
          : 'Contact us'}
      </div>
      <Formik
        enableReinitialize
        validationSchema={helpValidationSchema}
        initialValues={initialData}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, dirty, isValid }) => (
          <Form className='ml-8 mr-8 lg:mx-[52px] 2xl:mx-[70px] mt-12'>
            <div className='flex flex-col md:flex-row gap-5'>
              <div>
                <img
                  className='w-full md:w-auto xl:w-[500px]'
                  src={`${
                    path === 'sales-team'
                      ? ContactSalesFormImage
                      : RequestADemoFormImage
                  }`}
                ></img>
              </div>
              <div className='md:ml-10  flex-grow'>
                <div className='flex flex-col justify-around '>
                  <div className='mt-4 md:mt-0'>
                    {errors.company_name && touched.company_name ? (
                      <p className='font-semibold mb-2 text-red-500 text-sm 2xl:text-base'>
                        {errors.company_name}
                      </p>
                    ) : (
                      <p className='font-semibold mb-2 text-sm 2xl:text-base'>
                        Customer company name {errors.company_name}
                      </p>
                    )}
                    <div className='flex'>
                      <Field
                        placeholder='Company name'
                        type='text'
                        name='company_name'
                        className='text-sm 2xl:text-base border hover:border-[#3D82F1] focus:border-[#3D82F1] border-[#B7C6D7] rounded-md w-full px-2 py-2 text-black placeholder-[#B7C6D7]'
                      ></Field>
                    </div>
                  </div>
                  <div className='mt-4 sm:mt- md:mt-0'>
                    {errors.timezone && touched.timezone ? (
                      <p className='font-semibold mb-2 text-red-500 text-sm 2xl:text-base'>
                        {errors.timezone}
                      </p>
                    ) : (
                      <p className='font-semibold mb-2 mt-4 text-sm 2xl:text-base'>
                        Country
                      </p>
                    )}
                    <div className='flex'>
                      <span className='inline-flex relative  items-center px-2 text-gray-900 bg-gray-[#DBE3EB] rounded-l-md  border-r-0'>
                        <img className='w-4 h-4' alt='search' src={Clock} />
                      </span>
                      <Field
                        as='select'
                        name='timezone'
                        className='text-sm 2xl:text-base -ml-8 pl-8 styled-select border hover:cursor-pointer hover:border-[#3D82F1] focus:border-[#3D82F1] border-[#B7C6D7] rounded-md w-full px-2 py-2 text-black placeholder-[#B7C6D7]'
                      >
                        <CountriesList />
                      </Field>
                    </div>
                  </div>
                </div>
                <div className='2xl:relative'>
                  {errors.body && touched.body ? (
                    <p className='font-semibold mb-2 text-red-500 text-sm 2xl:text-base mt-4'>
                      {errors.body}
                    </p>
                  ) : (
                    <h1 className='mb-2 font-semibold text-sm 2xl:text-base mt-4'>
                      Comments
                    </h1>
                  )}
                  <Field
                    className='text-sm 2xl:text-base w-full sm:max-w-none border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md  h-36 lg:h-28 xl:h-[257px] 2xl:h-[237px] resize-none px-2 py-2 text-black placeholder-[#B7C6D7] '
                    placeholder='Write your comment here'
                    as='textarea'
                    name='body'
                  ></Field>
                </div>
                <div className='flex justify-end mt-8'>
                  {dirty && isValid ? (
                    <button
                      type='submit'
                      className='bg-yellow-gradient btn justify-end sm:justify-end  lg:mr-0  rounded-lg px-2 py-2 flex items-center'
                    >
                      Submit
                      <img
                        alt='black arrow pointing right'
                        className='ml-12'
                        src={BlackRightArrow}
                      ></img>
                    </button>
                  ) : (
                    <div className='bg-[#B7C6D7] justify-end sm:justify-end text-white  lg:mr-0  rounded-lg px-2 py-2 flex items-center hover:cursor-not-allowed'>
                      Submit
                      <img
                        alt='white arrow pointing right'
                        className='ml-12'
                        src={WhiteRightArrow}
                      ></img>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <HelpRequestModal
              isOpen={openRequestModal}
              openFunction={SubmitRequest}
              closeFunction={CloseSubmitRequest}
            />
            <DemoRequestModal
              isOpen={openDemoModal}
              openFunction={SubmitDemoRequest}
              closeFunction={CloseDemoRequest}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactUs;
