import React, { Fragment, useState } from 'react';
import RightArrow from '../components/img/RightArrow.svg';
import { useNavigate, Link } from 'react-router-dom';
import Test from '../components/Popover/Test2';
import Oval from '../components/img/Oval.svg';
import { useLocation } from 'react-router-dom';
import WhiteRightArrow from '../components/img/WhiteRightArrow.svg';
import ProductInfo from '../components/Popover/ProductInfo';

const MyCustomersTable = ({ loading, data, setSort, setOrientation }) => {
  const [lastSort, setLastSort] = useState('Partner_Client__c');
  const [lastOrientation, setLastOrientation] = useState('ascending');
  const location = useLocation();
  const pathname = location.pathname.split('/')[1];
  // console.log(pathname, 'pathname');
  const navigate = useNavigate();
  const handleRowClick = (id) => {
    navigate(`/customer/${id}`);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function reformatDate(dateStr) {
    let dArr = dateStr.split('-'); // ex input "2010-01-18"
    return dArr[1] + '/' + dArr[2] + '/' + dArr[0]; //ex out: "18/01/10"
  }

  const setSortAndOrientation = (orientation, sort) => {
    if (orientation === lastOrientation && sort === lastSort) {
      setOrientation('descending');
      setSort(sort);
      setLastOrientation('descending');
    } else {
      setOrientation('ascending');
      setSort(sort);
      setLastOrientation('ascending');
    }
    setLastSort(sort);
  };

  return (
    <div>
      {!data.length && loading === true ? (
        <div className='hidden lg:flex justify-center mt-12'>
          <img alt='loading' src={Oval} />
        </div>
      ) : (
        <div>
          <div
            style={{ scrollbarGutter: 'stable' }}
            className='hidden md:grid grid-cols-24 mt-4 mx-8 md:max-h-[calc(100vh-205px)] lg:max-h-[calc(100vh-270px)] xl:max-h-[calc(100vh-230px)] 2xl:max-h-[calc(100vh-300px)] 3xl:max-h-[650px] overflow-y-auto 2xl:ml-[32px] 2xl:mr-[27px]'
          >
            <div className=' bg-[#292929] text-white col-span-6 rounded-l-lg pl-4  text-sm  xl:text-lg font-semibold py-2 sticky top-0'>
              <div className='flex items-center'>
                <button
                  className='flex ml-5 items-center'
                  onClick={() => {
                    setSortAndOrientation('ascending', 'Partner_Client__c');
                  }}
                >
                  Company
                  {lastSort === 'Partner_Client__c' && (
                    <img
                      className={`${
                        lastOrientation === 'ascending'
                          ? 'rotate-90'
                          : 'rotate-[270deg]'
                      } ml-2`}
                      src={WhiteRightArrow}
                    ></img>
                  )}
                </button>
              </div>
            </div>
            <div className='bg-[#292929] text-white  col-span-6 text-sm  xl:text-lg font-semibold py-2 sticky top-0'>
              <div className='flex items-center'>
                <button
                  className='flex items-center'
                  onClick={() => {
                    setSortAndOrientation('ascending', 'ProductName');
                  }}
                >
                  Actual Plan
                  {lastSort === 'ProductName' && (
                    <img
                      className={`${
                        lastOrientation === 'ascending'
                          ? 'rotate-90'
                          : 'rotate-[270deg]'
                      } ml-2`}
                      src={WhiteRightArrow}
                    ></img>
                  )}
                </button>
              </div>
            </div>
            <div className='bg-[#292929] text-white  col-span-4 text-sm  xl:text-lg font-semibold py-2 sticky top-0'>
              <div className='flex items-center'>
                <button
                  className='flex items-center'
                  onClick={() => {
                    setSortAndOrientation('ascending', 'Type');
                  }}
                >
                  Service
                  {lastSort === 'Type' && (
                    <img
                      className={`${
                        lastOrientation === 'ascending'
                          ? 'rotate-90'
                          : 'rotate-[270deg]'
                      } ml-2`}
                      src={WhiteRightArrow}
                    ></img>
                  )}
                </button>
              </div>
            </div>
            <div className='bg-[#292929] text-white col-span-4 text-sm  xl:text-lg font-semibold py-2 sticky top-0 z-[9]'>
              <div className='flex items-center'>
                <button
                  className='flex items-center'
                  onClick={() => {
                    setSortAndOrientation(
                      'ascending',
                      'Contract_Expiration_Date__c'
                    );
                  }}
                >
                  Expiration date
                  {lastSort === 'Contract_Expiration_Date__c' && (
                    <img
                      className={`${
                        lastOrientation === 'ascending'
                          ? 'rotate-90'
                          : 'rotate-[270deg]'
                      } ml-2`}
                      src={WhiteRightArrow}
                    ></img>
                  )}
                </button>
              </div>
            </div>
            <div className=' bg-[#292929] text-white  text-sm  xl:text-lg col-span-3 font-semibold py-2 sticky top-0'>
              <div className='flex items-center'>
                <button
                  className='flex items-center'
                  onClick={() => {
                    setSortAndOrientation('ascending', 'Amount');
                  }}
                >
                  Amount
                  {lastSort === 'Amount' && (
                    <img
                      className={`${
                        lastOrientation === 'ascending'
                          ? 'rotate-90'
                          : 'rotate-[270deg]'
                      } ml-2`}
                      src={WhiteRightArrow}
                    ></img>
                  )}
                </button>
              </div>
            </div>
            <div className='bg-[#292929] text-white rounded-r-lg col-span-1  text-sm  xl:text-lg font-semibold py-2 sticky top-0'></div>
            {data.map((customer) => {
              const today = new Date();
              const closeDate = new Date(customer.Contract_Expiration_Date__c);
              const diffDays = Math.ceil(
                (closeDate - today) / (1000 * 60 * 60 * 24)
              );
              // if (diffDays > 30) {
              return (
                <Fragment key={customer.Id}>
                  <div
                    className={`hover:cursor-pointer ${
                      pathname === 'dashboard' ? '' : ''
                    }  ${
                      diffDays > 0 && diffDays <= 30
                        ? 'rowWrapper-orange'
                        : 'rowWrapper'
                    }`}
                    onClick={() => handleRowClick(customer.Id)}
                  >
                    <div className='ml-5 items-center text-sm xl:text-base hover:rounded-l-lg flex pl-4  col-span-6 py-2 my-2'>
                      {customer.Partner_Client__c}
                    </div>
                    <div className='col-span-6 text-sm xl:text-base items-center flex  py-2 my-2'>
                      {customer.ProductName.length > 1 ? (
                        <div className='flex'>
                          {customer.ProductName.length} Products{' '}
                          <ProductInfo text={customer.ProductName} />
                        </div>
                      ) : (
                        <div>{customer.ProductName}</div>
                      )}
                      {/* {JSON.stringify(customer.ProductName)} */}
                    </div>
                    <div className='col-span-4 text-sm xl:text-base items-center flex  py-2 my-2'>
                      {customer.Type}
                    </div>
                    <div
                      className={`items-center text-sm xl:text-base col-span-4  flex  py-2 my-2 ${
                        diffDays > 0 && diffDays <= 30
                          ? 'font-semibold text-[#FF7A2F]'
                          : diffDays < 0
                          ? 'font-semibold text-[#FF0E00]'
                          : ''
                      }`}
                    >
                      {customer.Contract_Expiration_Date__c &&
                        reformatDate(customer.Contract_Expiration_Date__c)}
                      {diffDays > 0 && diffDays <= 30 ? (
                        <Test type={'expiring'} />
                      ) : diffDays < 0 ? (
                        <Test type={'expired'} />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='col-span-3 text-sm xl:text-base items-center flex  py-2 my-2'>
                      {'$ ' +
                        (customer.Amount && numberWithCommas(customer.Amount))}
                    </div>
                    <div className='items-center justify-center col-span-1  flex  py-2 my-2'>
                      <Link to={`/deal/${customer.Id}`}>
                        <img
                          alt='right arrow'
                          className='hover:bg-blue-300 rounded-3xl p-2'
                          src={RightArrow}
                        ></img>
                      </Link>
                    </div>
                  </div>
                  <span
                    className={`table-divider cols-span-24 h-1 mx-4 ${
                      pathname === 'dashboard' ? '' : ''
                    }`}
                  ></span>
                  {/* <span className='col-span-12 h-2 bg-blue-400 hover:bg-blue-400'></span> */}
                </Fragment>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyCustomersTable;
