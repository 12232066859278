import React, { useEffect, useState, Fragment } from 'react';
import ChartInfo from '../Popover/ChartInfo';
import { useGetUsersWazuhMutation } from '../../redux/features/user/usersApiSlice';
import Search from '../img/Search.svg';

const UsersList = () => {
  const [usersList, setUsersList] = useState([]);
  const [query, setQuery] = useState('');
  const [getWazuhUsers] = useGetUsersWazuhMutation();

  useEffect(() => {
    const getWazuhUsersFunction = async () => {
      const result = await getWazuhUsers().unwrap();
      setUsersList(result);
    };
    getWazuhUsersFunction();
  }, []);

  const search = (data) => {
    return data
      .map((item) => {
        const isNameMatch = item.name
          .toLowerCase()
          .includes(query.toLowerCase());
        const matchingUsers = item.users.filter(
          (user) =>
            user.email.toLowerCase().includes(query.toLowerCase()) ||
            user.role.toLowerCase().includes(query.toLowerCase()) ||
            user.username.toLowerCase().includes(query.toLowerCase())
        );

        if (isNameMatch) {
          // If the name matches, return the entire item
          return item;
        } else if (matchingUsers.length > 0) {
          // If there are matching users, return a new object with filtered users
          return {
            ...item,
            users: matchingUsers,
          };
        } else {
          // If there are no matches, return null or some other value to filter it out
          return null;
        }
      })
      .filter((item) => item !== null);
  };

  const searchData = search(usersList);

  // console.log(usersList, 'usersList');

  return (
    <div>
      <div>
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <h1 className='text-[22px] mr-1 font-semibold text-left'>
              Users List
            </h1>
            <ChartInfo
              text={
                'Shows all the currently registered users on the portal and their information.'
              }
            />
          </div>
          <div className='flex mr-20'>
            <span className='inline-flex relative left-9  items-center px-2 text-gray-900 bg-gray-[#DBE3EB] rounded-l-md  border-r-0 py-2'>
              <img alt='search' src={Search} />
            </span>
            <input
              type='text'
              id='website-admin'
              className='rounded-md pl-9 bg-gray-50 border  text-gray-900 focus:ring-mainblue focus:border-mainblue block flex-1 min-w-0 w-full text-sm border-gray-300'
              placeholder='Search'
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        </div>
        <span className='form-divider h-1 mr-0 lg:mr-6 2xl:mr-3 block mt-2 mb-7'></span>
      </div>
      <div className='rounded-lg bg-white p-2'>
        <div>
          <div className='grid grid-cols-24 mt-4 lg:mx-8 md:max-h-[calc(100vh-205px)] lg:max-h-[calc(100vh-270px)] xl:max-h-[calc(100vh-230px)] 2xl:max-h-[calc(100vh-300px)] 3xl:max-h-[650px] overflow-y-auto 2xl:mx-[55px]'>
            <div className=' bg-[#292929] text-white col-span-6 rounded-l-lg pl-4  text-sm  xl:text-lg font-semibold py-2 sticky top-0'>
              Name
            </div>
            <div className='bg-[#292929] text-white col-span-7 text-sm  xl:text-lg font-semibold py-2 sticky top-0'>
              Email
            </div>
            <div className='bg-[#292929] text-white col-span-3 text-sm  xl:text-lg font-semibold py-2 sticky top-0'>
              Role
            </div>
            <div className=' bg-[#292929] text-white text-sm  xl:text-lg col-span-7 font-semibold py-2 sticky top-0 z-[9]'>
              Partner
            </div>
            <div className='bg-[#292929] text-white rounded-r-lg col-span-1 text-sm  xl:text-lg font-semibold py-2 sticky top-0'></div>
            {searchData.map((partner) => (
              <Fragment key={partner.name}>
                {partner.users.map((user) => (
                  <Fragment key={user.username}>
                    <>
                      <div className='rowWrapper rowWrapperProspect hover:cursor-pointer'>
                        <div className='items-center text-sm xl:text-base hover:rounded-l-lg flex pl-4  col-span-6 py-2 my-2 pr-6 '>
                          <div className=' overflow-ellipsis max-w-[300px] truncate'>
                            {user.username}
                          </div>
                        </div>
                        <div className='col-span-7 text-sm xl:text-base -200 items-center flex  py-2 my-2 pr-6 '>
                          <div className='overflow-ellipsis  max-md:max-w-[100px] truncate'>
                            {user.email}
                          </div>
                        </div>
                        <div className='col-span-3 text-sm xl:text-base -200 items-center flex  py-2 my-2 pr-6 '>
                          {user.role.charAt(0).toUpperCase() +
                            user.role.slice(1)}
                        </div>
                        <div className='col-span-7 text-sm xl:text-base items-center flex  py-2 my-2 pr-6 '>
                          {partner.name}
                        </div>
                        <div className='items-center justify-center col-span-1 flex  py-2 my-2'></div>
                      </div>
                      <span className='table-divider cols-span-24 h-1 mx-4'></span>
                    </>
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </div>
        </div>

        {/* <div className='flex flex-col items-center h-[500px]  overflow-y-scroll'> 
          <div className='flex w-full justify-around bg-black text-white rounded-lg'>
            <div className='w-32'>name</div>
            <div className='w-32'>email</div>
            <div className='w-32'>partner</div>
          </div>
          {usersList.map((partner) => (
            <div className='w-full'>
               <div className='bg-blue-500 pl-2   text-white rounded-lg w-full'>
                <div className=''>{partner.name}</div>
              </div> 
              <div className=''>
                {partner.users.map((user) => (
                  <div className='flex flex-col'>
                    <div className='flex w-full justify-around'>
                      <div className='pl-2 w-32'>{user.username}</div>
                      <div className='pl-2 w-32 overflow-ellipsis max-w-[350px] truncate'>
                        {user.email}
                      </div>
                      <div>{partner.name} </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default UsersList;
