import React from 'react';
import { Link } from 'react-router-dom';

const AnalyticsButton = () => {
  return (
    <Link to='/wazuh-analytics'>
      <button
        className={`flex text-gray-800 hover:text-blue-500 items-center my-4`}
      >
        Sales Analytics
      </button>
    </Link>
  );
};

export default AnalyticsButton;
