import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import SideBarArrow from '../img/SideBarArrow.svg';
import { useRegisterUserMutation } from '../../redux/features/user/authApiSlice';
import * as Yup from 'yup';
import EmailIcon from '../img/EmailIcon.svg';
import WhiteCross from '../img/WhiteCross.svg';
import { useGetPartnersMutation } from '../../redux/features/user/partnersApiSlice';
import { useLocation } from 'react-router-dom';

export default function AddUserModal({ isOpen, closeModal, openUserModal }) {
  //   let [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [partnersList, setPartnersList] = useState([]);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [err, setErr] = useState(null);
  const [registerUser] = useRegisterUserMutation();
  const [getPartners] = useGetPartnersMutation();
  const location = useLocation();
  const path = location.pathname.split('/')[1];

  useEffect(() => {
    fetchPartners();
  }, [isOpen]);

  const fetchPartners = useCallback(async () => {
    const result = await getPartners(path).unwrap();
    setPartnersList(result);
  });

  const registerValidationSchema = Yup.object({
    username: Yup.string()
      .required('User is required')
      .max(30, 'User must be 30 characters or less.'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required')
      .max(70, 'Email name must be 70 characters or less.'),
    // password: Yup.string().required('Password is required!'),
  });

  const initialData = {
    username: '',
    email: '',
    role: 'user',
    partnerId: '',
  };

  const handleSubmit = async (formData, { resetForm }) => {
    try {
      await registerUser(formData).unwrap();
      setRegisterOpen(true);
      resetForm();
    } catch (error) {
      setErr(error.response.data);
    }
  };

  const closeRegisterModal = () => {
    setRegisterOpen(false);
  };

  const cancelButtonRef = useRef(null);

  return (
    <>
      <div></div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          initialFocus={cancelButtonRef}
          as='div'
          className='relative z-10'
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto font-manrope'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white px-4 py-4 text-left align-middle shadow-xl transition-all'>
                  <div className='flex flex-col w-auto'>
                    <Formik
                      validationSchema={registerValidationSchema}
                      initialValues={initialData}
                      onSubmit={handleSubmit}
                    >
                      {({ errors, touched }) => (
                        <Form className='flex flex-col gap-4 justify-center m-4'>
                          <div className='text-center flex-col flex items-center content-center '>
                            <h1 className='text-2xl pl-8 justify-between  font-semibold items-center bg-[#3D82F1] rounded-2xl w-full flex py-3 text-white'>
                              <p>
                                Register{' '}
                                <span className='wzh-remark'>user</span>
                              </p>
                              <img
                                onClick={closeModal}
                                className='mr-8 hover:cursor-pointer'
                                src={WhiteCross}
                              ></img>
                            </h1>
                          </div>
                          <div className='flex flex-col md:flex-row justify-between mx-8 md:gap-12'>
                            <div className='w-full'>
                              {errors.username && touched.username ? (
                                <p className='font-semibold mb-2 text-red-500'>
                                  {errors.username}
                                </p>
                              ) : (
                                <p className='font-semibold mb-2'>Username</p>
                              )}
                              <Field
                                className=' border border-[#B7C6D7] rounded-md w-full  px-2 py-2 text-black placeholder-[#B7C6D7]'
                                placeholder='Username'
                                type='text'
                                name='username'
                              ></Field>
                            </div>
                            <div className='w-full'>
                              {errors.email && touched.email ? (
                                <p className='font-semibold mb-2 text-red-500'>
                                  {errors.email}
                                </p>
                              ) : (
                                <p className='font-semibold mb-2 mt-4 md:mt-0'>
                                  Email
                                </p>
                              )}

                              <div className='flex'>
                                <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300'>
                                  <img alt='email icon' src={EmailIcon} />
                                </span>
                                <Field
                                  name='email'
                                  type='email'
                                  className='rounded-none rounded-r-lg bg-gray-50 border  text-gray-900 focus:ring-blue-500 focus:border-blue-500 w-full text-sm border-gray-300 p-2.5'
                                  placeholder='Email'
                                ></Field>
                              </div>
                            </div>
                          </div>
                          <div className='flex justify-between mx-8 md:gap-12 flex-col md:flex-row'>
                            <div className='flex flex-col w-full'>
                              <p className='font-semibold mb-2 mt-4'>Partner</p>
                              <Field
                                className='w-full border border-[#B7C6D7] rounded-md px-2 py-2 text-black placeholder-[#B7C6D7] styled-select'
                                as='select'
                                name='partnerId'
                              >
                                <option disabled selected value=''>
                                  -
                                </option>
                                {partnersList.map((partner) => (
                                  <option value={partner.id}>
                                    {partner.name}
                                  </option>
                                ))}
                              </Field>
                            </div>
                            <div className='flex flex-col w-full'>
                              <p className='font-semibold mb-2 mt-4'>Role</p>
                              <Field
                                className='border border-[#B7C6D7] rounded-md px-2 py-2 text-black placeholder-[#B7C6D7] styled-select w-full'
                                as='select'
                                name='role'
                              >
                                <option selected value='user'>
                                  User
                                </option>
                                <option value='admin'>Admin</option>
                                {/* <option value='wazuh'>Wazuh</option> */}
                              </Field>
                            </div>
                          </div>
                          {/* {errors.password && touched.password ? (
                    <p className='font-semibold mb-2 text-red-500'>
                      {errors.password}
                    </p>
                  ) : (
                    <p className='font-semibold mb-2'>Password</p>
                  )} */}

                          {/* <div className='flex'>
                    <span className='bg-[#FBFCFD] inline-flex items-center px-3 text-sm text-gray-900 bg-gray-[#DBE3EB] rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600'>
                      <img src={Lock} />
                    </span>
                    <Field
                      name='password'
                      type='password'
                      id='website-admin'
                      className='rounded-none  bg-gray-50 border border-l-0  text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      placeholder='Password'
                    ></Field>
                    <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-[#DBE3EB] rounded-r-md border border-l-0 border-gray-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600'>
                      <img src={CrossEye} />
                    </span>
                  </div> */}

                          {err && <p className='text-red-500'>{err}</p>}

                          {/* <Modal fetchPartners={fetchPartners} /> */}
                          <div className='flex justify-between mt-2 px-8 gap-12 bg-[#F8F8F8] rounded-xl'>
                            <button
                              type='submit'
                              className='btn bg-yellow-gradient col-span-2 rounded-lg my-2 py-[10px] text-center items-center w-full'
                            >
                              Register
                            </button>
                            <button
                              type='button'
                              className='bg-[#FBFCFD] border border-[#3D82F1] hover:text-blue-700 hover:border-blue-800 text-[#3D82F1] col-span-2 rounded-lg my-2 py-[10px] text-center items-center w-full'
                              onClick={closeModal}
                              ref={cancelButtonRef}
                            >
                              Cancel
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* User registered confirmation modal */}
      <Transition.Root show={registerOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeRegisterModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            {/* Overlay Background */}
            <div className='fixed inset-0 bg-black bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto font-manrope'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <p className='text-lg text-gray-500'>
                    User registered succesfully!
                  </p>

                  {/* <div className='text-[#3D82F1] font-semibold text-sm'>
                    Once a deal is closed it can't be opened again
                  </div> */}
                  <div className='mt-6 justify-end flex'>
                    <button
                      type='button'
                      className='inline-flex ml-5 justify-center rounded-md border border-transparent bg-[#3D82F1] px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      onClick={() => closeRegisterModal()}
                    >
                      Accept!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
