import * as React from 'react';
const Export = (props) => (
  <svg
    width='41'
    height='41'
    viewBox='0 0 41 41'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='20.5' cy='20.5' r='20.5' fill={props.bgfill} />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.2745 22.3982C26.5739 23.773 27.4716 25.6371 27.7304 27.7397C27.8114 28.408 27.2793 28.9997 26.6054 28.9997H13.2573C12.5834 28.9997 12.0513 28.408 12.1334 27.7397C12.391 25.6371 13.2888 23.773 14.5881 22.3982C14.866 22.6547 15.1563 22.8955 15.4679 23.1104C14.3001 24.313 13.4823 25.9791 13.2494 27.877L26.6054 27.8747C26.3759 25.978 25.5603 24.313 24.3936 23.1104C24.7064 22.8955 24.9966 22.6547 25.2745 22.3982ZM19.9312 11.0003C22.0687 11.0003 23.3062 13.2503 24.8576 13.9624C24.3423 14.5553 23.5908 14.9378 22.7437 14.9378C22.4085 14.9378 22.0923 14.8692 21.7942 14.7612C21.0202 15.7197 19.8513 16.3441 18.525 16.3441C16.9556 16.3441 14.3062 16.6253 14.8743 14.2032C16.5562 13.2503 17.6958 11.0003 19.9312 11.0003Z'
      fill={props.mainfill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.9316 12.125C22.4134 12.125 24.4316 14.1432 24.4316 16.625C24.4316 19.1067 22.4134 21.125 19.9316 21.125C17.4499 21.125 15.4316 19.1067 15.4316 16.625C15.4316 14.1432 17.4499 12.125 19.9316 12.125ZM19.9316 22.25C23.0378 22.25 25.5566 19.7311 25.5566 16.625C25.5566 13.5189 23.0378 11 19.9316 11C16.8255 11 14.3066 13.5189 14.3066 16.625C14.3066 19.7311 16.8255 22.25 19.9316 22.25Z'
      fill={props.mainfill}
    />
  </svg>
);
export default Export;
