import React from 'react';
import DashboardNavbar from '../components/DashboardNavbar';
import NotFoundImg from '../components/img/NotFound.webp';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='h-screen flex flex-col'>
      <DashboardNavbar />
      <div className=' flex-[1]'>
        <div className='flex w-full h-full justify-center items-center'>
          <div className='flex flex-col items-center'>
            <img src={NotFoundImg}></img>
            <div className=' text-3xl'>Oops! something went wrong</div>
            <div className='font-manrope mt-2 text-center'>
              The page you requested could not be found. We are working on it.
            </div>
            <Link
              to='/dashboard'
              className='bg-[#FEDD0B] hover:bg-[#FCE765] py-2 px-[60px] rounded-lg mt-6'
            >
              Go back home
            </Link>
          </div>
        </div>
      </div>
      <div className='h-16 bg-black text-white  flex justify-around items-center'>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://wazuh.com/terms-of-use/'
          className='hover:text-blue-500 hover:underline cursor-pointer'
        >
          Legal Resources
        </a>
      </div>
    </div>
  );
};

export default NotFound;
