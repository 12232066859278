import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import SideBarArrow from '../img/SideBarArrow.svg';
import WhiteCross from '../img/WhiteCross.svg';
import {
  useGetPartnersMutation,
  useCreatePartnerMutation,
} from '../../redux/features/user/partnersApiSlice';
import { useLocation } from 'react-router-dom';

export default function AddPartnerModal() {
  let [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [partnersList, setPartnersList] = useState([]);
  const [createPartner] = useCreatePartnerMutation();
  const [getPartners] = useGetPartnersMutation();
  const location = useLocation();
  const path = location.pathname.split('/')[1];
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = useCallback(async () => {
    const result = await getPartners(path).unwrap();
    setPartnersList(result);
  });

  const modalInitialValues = {
    name: '',
    salesforceId: '',
  };

  const handleSubmitModal = async (formData) => {
    await createPartner({
      body: formData,
    });
    await fetchPartners();
    setConfirmationOpen(true);
  };

  const cancelButtonRef = useRef(null);

  const closeConfirmationModal = () => {
    setConfirmationOpen(false);
    fetchPartners();
  };

  return (
    <>
      <div className='flex mt-4'>
        <button
          type='button'
          className={`flex text-gray-800 hover:text-blue-500 items-center ${
            isOpen && 'text-blue-500 font-bold'
          }`}
          onClick={() => setModalOpen(true)}
        >
          <img
            alt='blue-arrow'
            className={`relative right-2 w-1 ${!isOpen && 'hidden'}`}
            src={SideBarArrow}
          />
          Register partner
        </button>
      </div>

      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={setModalOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            {/* Overlay Background */}
            <div className='fixed inset-0 bg-black bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto font-manrope'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all px-4 py-4 w-full max-w-2xl'>
                  <Formik
                    initialValues={modalInitialValues}
                    onSubmit={handleSubmitModal}
                  >
                    {(formProps) => (
                      <Form className='flex flex-col gap-4 justify-center m-4'>
                        <div className='bg-white '>
                          <div className=''>
                            <div className='sm:mt-0 sm:text-left'>
                              <Dialog.Title
                                as='h3'
                               className='text-2xl pl-8 justify-between  font-semibold items-center bg-[#3D82F1] rounded-2xl w-full flex py-3 text-white'
                              >
                                <h1>Register <span className='wzh-remark'>new Partner</span></h1>
                                <img onClick={()=>setModalOpen(false)} className='mr-8 hover:cursor-pointer' src={WhiteCross}></img>
                              </Dialog.Title>
                              <div className='mt-8'>
                                <div className='md:flex-row flex flex-col justify-between mx-8 md:gap-12'>
                                  <div className='w-full'>
                                    <p className='font-semibold  text-sm mb-2'>
                                      Name
                                    </p>
                                    <Field
                                      className=' border w-full border-[#B7C6D7] rounded-md px-2 py-2 text-black placeholder-[#B7C6D7]'
                                      placeholder='Name'
                                      type='text'
                                      name='name'
                                    ></Field>
                                  </div>
                                  <div className='w-full mt-4 md:mt-0'>
                                            <p className='font-semibold  text-sm mb-2'>
                                                    Partner Salesforce Id
                                                  </p>
                                                  <Field
                                                    className='w-full border border-[#B7C6D7] rounded-md px-2 py-2 text-black placeholder-[#B7C6D7]'
                                                    placeholder='Salesforce Id'
                                                    type='text'
                                                    name='salesforceId'
                                                  ></Field>
                                                </div>
                                </div>
                               
                              </div>
                              <div className='flex justify-between mt-8 px-8 gap-12 bg-[#F8F8F8] rounded-xl'>
                          <button
                           onClick={()=>setModalOpen(false)}
                              type='submit'
                              className='btn bg-yellow-gradient col-span-2 rounded-lg my-2 py-[10px] text-center items-center w-full'
                            >
                              Register
                            </button>
                            <button
                              type='button'
                              className='bg-[#FBFCFD] border border-[#3D82F1] hover:text-blue-700 hover:border-blue-800 text-[#3D82F1] col-span-2 rounded-lg my-2 py-[10px] text-center items-center w-full'
                              onClick={()=>setModalOpen(false)}
                              ref={cancelButtonRef}
                            >
                              Cancel
                            </button>
                          
                          </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={confirmationOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={closeConfirmationModal}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            {/* Overlay Background */}
            <div className='fixed inset-0 bg-black bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto font-manrope'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <p className='text-lg text-gray-500'>
                    Partner registered succesfully!
                  </p>

                  {/* <div className='text-[#3D82F1] font-semibold text-sm'>
                    Once a deal is closed it can't be opened again
                  </div> */}
                  <div className='mt-6 justify-end flex'>
                    <button
                      type='button'
                      className='inline-flex ml-5 justify-center rounded-md border border-transparent bg-[#3D82F1] px-4 py-2 font-medium text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      onClick={() => closeConfirmationModal()}
                    >
                      Accept!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
