import ArrowToRight from '../img/ArrowToRight.svg';
import BenefitsTable from './BenefitsTable';
import { useState } from 'react';

export default function PlanBenefitsModal({ plan, discount }) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      {plan ? (
        <>
          <div
            onClick={openModal}
            className=' text-white hover:underline mt-1 hover:cursor-pointer flex'
          >
            You have {plan} plan
            <img className='ml-2' src={ArrowToRight}></img>
          </div>
          <BenefitsTable
            discount={discount}
            isOpen={isOpen}
            closeModal={closeModal}
            openModal={openModal}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
