import { Popover, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import Notification from '../../components/img/Notification.svg';
import RedNotification from '../../components/img/RedNotification.svg';

const Test = ({ type }) => {
  const [isShowing, setIsShowing] = useState(false);
  return (
    <div className='relative xl:lef-[30px]  2xl:left-0 top-[2px] ml-2'>
      <Popover className='relative'>
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md  text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <img
                className=''
                alt='notification icon'
                onMouseEnter={() => setIsShowing(true)}
                onMouseLeave={() => setIsShowing(false)}
                src={`${type === 'expiring' ? Notification : RedNotification}`}
              ></img>
            </Popover.Button>
            <Transition
              show={isShowing}
              onMouseEnter={() => setIsShowing(true)}
              onMouseLeave={() => setIsShowing(false)}
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute -right-[90%] bottom-[40px]  z-10 mt-3 w-screen max-w-sm trans transform px-4 sm:px-0 lg:max-w-[280px]'>
                <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                  {/* <div className='relative grid gap-8 bg-black p-7 lg:grid-cols-2'>
                    {solutions.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className='-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'
                      >
                        <div className='flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12'>
                          <item.icon aria-hidden='true' />
                        </div>
                        <div className='ml-4'>
                          <p className='text-sm font-medium text-gray-900'>
                            {item.name}
                          </p>
                          <p className='text-sm text-gray-500'>
                            {item.description}
                          </p>
                        </div>
                      </a>
                    ))}
                  </div> */}
                  <div className=' bg-[#404040]'>
                    <span className='triangle2'></span>
                    <a
                      href='##'
                      className='flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out  focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'
                    >
                      <span className='flex items-center'>
                        <span className='text-sm font-medium text-white'>
                          This plan
                          {type === 'expiring'
                            ? ' is about to expire'
                            : ' has expired'}
                          , please contact to &nbsp;
                          <a
                            href='mailto:partners@wazuh.com'
                            className='text-blue-500 hover:underline'
                          >
                            partners@wazuh.com
                          </a>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default Test;
