import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import Submitted from '../img/Submitted.svg';
import { Link } from 'react-router-dom';

const HelpRequestModal = ({ isOpen, openFunction, closeFunction }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeFunction}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-75' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                {/* <Dialog.Title
        as='h3'
        className='text-lg font-medium leading-6 text-gray-900'
      >
        Delete User
      </Dialog.Title> */}

                <div className='mt-4 flex flex-col justify-center items-center font-manrope'>
                  <img
                    alt='submitted message'
                    className=' w-72'
                    src={Submitted}
                  />
                  <div className=' text-center mt-2'>
                    <p className='text-3xl font-semibold'>
                      Your submission has been sent!
                    </p>
                    <p className='text-lg font-semibold my-4'>
                      We'll contact you shortly
                    </p>
                  </div>
                  <Link to='/dashboard'>
                    <button
                      type='button'
                      className='inline-flex mt-4 mb-4 justify-center rounded-md border border-transparent bg-[#3D82F1] px-8 py-2 text-base font-normal text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2'
                    >
                      Go back home
                    </button>
                  </Link>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default HelpRequestModal;
