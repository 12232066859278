import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import useAuth from '../../../hooks/useAuth';
import EditUserModal from '../../Modals/EditUserModal';
import Edit from '../../img/Edit.svg';

const ChangeUserDetails = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [infoErr, setInfoErr] = useState(null);
  const [submitModal, setSubmitModal] = useState(false);

  const userinfo = useAuth();
  const id = userinfo.id;

  function closeModal() {
    setSubmitModal(false);
  }

  function changeIsEdit() {
    setIsEdit(!isEdit);
  }

  const accountInitialValues = {
    username: userinfo.username,
    email: userinfo.email,
  };

  const userInfoValidationSchema = Yup.object({
    username: Yup.string().required('Username is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('email is required'),
  });

  const handleSubmitAccount = async (formData) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users/editaccountsettings/${id}`,
        formData
      );
      setTimeout(() => window.location.reload(), 5000);
      setInfoErr(null);
      setSubmitModal(true);
    } catch (error) {
      setInfoErr(error.response.data);
      setTimeout(() => setInfoErr(null), 5000);
    }
  };
  return (
    <div className='w-full mb-8'>
      <EditUserModal submitModal={submitModal} closeModal={closeModal} />
      <Formik
        validationSchema={userInfoValidationSchema}
        initialValues={accountInitialValues}
        onSubmit={handleSubmitAccount}
      >
        {({ errors, touched }) => (
          <Form>
            <h3 className='text-[22px] font-semibold leading-6 text-gray-900 mt-4 mb-4'>
              Account Settings
            </h3>
            <span className='form-divider h-1 mr-0 lg:mr-11 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
            {isEdit ? (
              <div className='flex flex-col sm:flex-row space-y-7 sm:space-y-0 sm:space-x-7 mt-6'>
                <div className='flex flex-col'>
                  <div className=''>
                    {errors.username && touched.username ? (
                      <p className='font-semibold mb-2 text-red-500'>
                        {errors.username}
                      </p>
                    ) : (
                      <h1 className='font-semibold'>Username</h1>
                    )}

                    <Field
                      className='border border-[#B7C6D7] rounded-md mt-3  w-full sm:w-64 px-2 py-2 text-black placeholder-[#B7C6D7]'
                      type='text'
                      name='username'
                      placeholder='username'
                    ></Field>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div>
                    {errors.email && touched.email ? (
                      <p className='font-semibold mb-2 text-red-500'>
                        {errors.email}
                      </p>
                    ) : (
                      <h1 className='font-semibold'>Email</h1>
                    )}
                    <Field
                      className=' border border-[#B7C6D7] rounded-md  mt-3  w-full sm:w-64 px-2 py-2 text-black placeholder-[#B7C6D7]'
                      type='text'
                      name='email'
                      placeholder='email'
                    ></Field>
                  </div>
                </div>
                <div className='flex justify-center sm:justify-start'>
                  <button
                    className='mt-4 sm:mt-9 text-white bg-[#3D82F1] hover:bg-blue-600 py-2 px-4 rounded-lg'
                    type='submit'
                  >
                    Save
                  </button>
                  <button
                    onClick={changeIsEdit}
                    className='mt-9 text-blue-500 hover:underline ml-6'
                    type='submit'
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className='flex flex-col sm:flex-row space-y-7 sm:space-y-0 sm:space-x-7 mt-6'>
                <div className='flex flex-col'>
                  <div className=''>
                    <h1 className='font-semibold'>Username</h1>

                    <div
                      className='border border-[#B7C6D7] rounded-md mt-3  w-64 xl:w-48 2xl:w-64 truncate px-2 py-2 text-black placeholder-[#B7C6D7]'
                      type='text'
                      name='username'
                      placeholder='username'
                    >
                      {userinfo.username}
                    </div>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div>
                    <h1 className='font-semibold'>Email</h1>
                    <div className=' border border-[#B7C6D7] rounded-md  mt-3  w-64 xl:w-48 2xl:w-64 truncate px-2 py-2 text-black placeholder-[#B7C6D7]'>
                      {userinfo.email}
                    </div>
                  </div>
                </div>
                {/* <div className='flex gap-0 items-center mt-2 sm:mt-9 justify-center'>
                  <img
                    onClick={changeIsEdit}
                    src={Edit}
                    className='hover:cursor-pointer'
                  />
                </div> */}
              </div>
            )}

            {/* {infoMess && <p className='text-green-500'>{infoMess}</p>} */}
            {infoErr && <p className='text-red-500'>{infoErr}</p>}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChangeUserDetails;
