import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import SideBarArrow from './img/SideBarArrow.svg';
import {
  useGetPartnersMutation,
  useCreatePartnerMutation,
  useGetPartnerByIdMutation,
  useUpdateUsersPartnerMutation,
} from '../redux/features/user/partnersApiSlice';
import { useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

export default function AnalyticsBreadcrumb({ partnerName }) {
  let [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [partnersList, setPartnersList] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [createPartner] = useCreatePartnerMutation();
  const [getPartners] = useGetPartnersMutation();
  const [getByIdPartner] = useGetPartnerByIdMutation();
  const [updateUsersPartner] = useUpdateUsersPartnerMutation();
  const location = useLocation();
  const path = location.pathname.split('/')[1];
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const userinfo = useAuth();

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = useCallback(async () => {
    const result = await getPartners(path).unwrap();
    setPartnersList(result);
  });

  useEffect(() => {
    const getPartnerData = async () => {
      const result = await getByIdPartner(userinfo.partnerId).unwrap();
      setPartnerData(result);
    };
    getPartnerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalInitialValues = {
    userId: userinfo?.id,
    partnerId: partnerData[0]?.id,
  };

  const handleSubmitModal = async (formData) => {
    // if (formData.pic !== '') {
    //   const upload = async () => {
    //     try {
    //       let data = new FormData();
    //       data.append('pic', formData.pic);
    //       const res = await axios.post(
    //         `${process.env.REACT_APP_API_URL}/api/${
    //           process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'img' : 's3img'
    //         }/upload`,
    //         data
    //       );
    //       // console.log('res.data', res.data);
    //       await fetchPartners();
    //       return res.data;
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   };
    //   let imgUrl = '';
    //   if (formData.pic) imgUrl = await upload();
    //   try {
    //     await createPartner({
    //       id: path,
    //       body: { name: formData.name, pic: imgUrl },
    //     }).unwrap();
    //     await fetchPartners();
    //     setConfirmationOpen(true);
    //     //Open confirmation modal
    //     // window.location.reload();
    //   } catch (error) {
    //     console.log(error);
    //   }
    // } else {
    await updateUsersPartner(formData);
    window.location.reload(true);
    // console.log(formData);
    // }
  };

  const cancelButtonRef = useRef(null);

  const closeConfirmationModal = () => {
    setConfirmationOpen(false);
    fetchPartners();
  };

  return (
    <>
      <div className='flex items-center ml-3'>
        <select
          value={partnerData[0]?.id}
          className='styled-select w-36 sm:w-72 text-blue-500 border pl-2 rounded-md'
          onChange={(e) =>
            handleSubmitModal({
              userId: userinfo?.id,
              partnerId: e.target.value,
            })
          }
        >
          {partnersList.map((partner) => (
            <option value={partner.id}>{partner.name}</option>
          ))}
        </select>
        {/* <button
          type='button'
          className={`flex hover:underline text-blue-500 hover:cursor-pointer hover:text-blue-500 items-center ${
            isOpen && 'text-blue-500 font-bold'
          }`}
          onClick={() => setModalOpen(true)}
        >
          {partnerName !== null && partnerName[0].name}
        </button> */}
      </div>

      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={setModalOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            {/* Overlay Background */}
            <div className='fixed inset-0 bg-black bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl'>
                  <Formik
                    enableReinitialize
                    initialValues={modalInitialValues}
                    onSubmit={handleSubmitModal}
                  >
                    {(formProps) => (
                      <Form>
                        <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                          <div className='sm:flex sm:items-start'>
                            <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                              <Dialog.Title
                                as='h3'
                                className='text-lg font-medium leading-6 text-gray-900'
                              >
                                Change Partner view
                              </Dialog.Title>
                              <div className='mt-2'>
                                <div className='grid grid-cols-3 gap-4'>
                                  <div className='flex flex-col'>
                                    <p className='font-semibold mb-2 mt-4'>
                                      Partner
                                    </p>
                                    <Field
                                      className=' border border-[#B7C6D7] rounded-md  w-64 px-2 py-2 text-black placeholder-[#B7C6D7] styled-select'
                                      as='select'
                                      name='partnerId'
                                    >
                                      {partnersList.map((partner) => (
                                        <option value={partner.id}>
                                          {partner.name}
                                        </option>
                                      ))}
                                    </Field>
                                  </div>
                                  {/* <div>
                                                <p className='font-semibold  text-sm mb-2'>
                                                  Picture
                                                </p>
                                                <Field
                                                  className='border w- border-[#B7C6D7] rounded-md w-44 px-2 py-2 text-black placeholder-[#B7C6D7]'
                                                  type='file'
                                                  name='pic'
                                                  value={undefined}
                                                  onChange={(event) =>
                                                    formProps.setFieldValue(
                                                      'pic',
                                                      event.target.files[0]
                                                    )
                                                  }
                                                ></Field>
                                              </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                          <button
                            type='submit'
                            className='inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm'
                            onClick={() => setModalOpen(false)}
                          >
                            Change
                          </button>
                          <button
                            type='button'
                            className='mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                            onClick={() => setModalOpen(false)}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={confirmationOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={closeConfirmationModal}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            {/* Overlay Background */}
            <div className='fixed inset-0 bg-black bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='w-full font-manrope max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <p className='text-lg text-gray-500'>
                    View changed succesfully!
                  </p>

                  {/* <div className='text-[#3D82F1] font-semibold text-sm'>
                    Once a deal is closed it can't be opened again
                  </div> */}
                  <div className='mt-6 justify-end flex'>
                    <button
                      type='button'
                      className='inline-flex ml-5 justify-center rounded-md border border-transparent bg-[#3D82F1] px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      onClick={() => closeConfirmationModal()}
                    >
                      Accept!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
