import React, { useState, useEffect, useRef } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useRefreshMutation } from './authApiSlice';
import usePersist from '../../../hooks/usePersist';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from './authSlice';

const PersistLogin = () => {
  const [persist] = usePersist();
  const token = useSelector(selectCurrentToken);
  const effectRan = useRef(false);
  const [trueSuccess, setTrueSuccess] = useState(false);
  const [refresh, { isUninitialized, isLoading, isSuccess, isError, error }] =
    useRefreshMutation();

  const navigate = useNavigate();

  const redirectHome = () => {
    navigate('/');
  };

  useEffect(
    () => {
      const verifyRefreshToken = async () => {
        // console.log('verifying refresh token');
        try {
          //const response =
          await refresh();
          //const { accessToken } = response.data
          setTrueSuccess(true);
        } catch (err) {
          console.error(err);
        }
      };

      if (!token && persist) verifyRefreshToken();
    },
    // eslint-disable-next-line
    []
  );

  let content;
  if (!persist) {
    // persist: no
    // console.log('no persist');
    content = <Outlet />;
  } else if (isLoading) {
    //persist: yes, token: no
    // console.log('loading');
    content = <p>Loading...</p>;
  } else if (isError) {
    redirectHome();
    //persist: yes, token: no
    // console.log('error', error);
    // content = (
    //   <p className=''>
    //     {`${error?.data} - `}
    //     <Link to='/'>Please login again</Link>.
    //   </p>
    // );
  } else if (isSuccess && trueSuccess) {
    //persist: yes, token: yes
    // console.log('success');
    content = <Outlet />;
  } else if (token && isUninitialized) {
    //persist: yes, token: yes
    // console.log('token and uninit');
    // console.log(isUninitialized);
    content = <Outlet />;
  }

  return content;
};

export default PersistLogin;
