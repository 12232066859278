import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Lock from '../../img/Lock.svg';
import eye from '../../img/eye.svg';
import eyeclosed from '../../img/eyeClosed.svg';
import UpdatePasswordModal from '../../Modals/UpdatePasswordModal';
import PasswordValid from '../../img/PasswordValid.svg';
import PasswordInvalid from '../../img/PasswordInvalid.svg';
import useAuth from '../../../hooks/useAuth';
import { Popover, Transition } from '@headlessui/react';

const ChangePassword = () => {
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [submitPasswordModal, setSubmitPasswordModal] = useState(false);
  const [seePassword, setSeePassword] = useState(false);
  const [seeNewPassword, setSeeNewPassword] = useState(false);
  const [seeConfirmPassword, setSeeConfirmPassword] = useState(false);
  const [passwordErr, setPasswordErr] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] =
    useState(false);
  const userinfo = useAuth();

  const id = userinfo.id;

  const noCopyPaste = (e) => {
    e.preventDefault();
  };

  function closePasswordModal() {
    setSubmitPasswordModal(false);
  }

  const passwordInitialValues = {
    password: '',
    newpassword: '',
    confirmnewpassword: '',
  };

  const password_regex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%.]).{8,}$/;

  const passwordValidationSchema = Yup.object({
    password: Yup.string().required('Password is required!'),
    newpassword: Yup.string()
      .required('New password is required!')
      .matches(password_regex, 'Weak password.'),
    confirmnewpassword: Yup.string().required(
      'Confirm New password is required!'
    ),
  });

  const handleUpdatePassword = async (formData, { resetForm }) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users/editaccountpassword/${id}`,
        formData
      );
      setPasswordErr(null);
      resetForm();
      setSubmitPasswordModal(true);
    } catch (error) {
      setPasswordErr(error.response.data);
      setTimeout(() => setPasswordErr(null), 5000);
    }
  };
  return (
    <div className='mt-4 flex mb-8'>
      <UpdatePasswordModal
        closePasswordModal={closePasswordModal}
        submitPasswordModal={submitPasswordModal}
      />

      <Formik
        validationSchema={passwordValidationSchema}
        initialValues={passwordInitialValues}
        onSubmit={handleUpdatePassword}
      >
        {({ errors, touched, isValid, dirty, values }) => (
          <Form className='flex flex-col w-full'>
            <h3 className='text-[22px] font-semibold leading-6 text-gray-900 mb-2'>
              Password
            </h3>
            <span className='form-divider h-1 mr-0 lg:mr-11 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
            {isChangingPassword ? (
              <div className='mt-3 mb-4 grid grid-cols-2 gap-5'>
                <div className='flex flex-col'>
                  <div>
                    {errors.password && touched.password ? (
                      <p className='font-semibold mb-2 text-red-500'>
                        {errors.password}
                      </p>
                    ) : (
                      <h1 className='font-semibold'>Current Password</h1>
                    )}
                    <div className='flex mt-3'>
                      <span
                        className={`${
                          isPasswordFocused
                            ? 'border-[#3D82F1]'
                            : 'border-gray-300'
                        } bg-[#FBFCFD] inline-flex items-center px-3 text-sm text-gray-900 rounded-l-md border border-r-0`}
                      >
                        <img alt='lock' src={Lock} />
                      </span>
                      <Field
                        onFocus={() => setIsPasswordFocused(true)}
                        onBlur={() => setIsPasswordFocused(false)}
                        name='password'
                        type={seePassword ? 'text' : 'password'}
                        className={`${
                          isPasswordFocused && 'border-y-[#3D82F1]'
                        } rounded-none  bg-gray-50 border border-l-0  text-gray-900 focus:ring-blue-500 block flex-1 min-w-0 w-[200px] 2xl:w-[225px] text-sm border-gray-300 p-2.5`}
                        placeholder='Password'
                      ></Field>
                      <span
                        onClick={() => setSeePassword(!seePassword)}
                        className={`${
                          isPasswordFocused && 'border-[#3D82F1]'
                        } hover:cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-[#DBE3EB] rounded-r-md border border-l-0 border-gray-300`}
                      >
                        {seePassword ? (
                          <img alt='open eye' src={eye} />
                        ) : (
                          <img alt='closed eye' src={eyeclosed} />
                        )}
                      </span>
                    </div>
                  </div>
                  {passwordErr && (
                    <p className='text-red-500 mt-4'>{passwordErr}</p>
                  )}
                </div>
                <div className='flex flex-col'>
                  <div>
                    {errors.newpassword && touched.newpassword ? (
                      <p className='font-semibold mb-2 text-red-500'>
                        {errors.newpassword}
                      </p>
                    ) : (
                      <h1 className='font-semibold'>New Password</h1>
                    )}
                    <div className='flex mt-3'>
                      <span
                        className={`${
                          isPopoverOpen ? 'border-[#3D82F1]' : 'border-gray-300'
                        } bg-[#FBFCFD] inline-flex items-center px-3 text-sm text-gray-900 rounded-l-md border border-r-0`}
                      >
                        <img alt='lock' src={Lock} />
                      </span>
                      <Field
                        onFocus={() => setIsPopoverOpen(true)}
                        onBlur={() => setIsPopoverOpen(false)}
                        name='newpassword'
                        type={seeNewPassword ? 'text' : 'password'}
                        className={`${
                          isPopoverOpen && 'border-y-[#3D82F1]'
                        } rounded-none  bg-gray-50 border border-l-0  text-gray-900 focus:ring-blue-500 block flex-1 min-w-0 w-[200px] 2xl:w-[225px] text-sm border-gray-300 p-2.5`}
                        placeholder='New Password'
                      ></Field>
                      <span
                        onClick={() => setSeeNewPassword(!seeNewPassword)}
                        className={`${
                          isPopoverOpen && 'border-[#3D82F1]'
                        } hover:cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-[#DBE3EB] rounded-r-md border border-l-0 border-gray-300`}
                      >
                        {seeNewPassword ? (
                          <img alt='open eye' src={eye} />
                        ) : (
                          <img alt='closed eye' src={eyeclosed} />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className=''>
                    <div className='flex items-center'>
                      {/* {errors.newpassword && touched.newpassword ? (
                      <PasswordErrorPopOver />
                    ) : (
                      <PasswordPopOver />
                    )} */}
                      <div className='relative'>
                        <Popover className='relative'>
                          {({ open }) => (
                            <>
                              <Popover.Button
                                className={`
                  ${open ? '' : 'text-opacity-90'}
                  group items-center hidden rounded-md text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                              >
                                {/* <img alt='notification icon' src={BlueNotification}></img> */}
                              </Popover.Button>
                              <Transition
                                show={isPopoverOpen}
                                as={Fragment}
                                enter='transition ease-out duration-200'
                                enterFrom='opacity-0 translate-y-1'
                                enterTo='opacity-100 translate-y-0'
                                leave='transition ease-in duration-150'
                                leaveFrom='opacity-100 translate-y-0'
                                leaveTo='opacity-0 translate-y-1'
                              >
                                <Popover.Panel className='absolute -left-8 md:left-0 sm:-left-1 z-10 -top-[245px]  w-screen max-w-sm transform px-4 sm:px-0 lg:max-w-[300px]'>
                                  <div className='block h-5 w-5 border-inherit absolute -top-2 triangle-password bg-[#404040]'></div>
                                  <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                                    <div className='bg-[#404040] py-2 px-2'>
                                      <a
                                        href='##'
                                        className='flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'
                                      >
                                        <span className='block text-sm text-white'>
                                          Your password must have the following:
                                          <br />
                                          <div className='flex'>
                                            {values.newpassword.length >= 8 ? (
                                              <img
                                                className='mr-1'
                                                src={PasswordValid}
                                              ></img>
                                            ) : (
                                              <img
                                                className='mr-1'
                                                src={PasswordInvalid}
                                              ></img>
                                            )}
                                            Minimum 8 characters in length.
                                          </div>
                                          <div className='flex'>
                                            {/^(?=.*[A-Z])/.test(
                                              values.newpassword
                                            ) ? (
                                              <img
                                                className='mr-1'
                                                src={PasswordValid}
                                              ></img>
                                            ) : (
                                              <img
                                                className='mr-1'
                                                src={PasswordInvalid}
                                              ></img>
                                            )}
                                            At least 1 uppercase letter.
                                          </div>
                                          <div className='flex'>
                                            {/^(?=.*[a-z])/.test(
                                              values.newpassword
                                            ) ? (
                                              <img
                                                className='mr-1'
                                                src={PasswordValid}
                                              ></img>
                                            ) : (
                                              <img
                                                className='mr-1'
                                                src={PasswordInvalid}
                                              ></img>
                                            )}
                                            At least 1 lowercase letter.
                                          </div>
                                          <div className='flex'>
                                            {/^(?=.*[0-9])/.test(
                                              values.newpassword
                                            ) ? (
                                              <img
                                                className='mr-1'
                                                src={PasswordValid}
                                              ></img>
                                            ) : (
                                              <img
                                                className='mr-1'
                                                src={PasswordInvalid}
                                              ></img>
                                            )}
                                            At least 1 number.
                                          </div>
                                          <div className='flex'>
                                            {/^(?=.*?[#!@$%.])/.test(
                                              values.newpassword
                                            ) ? (
                                              <img
                                                className='mr-1'
                                                src={PasswordValid}
                                              ></img>
                                            ) : (
                                              <img
                                                className='mr-1'
                                                src={PasswordInvalid}
                                              ></img>
                                            )}
                                            At least 1 special character
                                            (#!@$%.).
                                          </div>
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </>
                          )}
                        </Popover>
                      </div>
                      {/* <PasswordPopOver
                      password={values.newpassword}
                      isOpen={isPopoverOpen}
                    /> */}
                      {/* <p className='mb-1 ml-1'>Password must be 8+ characters</p> */}
                    </div>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div className=''>
                    {errors.confirmnewpassword && touched.confirmnewpassword ? (
                      <p className='font-semibold mb-2 text-red-500'>
                        {errors.confirmnewpassword}
                      </p>
                    ) : (
                      <h1 className='font-semibold'>Confirm new password</h1>
                    )}
                    <div className='flex mt-3'>
                      <span
                        className={`${
                          isConfirmPasswordFocused
                            ? 'border-[#3D82F1]'
                            : 'border-gray-300'
                        } bg-[#FBFCFD] inline-flex items-center px-3 text-sm text-gray-900 rounded-l-md border border-r-0`}
                      >
                        <img alt='lock' src={Lock} />
                      </span>
                      <Field
                        onFocus={() => setIsConfirmPasswordFocused(true)}
                        onBlur={() => setIsConfirmPasswordFocused(false)}
                        onCut={noCopyPaste}
                        onCopy={noCopyPaste}
                        onPaste={noCopyPaste}
                        name='confirmnewpassword'
                        type={seeConfirmPassword ? 'text' : 'password'}
                        className={`${
                          isConfirmPasswordFocused && 'border-y-[#3D82F1]'
                        } rounded-none  bg-gray-50 border border-l-0  text-gray-900 focus:ring-blue-500 block flex-1 min-w-0 w-[200px] 2xl:w-[225px] text-sm border-gray-300 p-2.5`}
                        placeholder='Confirm New Password'
                      ></Field>
                      <span
                        onClick={() =>
                          setSeeConfirmPassword(!seeConfirmPassword)
                        }
                        className={`${
                          isConfirmPasswordFocused && 'border-[#3D82F1]'
                        } hover:cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-[#DBE3EB] rounded-r-md border border-l-0 border-gray-300`}
                      >
                        {seeConfirmPassword ? (
                          <img alt='open eye' src={eye} />
                        ) : (
                          <img alt='closed eye' src={eyeclosed} />
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className='flex sm:block justify-end col-start-1'>
                  {isValid && dirty ? (
                    <button
                      type='submit'
                      className='mt-4 sm:mt-9 text-white bg-[#3D82F1] hover:bg-blue-600 py-2 px-4 rounded-lg text-center w-full'
                    >
                      Save
                    </button>
                  ) : (
                    <div className='mt-4 sm:mt-9 text-white bg-[#B7C6D7] hover:cursor-not-allowed py-2 px-4 rounded-lg text-center'>
                      Save
                    </div>
                  )}
                </div>
                <div className='flex sm:block justify-end '>
                  <button
                    type='button'
                    onClick={() => setIsChangingPassword(false)}
                    className='hover:underline hover:text-blue-500 hover:cursor-pointer text-center w-full mt-4 xl:mt-11'
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className='flex'>
                <button
                  type='button'
                  onClick={() => setIsChangingPassword(true)}
                  className='bg-[#3D82F1] hover:bg-blue-600 rounded-lg text-white p-2 px-4'
                >
                  Change Password
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
