import React, { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import LargeSupportModal from '../Modals/LargeSupportModal';
import useAuth from '../../hooks/useAuth';

const LargeContactUs = () => {
  const [isOpenSupportPortalModal, setIsOpenSupportPortalModal] =
    useState(false);

  const handleSupportModal = () => {
    setIsOpenSupportPortalModal(!isOpenSupportPortalModal);
  };

  const openSupportModal = () => {
    setIsOpenSupportPortalModal(true);
  };

  const closeSupportModal = () => {
    setIsOpenSupportPortalModal(false);
  };

  const userinfo = useAuth();

  return (
    <div className='px-4'>
      <Popover className=''>
        {({ open }) => (
          <>
            <Popover.Button
              className={`
              ${
                open
                  ? ''
                  : 'text-opacity-90' +
                    ' hover:text-[#3D82F1] hover:cursor-pointer'
              }
             `}
            >
              <span>Contact Us</span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel
                className={`absolute ${
                  userinfo.role === 'wazuh' ? 'left-[800px]' : ' left-[783px]'
                }  z-10 mt-3 w-screen -translate-x-1/2 transform px-4 sm:px-0 max-w-[220px]`}
              >
                <span className='triangle-large-deal border border-blue-300 bg-white'></span>
                <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 border-blue-400 border'>
                  <div className='relative grid hover:cursor-pointer hover:text-blue-500 gap-8 bg-white py-4 px-7'>
                    <Link to='/contact-us/sales-team' className='text-base'>
                      Wazuh Sales Team
                    </Link>
                  </div>
                  <div className='relative grid hover:cursor-pointer hover:text-blue-500 gap-8 bg-white py-4 px-7'>
                    <Link to='/contact-us/request-a-demo' className='text-base'>
                      Request a Demo
                    </Link>
                  </div>
                  <div
                    onClick={handleSupportModal}
                    className='relative grid hover:cursor-pointer hover:text-blue-500 gap-8 bg-white py-4 px-7'
                  >
                    Support Portal
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <LargeSupportModal
        closeModal={closeSupportModal}
        openModal={openSupportModal}
        isOpen={isOpenSupportPortalModal}
      />
    </div>
  );
};

export default LargeContactUs;
