import axios from 'axios';
import { saveAs } from 'file-saver';

export const DownloadFiles = async (endpoint, filename, mimetype) => {
  const getPdf = async () => {
    return axios.get(endpoint, {
      headers: {
        'Content-Type': 'multiple/form-data',
      },
      responseType: 'arraybuffer',
    });
  };

  const printPdf = async () => {
    const { data } = await getPdf();
    const blob = new Blob([data], { type: mimetype });
    saveAs(blob, filename);
  };

  printPdf();
};
