import * as React from 'react';
const Export = (props) => (
  <svg
    width='41'
    height='41'
    viewBox='0 0 41 41'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='20.5' cy='20.5' r='20.5' fill={props.bgfill} />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.75 15.5V14.3818C27.75 13.7563 27.2437 13.25 26.6182 13.25H24.375V14.375H23.25V13.25H18.75V14.375H17.625V13.25H15.3818C14.7563 13.25 14.25 13.7563 14.25 14.3818V25.6183C14.25 26.2438 14.7563 26.75 15.3818 26.75H26.6182C27.2437 26.75 27.75 26.2438 27.75 25.6183V16.625H14.25V15.5H27.75ZM24.375 12.125H26.6182C27.8647 12.125 28.875 13.1341 28.875 14.3818V25.6183C28.8751 25.9147 28.8169 26.2082 28.7035 26.482C28.5902 26.7559 28.4239 27.0048 28.2143 27.2143C28.0048 27.4239 27.7559 27.5902 27.482 27.7035C27.2082 27.8169 26.9147 27.8751 26.6182 27.875H15.3818C15.0853 27.8751 14.7918 27.8169 14.518 27.7035C14.2441 27.5902 13.9952 27.4239 13.7857 27.2143C13.5761 27.0048 13.4098 26.7559 13.2965 26.482C13.1831 26.2082 13.1249 25.9147 13.125 25.6183V14.3818C13.125 13.1353 14.1341 12.125 15.3818 12.125H17.625V11H18.75V12.125H23.25V11H24.375V12.125ZM16.5 18.875H18.75V20H16.5V18.875ZM19.875 18.875H22.125V20H19.875V18.875ZM23.25 18.875H25.5V20H23.25V18.875ZM16.5 21.125H18.75V22.25H16.5V21.125ZM19.875 21.125H22.125V22.25H19.875V21.125ZM23.25 21.125H25.5V22.25H23.25V21.125ZM16.5 23.375H18.75V24.5H16.5V23.375ZM19.875 23.375H22.125V24.5H19.875V23.375ZM23.25 23.375H25.5V24.5H23.25V23.375Z'
      fill={props.mainfill}
    />
  </svg>
);
export default Export;
