import * as React from 'react';
const Export = (props) => (
  <svg
    width='41'
    height='41'
    viewBox='0 0 41 41'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='20.5' cy='20.5' r='20.5' fill={props.bgfill} />
    <path
      d='M28.8178 15.145L21.7176 21.0262C21.3013 21.371 20.6987 21.371 20.2824 21.0262L13.1822 15.145C13.1451 15.2566 13.125 15.376 13.125 15.5V24.5C13.125 25.1213 13.6287 25.625 14.25 25.625H27.75C28.3713 25.625 28.875 25.1213 28.875 24.5V15.5C28.875 15.376 28.8549 15.2566 28.8178 15.145ZM14.25 13.25H27.75C28.9926 13.25 30 14.2574 30 15.5V24.5C30 25.7426 28.9926 26.75 27.75 26.75H14.25C13.0074 26.75 12 25.7426 12 24.5V15.5C12 14.2574 13.0074 13.25 14.25 13.25ZM14.0134 14.375L20.2871 19.5538C20.7014 19.8958 21.2997 19.8972 21.7155 19.5571L28.0514 14.375H14.0134Z'
      fill={props.mainfill}
    />
  </svg>
);
export default Export;
