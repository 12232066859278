import { apiSlice } from '../../apiSlice';

export const partnersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPartnerById: builder.mutation({
      query: (id) => ({
        url: `/api/partners/getpartnerbyid/${id}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getPartnerDetails: builder.mutation({
      query: (partner) => ({
        url: `/api/partners/getpartnerdetails/${partner}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getPartnerDates: builder.mutation({
      query: (partner) => ({
        url: `/api/partners/getpartnerdates/${partner}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getPartners: builder.mutation({
      query: (id) => ({
        url: `/api/partners/getpartners`,
        method: 'GET',
        headers: { authorization: id },
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    createPartner: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/partners/createpartner`,
        method: 'POST',
        body: body,
        headers: { authorization: id },
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    updateUsersPartner: builder.mutation({
      query: (body) => ({
        url: `/api/partners/changeuserpartner`,
        method: 'PUT',
        body: body,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    deletePartnerById: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/partners/deletepartnerbyid`,
        method: 'DELETE',
        body: body,
        headers: { authorization: id },
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getPartnerOwner: builder.mutation({
      query: (partnerName) => ({
        url: `/api/partners/getpartnerowner/${partnerName}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    updatePartnerName: builder.mutation({
      query: (body) => ({
        url: `/api/partners/updatepartnername`,
        method: 'PUT',
        body: body,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    updatePartnerSalesforceId: builder.mutation({
      query: (body) => ({
        url: `/api/partners/updatepartnersalesforceid`,
        method: 'PUT',
        body: body,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
  }),
});

export const {
  useGetPartnerByIdMutation,
  useGetPartnerDetailsMutation,
  useGetPartnerDatesMutation,
  useGetPartnersMutation,
  useCreatePartnerMutation,
  useUpdateUsersPartnerMutation,
  useDeletePartnerByIdMutation,
  useGetPartnerOwnerMutation,
  useUpdatePartnerNameMutation,
  useUpdatePartnerSalesforceIdMutation,
} = partnersApiSlice;
