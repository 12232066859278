import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReadMore from '../components/img/ReadMore.svg';
import BlueDownArrow from '../components/img/BlueDownArrow.svg';
import Notification from './img/Notification.svg';

const MobileCustomersCard = ({ customer }) => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const handleRowClick = (id) => {
    navigate(`/customer/${id}`);
  };
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  const today = new Date();
  const closeDate = new Date(customer?.Contract_Expiration_Date__c);
  const diffDays = Math.ceil((closeDate - today) / (1000 * 60 * 60 * 24));

  function reformatDate(dateStr) {
    let dArr = dateStr.split('-'); // ex input "2010-01-18"
    return dArr[1] + '/' + dArr[2] + '/' + dArr[0]; //ex out: "18/01/10"
  }

  return (
    <div>
      <div
        className={`${
          menu ? 'border-[#3D82F1]' : 'border-[#F8FAFD]'
        }  rounded-lg border bg-[#F8FAFD] grid grid-cols-6 mt-4 justify-around py-2 `}
      >
        <div className='col-span-3 ml-4 items-center flex'>
          {customer?.Partner_Client__c}
          {diffDays > 0 && diffDays <= 30 && (
            <img className='ml-2 inline' src={Notification}></img>
          )}
        </div>
        <div className=' col-span-2 ml-4 '>{customer?.Type}</div>
        <div className='justfy-center col-span-1 mt-2  flex ml-[50%]'>
          <img
            onClick={() => setMenu(!menu)}
            className={`${
              menu && 'rotate-180'
            } transition ease-out duration-300 h-3`}
            src={BlueDownArrow}
          ></img>
        </div>
        {menu && (
          <>
            <span className='form-divider col-span-6 mx-4 h-1 my-3'></span>
            <div className=' col-span-3 ml-4'>
              <div className=' font-semibold'>Amount:</div>
              {'$ ' + numberWithCommas(customer?.Amount)}
            </div>

            <div className=' col-span-3  ml-4'>
              <div className=' font-semibold'>Expiration day:</div>
              {reformatDate(customer?.Contract_Expiration_Date__c)}
            </div>

            <div className='col-span-4'></div>
            <span className='form-divider col-span-6 mx-4 h-1 my-3'></span>
            <div className='col-span-6 font-semibold ml-4'>Actual plan:</div>
            <div className='col-span-6 ml-4'>{customer.ProductName}</div>
            {diffDays > 0 && diffDays <= 30 && (
              <div className='col-span-6 bg-orange-500 bg-opacity-10 px-4 py-2 mt-2'>
                <div className=''>
                  This plan is about to expire, please contact to
                  <h1 className='text-blue-500 inline'> partners@wazuh.com</h1>
                </div>
              </div>
            )}
            <span className='form-divider col-span-6 mx-4 h-1 my-3'></span>
            <div
              onClick={() => handleRowClick(customer?.Id)}
              className='col-span-6 mt-2 flex items-center text-xs justify-end mr-2 mb-2'
            >
              Read more
              <img className='ml-1 ' src={ReadMore}></img>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MobileCustomersCard;
