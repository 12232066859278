import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRefreshMutation } from '../redux/features/user/authApiSlice';
import usePersist from './usePersist';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../redux/features/user/authSlice';

const IsLoggedIn = () => {
  const [persist] = usePersist();
  const token = useSelector(selectCurrentToken);
  const effectRan = useRef(false);
  const [trueSuccess, setTrueSuccess] = useState(false);
  const [refresh, { isUninitialized, isLoading, isSuccess, isError, error }] =
    useRefreshMutation();

  const navigate = useNavigate();

  const redirectDashboard = () => {
    navigate('/dashboard');
  };

  useEffect(
    () => {
      const verifyRefreshToken = async () => {
        try {
          await refresh();

          setTrueSuccess(true);
        } catch (err) {
          console.error(err);
        }
      };

      if (!token && persist) verifyRefreshToken();
    },
    // eslint-disable-next-line
    []
  );

  let content;
  if (!persist) {
    content = <Outlet />;
  } else if (isLoading) {
    content = <p>Loading...</p>;
  } else if (isError) {
    content = <Outlet />;
  } else if (isSuccess && trueSuccess) {
    redirectDashboard();
  } else if (token && isUninitialized) {
    redirectDashboard();
  }

  return content;
};

export default IsLoggedIn;
