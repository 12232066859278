import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../redux/features/user/authSlice';
import jwtDecode from 'jwt-decode';

const useAuth = () => {
  const token = useSelector(selectCurrentToken);

  if (token) {
    const decoded = jwtDecode(token);
    // console.log(decoded, 'decoded');
    return decoded;
  }
};
export default useAuth;
