import React, { useState, useEffect } from 'react';
import StepOne from '../components/Forms/Partner Deal Registration/StepOne';
import StepTwo from '../components/Forms/Partner Deal Registration/StepTwo';
import StepThree from '../components/Forms/Partner Deal Registration/StepThree';
import StepFour from '../components/Forms/Partner Deal Registration/StepFour';
import useAuth from '../hooks/useAuth';
import {
  useCreateDealMutation,
  useCreateRegisteredDealMutation,
} from '../redux/features/user/dealsApiSlice';
import { useGetPartnerByIdMutation } from '../redux/features/user/partnersApiSlice';

const DealRegistration = () => {
  const [partnerData, setPartnerData] = useState(null);
  const [partnerSalesforceId, setPartnerSalesforceId] = useState('');
  const userinfo = useAuth();
  const [createDeal] = useCreateDealMutation();
  const [createRegisteredDeal] = useCreateRegisteredDealMutation();
  const [getPartner] = useGetPartnerByIdMutation();

  useEffect(() => {
    const getPartnerData = async () => {
      const result = await getPartner(userinfo.partnerId).unwrap();
      setPartnerData(result[0].name);
      setPartnerSalesforceId(result[0].salesforceId);
    };
    getPartnerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const savedValues = JSON.parse(localStorage.getItem('formValues')) || {};

  const [data, setData] = useState({
    Name: savedValues.Name || '',
    Contact_Name__c: savedValues.Contact_Name__c || '',
    Contact_Email__c: savedValues.Contact_Email__c || '',
    Description: savedValues.Description || '',
    budget__c: savedValues.budget__c || '',
    CloseDate: savedValues.CloseDate || '',
    Type: savedValues.Type || '',
    Number_of_Servers__c: savedValues.Number_of_Servers__c || '',
    Number_of_Workstations__c: savedValues.Number_of_Workstations__c || '',
    Number_of_Network_Devices__c:
      savedValues.Number_of_Network_Devices__c || '',
    Hot_Storage_Retention_Period__c:
      savedValues.Hot_Storage_Retention_Period__c || '',
    Cold_Storage_Retention_Period__c:
      savedValues.Cold_Storage_Retention_Period__c || '',
    Sales_Notes__c: savedValues.Sales_Notes__c || '',
    Request_Demo__c: savedValues.Request_Demo__c || '',
    registeredby__c: savedValues.registeredby__c || userinfo.username,
    email: savedValues.email || userinfo.email,
    Professional_support: savedValues.Professional_support || '',
    recaptcha: null,
    formType: savedValues.formType || '',
    cloudType: savedValues.cloudType || 'small',
    activeAgents: savedValues.activeAgents || '100',
    indexedDataRetention: savedValues.indexedDataRetention || '1 Month',
    archiveDataRetention: savedValues.archiveDataRetention || '3 Months',
    supportPlan: savedValues.supportPlan || 'Standard',
    averagePeakEPS: savedValues.averagePeakEPS || '100 to 500 EPS',
    useRecommendedValueEPS: savedValues.useRecommendedValueEPS || true,
    indexedDataCapacity: savedValues.indexedDataCapacity || '25 GB',
    useRecommendedValueDataCapacity:
      savedValues.useRecommendedValueDataCapacity || true,
  });
  const [currentStep, setCurrentStep] = useState(0);

  const makeRequest = async (formData) => {
    try {
      const createdeal = await createDeal(formData).unwrap();
      const { returnedId } = createdeal;
      const createRegisteredDealData = await createRegisteredDeal({
        dealId: returnedId,
        userId: userinfo.id,
      }).unwrap();
      return createdeal;
    } catch (error) {
      return error.data;
    }
  };

  const handleNextStep = async (newData, final = false) => {
    setData((prev) => ({
      partnerName: partnerData,
      salesforceId: partnerSalesforceId,
      ...prev,
      ...newData,
    }));
    if (final) {
      const res = await makeRequest(newData);
      localStorage.removeItem('formValues');
      return res;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const handlePrevStep2 = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 2);
  };

  const handlePrevStep3 = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 3);
  };

  const steps = [
    <StepOne next={handleNextStep} data={data} />,
    <StepTwo prev={handlePrevStep} next={handleNextStep} data={data} />,
    <StepThree prev={handlePrevStep} next={handleNextStep} data={data} />,
    <StepFour
      prev3={handlePrevStep3}
      prev2={handlePrevStep2}
      prev={handlePrevStep}
      next={handleNextStep}
      data={data}
    />,
  ];

  return <div className=''>{steps[currentStep]}</div>;
};

export default DealRegistration;
