import React, { useState } from 'react';
import MobileCustomersCard from './MobileCustomersCard';
import Search from '../components/img/Search.svg';
import Plus from '../components/img/Plus.svg';
import { downloadExcel } from 'react-export-table-to-excel';
import Export from './img/Export';
import { Link } from 'react-router-dom';

const MobileMyCustomers = ({ customers }) => {
  const [query, setQuery] = useState('');

  const search = (data) => {
    return data.filter(
      (item) =>
        item?.Partner_Client__c?.toLowerCase().includes(query.toLowerCase()) ||
        item?.Type?.toLowerCase().includes(query.toLowerCase()) ||
        item?.Contract_Expiration_Date__c?.toLowerCase().includes(
          query.toLowerCase()
        ) ||
        JSON.stringify(item.Amount).includes(query.toLocaleLowerCase())
    );
  };

  const filteredCustomers = search(customers);

  const body = search(customers).map((data) => [
    data.Partner_Client__c,
    data.ProductName,
    data.Type,
    data.Contract_Expiration_Date__c,
    data.Amount,
  ]);

  const header = [
    'Company',
    'Actual Plan',
    'Service',
    'Expiration Date',
    'Amount',
  ];
  function handleDownloadExcel() {
    downloadExcel({
      fileName: 'Excel Customers',
      sheet: 'Customers',
      tablePayload: {
        header,
        body: body,
      },
    });
  }

  return (
    <div className='md:hidden mt-4 mx-6'>
      <div className='flex my-2 h-8'>
        <span className='inline-flex relative  items-center px-2 text-gray-900 bg-gray-[#DBE3EB] rounded-l-md  border-r-0'>
          <img alt='search' src={Search} />
        </span>
        <input
          type='text'
          id='website-admin'
          className='rounded-md -ml-8 pl-9 py-1 bg-gray-50 border  text-gray-900 focus:ring-mainblue focus:border-mainblue block flex-1 min-w-0  w-24 text-sm border-gray-300 '
          placeholder='Search'
          onChange={(e) => setQuery(e.target.value)}
        />
        <button
          onClick={handleDownloadExcel}
          className='text-[#292929] block md:hidden items-center bg-[#EBD0FC] hover:bg-[#D3ADF0] rounded-lg px-[10px] ml-4 h-8'
        >
          <Export className='w-6 h-5' fill='#292929' />
        </button>
        <Link to='/deal-registration' className='flex'>
          <button className='ml-4 py-1 px-[14px] rounded-lg bg-[#FEDD0B] hover:bg-[#FCE765]'>
            <img src={Plus} />
          </button>
        </Link>
      </div>
      <div className='bg-[#292929] text-white font-semibold grid grid-cols-6 rounded-lg mt-4 py-2'>
        <div className='ml-4 col-span-3'>Comapny</div>
        <div className='ml-4'>Service</div>
      </div>
      {filteredCustomers.map((customer) => (
        <MobileCustomersCard key={customer.Id} customer={customer} />
      ))}
    </div>
  );
};

export default MobileMyCustomers;
