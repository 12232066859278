import React, { useState } from 'react';
import BlueDownArrow from '../components/img/BlueDownArrow.svg';
import ReadMore from '../components/img/ReadMore.svg';
import { useNavigate } from 'react-router-dom';

const MobileDealsCard = ({ deal }) => {
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  const handleRowClick = (id) => {
    navigate(`/deal/${id}`);
  };

  function reformatDate(dateStr) {
    let dArr = dateStr.split('-'); // ex input "2010-01-18"
    return dArr[1] + '/' + dArr[2] + '/' + dArr[0]; //ex out: "18/01/10"
  }

  return (
    <div>
      <div
        className={`${
          deal.StageName === 'Pending approval'
            ? 'border-[#FF7A2F]'
            : menu
            ? 'border-[#3D82F1]'
            : 'border-[#F8FAFD]'
        } border-x border-t ${
          deal.StageName === 'Pending approval' &&
          !menu &&
          'rounded-br-lg rounded-bl-lg border-b'
        }  rounded-tr-lg rounded-tl-lg  bg-[#F8FAFD]  grid grid-cols-6  mt-4 justify-around py-2`}
      >
        <div className='col-span-3 ml-4 '>{deal.Partner_Client__c}</div>
        <div className=' col-span-2 ml-4 '>{deal.Type}</div>
        <div className='justfy-center col-span-1 items-center flex ml-[50%]'>
          <img
            onClick={() => setMenu(!menu)}
            className={`${
              menu && 'rotate-180'
            } transition ease-out duration-300 h-3`}
            src={BlueDownArrow}
          ></img>
        </div>
      </div>
      {menu && (
        <div
          className={`${
            deal.StageName === 'Pending approval'
              ? 'border-[#FF7A2F]'
              : 'border-[#3D82F1]'
          } border-x rounded-br-lg rounded-bl-lg border-b grid grid-cols-6 bg-[#F8FAFD]`}
        >
          <span className='form-divider col-span-6 mx-4 h-1 my-3'></span>
          <div className=' col-span-3 ml-4 mt-2'>
            <div className=' font-semibold'>Stage:</div>
            {deal.StageName}
          </div>

          <div className=' col-span-3 mt-2 ml-4'>
            <div className=' font-semibold'>Expiration date:</div>
            {reformatDate(deal.CloseDate)}
          </div>
          <span className='form-divider col-span-6 mx-4 h-1 my-3'></span>
          <div className='col-span-4'></div>
          <div
            onClick={() => handleRowClick(deal.Id)}
            className='col-span-2 mt-2 flex items-center text-xs justify-end mr-2 mb-2'
          >
            Read more
            <img className='ml-1 ' src={ReadMore}></img>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileDealsCard;
