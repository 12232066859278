import { apiSlice } from '../../apiSlice';
import { logOut, setCredentials } from './authSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/api/auth/login',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    sendLogout: builder.mutation({
      query: () => ({
        url: '/api/auth/logout',
        method: 'POST',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // console.log(data);
          dispatch(logOut());
        } catch (err) {
          console.log(err);
        }
      },
    }),
    refresh: builder.mutation({
      query: () => ({
        url: '/api/refresh',
        method: 'GET',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // console.log(data);
          const { accesstoken } = data;
          dispatch(setCredentials({ accesstoken }));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    registerUser: builder.mutation({
      query: (formData) => ({
        url: '/api/auth/register',
        method: 'POST',
        body: formData,
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    jwtTest: builder.mutation({
      query: ({ id, username, email }) => ({
        url: `/api/refresh/token/${id}`,
        method: 'PUT',
        body: { username, email },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // console.log(data, 'data on queryfullfilled');
          const { accesstoken } = data;
          dispatch(setCredentials({ accesstoken }));
        } catch (err) {
          console.log(err);
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useSendLogoutMutation,
  useRefreshMutation,
  useRegisterUserMutation,
  useJwtTestMutation,
} = authApiSlice;
