import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import useAuth from '../../hooks/useAuth';
import axios from 'axios';
import { useGetPartnerByIdMutation } from '../../redux/features/user/partnersApiSlice';

const PartnerProgramTermModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [partnerData, setPartnerData] = useState([]);
  const userinfo = useAuth();
  const [getPartner] = useGetPartnerByIdMutation();
  useEffect(() => {
    const partnerProgramTermsAccepted =
      localStorage.getItem('partnerProgramTerm');
    if (partnerProgramTermsAccepted !== 'true') {
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    const getPartnerData = async () => {
      const result = await getPartner(userinfo.partnerId).unwrap();
      setPartnerData(result);
    };
    getPartnerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const acceptTerms = async () => {
    setIsOpen(false);
    localStorage.setItem('partnerProgramTerm', 'true');
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/mails/partnerterms`,
      {
        username: userinfo.username,
        email: userinfo.email,
        partner: partnerData[0].name,
      }
    );
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full font-manrope max-w-5xl transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-2xl font-medium leading-6 text-gray-900'
                  ></Dialog.Title>
                  <div className='flex justify-between bg-[#3D82F1] text-white h-[72px] 2xl:h-[76px] rounded-2xl'>
                    <div className='flex items-center'>
                      <h1 className='text-2xl 2xl:text-3xl font-semibold ml-4'>
                        Partners Program{' '}
                        <span className='wzh-remark font-extrabold'>Terms</span>
                      </h1>
                    </div>
                  </div>
                  <div className='mt-6 mx-11'>
                    <p className='text-base text-black mb-5 font-bold'>
                      Dear Partner,
                    </p>
                    <p className='text-base text-black mb-5'>
                      We are writing to inform you of some updates to our
                      Partnership Program. Please review the relevant changes to
                      the Partner Program Terms below:
                    </p>
                    <p className='text-[#3D82F1] mb-5'>
                      1. Eligibility Criteria for Partner visibility
                    </p>
                    <p className='text-sm text-black mb-5'>
                      Effective June 1st, 2024, Partners will be able to retain
                      their logos and press release announcements on our
                      platforms by meeting the Eligibility Criteria of securing
                      at least two (2) new Subscription Customers per year. For
                      Partners with Subscription Terms expiring before September
                      1, 2024, this criterion will be enforced starting from
                      their renewal date.
                    </p>
                    <p className='text-[#3D82F1] mb-5'>
                      2. Cloud-hosted Centralized Console limits
                    </p>
                    <p className='text-sm text-black mb-5'>
                      The Partner Program Guide now explicitly states that each
                      cloud-hosted Centralized Console is limited to 20
                      environments within the same data center region. If a
                      Partner exceeds this limit, we will gladly provide
                      additional Centralized Consoles as necessary.
                    </p>
                    <p className='text-sm text-black mb-5'>
                      For more details, please refer ro the latest version of
                      the Wazuh Partner Program Terms. If you have any questions
                      or need further clarification, please contact us at{' '}
                      <a
                        className=' text-blue-500 hover:underline focus:outline-none'
                        href='mailto:partners@wazuh.com'
                      >
                        partners@wazuh.com
                      </a>
                      .
                    </p>
                    <p className='text-sm text-gray-900 mb-3'>
                      Thank you for your continued collaboration.{' '}
                    </p>
                    <p className='text-sm text-gray-900'>Best regards</p>
                  </div>

                  <div className='mt-4 flex bg-[#f8f8f8] ml-8 rounded-xl py-3 px-4 justify-end'>
                    <button
                      type='button'
                      className='inline-flex ml-5 justify-center rounded-md btn bg-yellow-gradient py-2 text-lg font-medium px-16'
                      onClick={acceptTerms}
                    >
                      Accept
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PartnerProgramTermModal;
