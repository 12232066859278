import React from 'react';
import ChangePassword from '../components/Forms/User Settings/ChangePassword';
import ChangeUserDetails from '../components/Forms/User Settings/ChangeUserDetails';
import ManageUsers from '../components/Forms/User Settings/ManageUsers';
import TwoFactorAuthentication from '../components/Forms/User Settings/TwoFactorAuthentication';

const MySettings = () => {
  return (
    <div className=''>
      <div className='text-2xl 2xl:text-3xl font-semibold p-5 xl:pl-[22px] 2xl:px-[40px] text-white bg-[#3D82F1] flex items-center rounded-2xl m-3 lg:m-7'>
        Profile
      </div>
      <div className='m-5 xl:ml-9 2xl:mx-[55px] grid grid-cols-1 xl:grid-cols-2'>
        <ChangeUserDetails />
        <ChangePassword />
        <ManageUsers />
        <TwoFactorAuthentication />
      </div>
    </div>
  );
};

export default MySettings;
