import React from 'react';
import AskADemo from '../components/img/AskADemo.svg';
import ProvideADemo from '../components/img/ProvideADemo.svg';
import BlueRightArrow from '../components/img/BlueRightArrow.svg';
import { Link } from 'react-router-dom';

const StartDemo = () => {
  return (
    <div className='m-5 mt-8'>
      <div className='text-3xl font-semibold'>Start your demo now!</div>
      <div className='mt-4 mb-4'>
        Guide your customers with this demo to explain how <br /> the wazuh
        service works.
      </div>
      <div className='flex gap-8 mt-12'>
        <div className='rounded-lg hover:border hover:border-blue-500 bg-blue-100 flex flex-col items-center px-24'>
          <div className='bg-white  flex justify-center rounded-full my-8 mx-16 px-7 py-4'>
            <img
              alt='provide a demo'
              className='h-[70px]'
              src={ProvideADemo}
            ></img>
          </div>
          <h1 className='text-xl font-semibold'>Provide a demo</h1>
          <h2>give the service on your own</h2>
          <img
            alt='blue arrow pointing right'
            className=' w-8 mt-16 mb-8'
            src={BlueRightArrow}
          ></img>
        </div>
        <div className='rounded-lg bg-blue-100 hover:border hover:border-blue-500 flex flex-col items-center px-24'>
          <div className='bg-white rounded-full my-8 mx-16 p-4'>
            <img
              alt='ask a demo'
              className='h-[70px] w-[70px]'
              src={AskADemo}
            ></img>
          </div>
          <h1 className='text-xl font-semibold'>Request a demo</h1>
          <h2>with the Wazuh team</h2>
          <Link to='/askdemo'>
            <img
              alt='blue arrow pointing right'
              className=' w-8 mt-16 mb-8'
              src={BlueRightArrow}
            ></img>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StartDemo;
