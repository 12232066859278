import React, { Fragment, useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useGetPartnersMutation } from '../../../redux/features/user/partnersApiSlice';
import { useLocation } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { useUpdatePartnerSalesforceIdMutation } from '../../../redux/features/user/partnersApiSlice';

const ChangePartnerSalesforceId = () => {
  let [isOpen, setIsOpen] = useState(false);
  const [getPartners] = useGetPartnersMutation();
  const [updatePartnerSalesforceId] = useUpdatePartnerSalesforceIdMutation();
  const [partnersList, setPartnersList] = useState([]);
  const [res, setRes] = useState('');
  const location = useLocation();
  const path = location.pathname.split('/')[1];
  const [partnerId, setPartnerId] = useState(-1);

  useEffect(() => {
    const fetchPartners = async () => {
      const result = await getPartners('register-user').unwrap();
      setPartnersList(result);
    };

    fetchPartners();
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const modalInitialValues = {
    partnerId: partnerId,
    salesforceId: '',
  };

  const handleSubmit = async (formData) => {
    try {
      const res = await updatePartnerSalesforceId(formData).unwrap();
      setRes(res);
      // console.log('partner name updated successfully');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {' '}
      <button
        onClick={openModal}
        type='button'
        className='bg-[#3b46da] hover:bg-blue-700  w-64 rounded-lg  text-white py-2 text-center items-center'
      >
        Change Partner Salesforce Id
      </button>
      <Transition
        appear
        show={isOpen}
        //   show={modalDeletePartnerOpen}
        as={Fragment}
      >
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 mb-2 text-gray-900'
                  >
                    Change Partner Salesforce Id {res}
                  </Dialog.Title>
                  <div>
                    <div className='flex flex-col'>
                      Partner:
                      <select
                        className=' border border-[#B7C6D7] rounded-md  w-64 px-2 py-2 text-black placeholder-[#B7C6D7]'
                        onChange={(e) => setPartnerId(e.target.value)}
                      >
                        <option disabled selected value=''>
                          -
                        </option>
                        {partnersList.map((partner) => (
                          <option value={partner.id}>{partner.name}</option>
                        ))}
                      </select>
                    </div>
                    <Formik
                      enableReinitialize={true}
                      initialValues={modalInitialValues}
                      onSubmit={handleSubmit}
                    >
                      <Form className='flex justify-between'>
                        {/* <Field
                          className=' border border-[#B7C6D7] rounded-md  w-64 px-2 py-2 text-black placeholder-[#B7C6D7]'
                          as='select'
                          name='User'
                          //   onChange={(e) => setUserId(e.target.value)}
                        >
                          <option disabled selected value=''>
                            -
                          </option>
                          {usersList.map((user) => (
                            <option value={user.id}>{user.username}</option>
                          ))}
                        </Field> */}
                        <div className='flex flex-col'>
                          Salesforce Id:
                          <Field
                            className=' border border-[#B7C6D7] rounded-md  w-64 px-2 py-2 text-black placeholder-[#B7C6D7]'
                            name='salesforceId'
                            type='text'
                          ></Field>
                        </div>
                        <button
                          type='submit'
                          className='bg-[#4d3ed8] hover:bg-purple-700 px-2 rounded-lg  text-white py-2 text-center items-center'
                        >
                          Update
                        </button>
                      </Form>
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default ChangePartnerSalesforceId;
