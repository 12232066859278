import React, { useState, useEffect } from 'react';
import Search from '../components/img/Search.svg';
import Plus from '../components/img/Plus.svg';
import Export from '../components/img/Export';
import RegisteredDealsTable from './RegisteredDealsTable';
import { Link } from 'react-router-dom';
import MobileRegisteredDeals from '../components/MobileRegisteredDeals';
import useAuth from '../hooks/useAuth';
import { useGetRenewalsMutation } from '../redux/features/user/dealsApiSlice';
import { useGetPartnerByIdMutation } from '../redux/features/user/partnersApiSlice';
import { downloadExcel } from 'react-export-table-to-excel';

const RenewalCustomers = () => {
  const [renewals, setRenewals] = useState([]);
  const [query, setQuery] = useState('');
  const [callLoading, setCallLoading] = useState(true);
  const [partnerData, setPartnerData] = useState(null);
  const [sort, setSort] = useState('Partner_Client__c');
  const [orientation, setOrientation] = useState('ascending');
  const [getRenewals] = useGetRenewalsMutation();
  const [getPartner] = useGetPartnerByIdMutation();

  const userinfo = useAuth();

  useEffect(() => {
    const getPartnerData = async () => {
      const partnerInfo = await getPartner(userinfo.partnerId).unwrap();
      setPartnerData(partnerInfo);
    };
    getPartnerData();
  }, []);

  useEffect(() => {
    const getRenewalsData = async () => {
      if (partnerData && partnerData.length > 0) {
        const result = await getRenewals(partnerData[0].salesforceId).unwrap();
        setRenewals(result);
        await setCallLoading(false);
      }
    };
    getRenewalsData();
  }, [partnerData]);

  const CloudTypes = [
    'Customer Renewal Cloud',
    'Customer Renewal/Upgrade Cloud',
    'Customer Renewal/Downgrade Cloud',
    'Partner Customer Renewal Cloud',
    'Partner Customer Renewal/Upgrade Cloud',
    'Partner Customer Renewal/Downgrade Cloud',
  ];

  const PremisesTypes = [
    'Customer Renewal On-premise',
    'Customer Renewal/Expansion On-premise',
    'Customer Renewal/Reduction On-premise',
    'Partner Customer Renewal On premises',
    'Partner Customer Renewal/ Upgrade On premises',
    'Partner Customer Renewal/ Reduction On-Prem',
  ];

  const newArray = renewals
    .filter(
      (obj) => CloudTypes.includes(obj.Type) || PremisesTypes.includes(obj.Type)
    )
    .map((obj) => {
      return {
        ...obj,
        Type: CloudTypes.includes(obj.Type) ? 'Cloud' : 'On premises',
        dealType: obj.StageName === 'Closed Won' ? 'Customer' : 'Opportunity',
        CloseDate:
          obj.StageName === 'Closed Won'
            ? obj.Contract_Expiration_Date__c
            : obj.CloseDate,
        StageName:
          obj.StageName === 'Closed Lost'
            ? 'Closed Lost'
            : obj.StageName === 'Closed Disqualified'
            ? 'Not approved'
            : obj.StageName === 'Lead'
            ? 'Pending approval'
            : 'Approved',
      };
    });

  function filterAndSortArray(data, sortByProperty, sortDirection) {
    // Check if sortDirection is valid
    if (sortDirection !== 'ascending' && sortDirection !== 'descending') {
      throw new Error(
        'Invalid sortDirection. Use "ascending" or "descending".'
      );
    }

    // Clone the data array to avoid modifying the original array
    const clonedData = [...data];

    // Sort the array based on the sortByProperty
    clonedData.sort((a, b) => {
      const aValue = a[sortByProperty];
      const bValue = b[sortByProperty];

      // Compare the values based on the sortDirection
      if (sortDirection === 'ascending') {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      } else {
        if (aValue < bValue) return 1;
        if (aValue > bValue) return -1;
        return 0;
      }
    });

    return clonedData;
  }

  const search2 = (data) => {
    return data.filter(
      (item) =>
        item.Partner_Client__c?.toLowerCase().includes(query.toLowerCase()) ||
        item.Type.toLowerCase().includes(query.toLowerCase()) ||
        item.CloseDate?.toLowerCase().includes(query.toLowerCase()) ||
        item.StageName.toLowerCase().includes(query)
    );
  };

  const header = ['Company', 'Plan', 'Expiration Date', 'Stage'];
  const body = filterAndSortArray(search2(newArray), sort, orientation).map(
    (data) => [
      data.Partner_Client__c,
      data.Type,
      data.CloseDate,
      data.StageName,
    ]
  );

  function handleDownloadExcel() {
    downloadExcel({
      fileName: 'Excel Renewals',
      sheet: 'Renewals',
      tablePayload: {
        header,
        body: body,
      },
    });
    // console.log(search2(newArray));
  }

  return (
    <div>
      <div className=''>
        <div className='flex justify-between bg-[#3D82F1] text-white p-5 h-[72px] 2xl:h-[76px] 2xl:pl-[40px] 2xl:pr-[26px] rounded-2xl m-3 lg:m-7'>
          <div className='flex items-center'>
            <h1 className='text-2xl 2xl:text-3xl font-semibold ml-2 md:ml-4 2xl:ml-0'>
              Renewals
            </h1>
          </div>
          <div className='hidden md:flex space-x-4 mr-3 2xl:mr-[9px] 2xl:ml-0 '>
            <div className='flex'>
              <input
                type='text'
                id='website-admin'
                className='rounded-md relative pr-6 left-8 pl-3 bg-gray-50 border  text-gray-900 focus:ring-mainblue focus:border-mainblue block flex-1 min-w-0 w-full text-sm border-gray-300'
                placeholder='Search'
                onChange={(e) => setQuery(e.target.value)}
              />
              <span className='inline-flex relative right-2  items-center px-2 text-gray-900 bg-gray-[#DBE3EB] rounded-l-md  border-r-0'>
                <img alt='search' src={Search} />
              </span>
            </div>
            <button
              onClick={handleDownloadExcel}
              className='text-[#292929] text-sm xl:text-base hidden md:flex items-center bg-yellow-gradient btn rounded-lg p-2 ml-6'
            >
              <Export className='mr-1 w-4  xl:w-5 xl:h-4' fill='#292929' />
              Download
            </button>
          </div>
        </div>
      </div>
      <div className=''>
        <RegisteredDealsTable
          loading={callLoading}
          setSort={setSort}
          setOrientation={setOrientation}
          data={filterAndSortArray(search2(newArray), sort, orientation)}
        />
      </div>
      <MobileRegisteredDeals deals={newArray} />
    </div>
  );
};

export default RenewalCustomers;
