import { useState, useEffect } from 'react';
import MobileUserData from './MobileUserData';
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

//Icons
import Customers from '../components/img/MobileMenu/Customers.svg';
import Home from '../components/img/MobileMenu/Home.svg';
import Analytics from '../components/img/MobileMenu/Analytics.svg';
import ContactUs from '../components/img/MobileMenu/ContactUs.svg';
import WazuhPanel from '../components/img/MobileMenu/WazuhPanel.svg';
import Support from '../components/img/MobileMenu/Support.svg';
import BlueDownArrow from '../components/img/BlueDownArrow.svg';
import AddUserModal from './AdminPanel/AddUserModal';
import AddPartnerModal from './AdminPanel/AddPartnerModal';
import ChangePartnerView from './AdminPanel/ChangePartnerView';
import ChangeUserRole from './AdminPanel/ChangeUserRole';
import AnalyticsButton from './AdminPanel/AnalyticsButton';
import SupportModalContact from './Modals/SupportModalContact';

const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  const [openCustomersSubMenu, setOpenCustomersSubMenu] = useState(false);
  const [openWazuhPanelSubMenu, setOpenWazuhPanelSubMenu] = useState(false);
  const [openContactUsSubMenu, setOpenContactUsSubMenu] = useState(false);
  const [openHamburger, setOpenHamburger] = useState(false);

  const userinfo = useAuth();

  const menuFunction = () => {
    setOpen(!open);
    setOpenHamburger(!openHamburger);
  };

  useEffect(() => {
    if (open) document.body.style.overflowY = 'hidden';
    else {
      document.body.style.overflowY = 'unset';
    }
  }, [open]);

  return (
    <>
      <button
        className='block lg:hidden text-gray-500 focus:outline-none'
        onClick={menuFunction}
      >
        {/* Hamburger begins */}
        <div className='flex flex-col justify-center'>
          <div className='relative  lg:max-w-xl mx-auto'>
            <nav>
              <div className='text-blue-500 w-10 h-10 relative focus:outline-none bg-white'>
                <span className='sr-only'>Open main menu</span>
                <div className='block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                  <span
                    aria-hidden='true'
                    className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                      openHamburger ? 'rotate-45' : '-translate-y-1.5'
                    }`}
                  ></span>
                  <span
                    aria-hidden='true'
                    className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                      openHamburger ? 'opacity-0' : ''
                    }`}
                  ></span>
                  <span
                    aria-hidden='true'
                    className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                      openHamburger ? '-rotate-45' : 'translate-y-1.5'
                    }`}
                  ></span>
                </div>
              </div>
            </nav>
          </div>
        </div>
        {/* Hamburger ends */}
      </button>
      <div className='custom-scrollbar fixed inset-0  z-10 -translate-y-[100%] bg-red-300'>
        <div
          style={{ scrollbarGutter: 'stable' }}
          className={`h-[calc(100vh-64px)] overflow-y-auto    bg-[#EBF2FB] transform transition-all duration-300 ease-in-out ${
            open ? 'translate-y-[calc(100%+128px)]' : ''
          } lg:hidden`}
        >
          <div className='relative py-4 lg:max-w-xl mx-auto'>
            <MobileUserData />
            <nav className='w-full'>
              <div className='flex text-center items-center my-5 '>
                <img alt='Home' className='ml-9 w-6' src={Home}></img>
                <Link
                  onClick={() => {
                    setOpen(false);
                    setOpenHamburger(false);
                  }}
                  to='/dashboard'
                  className=' ml-4 block px-4 py-2 text-gray-800'
                >
                  Home
                </Link>
              </div>
              <div
                className={`${
                  openCustomersSubMenu && 'bg-[#DCEBFF] '
                } flex text-center items-center  my-5`}
                onClick={() => setOpenCustomersSubMenu(!openCustomersSubMenu)}
              >
                <img alt='Customers' className='ml-9 w-6' src={Customers}></img>
                <h1 className='ml-4 block px-4 py-4 text-gray-800'>
                  Customers
                </h1>
                <img
                  alt='Blue Down Arrow'
                  className={`transition-all duration-300 ease-in-out ${
                    openCustomersSubMenu ? 'rotate-180' : ''
                  } flex ml-auto h-3 mr-12  justify-end`}
                  src={BlueDownArrow}
                ></img>
              </div>
              {openCustomersSubMenu && (
                <div className='-mt-2 ml-20'>
                  <Link
                    onClick={() => {
                      setOpen(false);
                      setOpenHamburger(false);
                    }}
                    to='/deal-registration'
                    className='border-l-2 border-blue-500 ml-4 block pl-10 py-3 text-gray-800'
                  >
                    Deal registration
                  </Link>
                  <Link
                    onClick={() => {
                      setOpen(false);
                      setOpenHamburger(false);
                    }}
                    to='/registered-deals'
                    className='border-l-2 border-blue-500 ml-4 block pl-10 py-3 text-gray-800'
                  >
                    Prospects
                  </Link>
                  <Link
                    onClick={() => {
                      setOpen(false);
                      setOpenHamburger(false);
                    }}
                    to='/my-customers'
                    className='border-l-2 border-blue-500 ml-4 block pl-10 py-3 text-gray-800'
                  >
                    Active customers
                  </Link>
                  <Link
                    onClick={() => {
                      setOpen(false);
                      setOpenHamburger(false);
                    }}
                    to='/expired-customers'
                    className='border-l-2 border-blue-500 ml-4 block pl-10 py-3 text-gray-800'
                  >
                    Expired contracts
                  </Link>
                </div>
              )}
              <div className='flex text-center items-center  my-5'>
                <img alt='user' className='ml-9 w-6' src={Analytics}></img>
                <Link
                  onClick={() => {
                    setOpen(false);
                    setOpenHamburger(false);
                  }}
                  to='/profile'
                  className='ml-4 block px-4 py-2 text-gray-800'
                >
                  Analytics
                </Link>
              </div>
              <div className='flex text-center items-center  my-5'>
                <img alt='Support' className='ml-9 w-6' src={Support}></img>
                <Link
                  onClick={() => {
                    setOpen(false);
                    setOpenHamburger(false);
                  }}
                  to='/resources'
                  className='ml-4 block px-4 py-2 text-gray-800'
                >
                  Resources
                </Link>
              </div>
              {userinfo.role === 'wazuh' && (
                <>
                  <div
                    className={`${
                      openWazuhPanelSubMenu && 'bg-[#DCEBFF] '
                    } flex text-center items-center  my-5`}
                    onClick={() =>
                      setOpenWazuhPanelSubMenu(!openWazuhPanelSubMenu)
                    }
                  >
                    <img
                      alt='settings'
                      className='ml-9 w-6'
                      src={WazuhPanel}
                    ></img>
                    <h1 className='ml-4 block px-4 py-4 text-gray-800'>
                      Wazuh Panel
                    </h1>
                    <img
                      alt='Blue Down Arrow'
                      className={`transition-all duration-300 ease-in-out ${
                        openWazuhPanelSubMenu ? 'rotate-180' : ''
                      } flex ml-auto h-3 mr-12  justify-end`}
                      src={BlueDownArrow}
                    ></img>
                  </div>
                  {openWazuhPanelSubMenu && (
                    <div className='-mt-2 ml-20'>
                      <div className='border-l-2 border-blue-500 ml-4 block pl-10 py-1'>
                        <AddUserModal />
                      </div>
                      <div className='border-l-2 border-blue-500 ml-4 block pl-10 py-1'>
                        <AddPartnerModal />
                      </div>
                      <div className='border-l-2 border-blue-500 ml-4 block pl-10 py-1'>
                        <ChangePartnerView />
                      </div>
                      <div className='border-l-2 border-blue-500 ml-4 block pl-10 py-1'>
                        <ChangeUserRole />
                      </div>
                      <div className='border-l-2 border-blue-500 ml-4 block pl-10 py-1'>
                        <AnalyticsButton />
                      </div>
                    </div>
                  )}
                </>
              )}
              <div
                className={`${
                  openContactUsSubMenu && 'bg-[#DCEBFF] '
                } flex text-center items-center  my-5`}
                onClick={() => setOpenContactUsSubMenu(!openContactUsSubMenu)}
              >
                <img alt='Customers' className='ml-9 w-6' src={ContactUs}></img>
                <h1 className='ml-4 block px-4 py-4 text-gray-800'>
                  Contact Us
                </h1>
                <img
                  alt='Blue Down Arrow'
                  className={`transition-all duration-300 ease-in-out ${
                    openContactUsSubMenu ? 'rotate-180' : ''
                  } flex ml-auto h-3 mr-12  justify-end`}
                  src={BlueDownArrow}
                ></img>
              </div>
              {openContactUsSubMenu && (
                <div className='-mt-2 ml-20'>
                  <Link
                    onClick={() => {
                      setOpen(false);
                      setOpenHamburger(false);
                    }}
                    to='/contact-us/sales-team'
                    className='border-l-2 border-blue-500 ml-4 block pl-10 py-3 text-gray-800'
                  >
                    Wazuh Sales Team
                  </Link>
                  <Link
                    onClick={() => {
                      setOpen(false);
                      setOpenHamburger(false);
                    }}
                    to='/contact-us/request-a-demo'
                    className='border-l-2 border-blue-500 ml-4 block pl-10 py-3 text-gray-800'
                  >
                    Request a Demo
                  </Link>
                  <div className='border-l-2 border-blue-500 ml-4 block pl-10 py-1'>
                    <SupportModalContact />
                  </div>
                </div>
              )}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
