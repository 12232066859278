import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';
import BlackDownloadButton from '../img/BlackDownloadButton.svg';
import ArrowToRight from '../img/ArrowToRight.svg';
import PlanStar from '../img/PlanStar.png';
import PlanTableRow from '../PlanTableRow';
import { DownloadFiles } from '../../hooks/DownloadFiles';
import WhiteCross from '../../components/img/WhiteCross.svg';
import useAuth from '../../hooks/useAuth';
import axios from 'axios';
import {
  useGetPartnerByIdMutation,
  useGetPartnerDetailsMutation,
} from '../../redux/features/user/partnersApiSlice';

export default function BenefitsTable({
  discount,
  isOpen,
  closeModal,
  openModal,
}) {
  const [partnerData, setPartnerData] = useState([]);
  const [partnerDetails, setPartnerDetails] = useState([]);
  const url = `${process.env.REACT_APP_API_URL}/api/`;
  const userinfo = useAuth();
  const [getPartner] = useGetPartnerByIdMutation();
  const [getPartnerDetails] = useGetPartnerDetailsMutation();
  const userPartnerId = userinfo.partnerId;
  const partnerPlan = partnerDetails?.Account_Type__c;

  useEffect(() => {
    const getPartnerData = async () => {
      const result = await getPartner(userinfo.partnerId).unwrap();
      setPartnerData(result);
    };
    getPartnerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getPartnerDetailsFunction = async () => {
      if (partnerData && partnerData.length > 0) {
        const partnerInfo = await getPartnerDetails(
          partnerData[0].salesforceId
        ).unwrap();
        setPartnerDetails(partnerInfo[0]);
      }
    };
    getPartnerDetailsFunction();
  }, [partnerData]);

  const tableContent = [
    {
      engineering: [
        {
          name: 'One cloud Subscription of 50GB',
          checked: partnerPlan === 'Platinum',
          description:
            'A cloud not-for-resale subscription limited to 50GB of hot storage.',
        },
        {
          name: 'Centralized Console for Multi-tenancy',
          checked: partnerPlan === 'Platinum',
          description:
            'A multitenant WUI presents data and provides control of different environments.',
        },
        {
          name: 'Whitelabeling of the WUI',
          checked: partnerPlan === 'Platinum',
          description:
            'Wazuh authorizes the eligible Partner to white label the WUI of Customers with a valid contract in place.',
        },
        {
          name: 'Credentials to a demo environment',
          checked: true,
          description:
            'Read and view access to the Wazuh demo environment to help Partner showcase the product to Customer.',
        },
        {
          name: 'Free cloud trial extensions of 7 extra days.',
          checked: true,
          description:
            'Access to request 21-day trials (instead of 14) for Customers at zero cost, after deal registration approval.',
        },
      ],
      training: [
        {
          name: '4-day online technical training course (#seats)',
          data: partnerPlan === 'Platinum' ? '2' : '1',
          description:
            'The purpose is to help partners build their own team of Wazuh experts.',
        },
        // {
        //   name: 'Centralized console walkthrough',
        //   checked: partnerPlan === 'Platinum',
        //   description:
        //     'One-hour session to showcase its use, once the Cloud Centralized console is set up and in use.',
        // },
        {
          name: 'Sales orientation',
          checked: true,
          description:
            'The purpose is to help partners build their own Sales team of Wazuh experts.',
        },
      ],
      sales: [
        {
          name: 'Wazuh business development program',
          checked: true,
          description:
            'Wazuh and Partner will design together a go-to-market plan with the goal of acquiring customers.',
        },
        {
          name: 'Pre-sales assistance',
          checked: true,
          description:
            "Pre-sales team can assist with demos, POC and technical queries, during the Customer's acquisition process.",
        },
        {
          name: 'Listed in the Wazuh website as a certified partner',
          checked: true,
          description:
            'Wazuh will publish the Partner logo along with a brief description of the services and territory covered.',
        },
        {
          name: 'Press Release to announce partnership',
          checked: partnerPlan === 'Platinum',
          description:
            'Official statement from Wazuh for public acknowledgement of the new alliance.',
        },
      ],
    },
  ];
  const engineering = tableContent[0].engineering;
  const training = tableContent[0].training;
  const sales = tableContent[0].sales;

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-[90vw] 2xl:w-full font-manrope max-w-7xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all'>
                  {partnerPlan == 'Platinum' || partnerPlan == 'Gold' ? (
                    <div className='bg-white p-4 xl:p-8'>
                      <div className='bg-black p-3 flex justify-between rounded-2xl rounded-b-none'>
                        <div></div>
                        <Dialog.Title
                          as='h3'
                          className='justify-center flex items-center text-xl xl:text-2xl font-medium leading-6 text-white ml-2'
                        >
                          <div className='flex items-center mr-1'>
                            <img className='mr-2' src={PlanStar}></img>
                            {partnerPlan} Plan
                          </div>
                        </Dialog.Title>
                        <img
                          src={WhiteCross}
                          onClick={closeModal}
                          className='hover:cursor-pointer justify-end mr-[14px]'
                        ></img>
                      </div>
                      <div className='grid grid-cols-1 lg:grid-cols-3 mt-4 gap-4'>
                        <div>
                          <div className='bg-[#F6F6F6] py-2  rounded-xl justify-center font-medium w-full text-center text-base xl:text-xl'>
                            Service augmentation
                          </div>
                          {engineering.map((row) => (
                            <PlanTableRow
                              key={row.name}
                              name={row.name}
                              checked={row.checked}
                              data={row.data}
                              description={row.description}
                            />
                          ))}
                        </div>
                        <div>
                          <div className='bg-[#F6F6F6] py-2  rounded-xl justify-center font-medium w-full text-center text-base xl:text-xl'>
                            Training
                          </div>
                          {training.map((row) => (
                            <PlanTableRow
                              key={row.name}
                              name={row.name}
                              checked={row.checked}
                              data={row.data}
                              description={row.description}
                            />
                          ))}
                        </div>
                        <div>
                          <div className='bg-[#F6F6F6] py-2  rounded-xl justify-center font-medium w-full text-center text-base xl:text-xl'>
                            Sales & Marketing
                          </div>
                          {sales.map((row) => (
                            <PlanTableRow
                              key={row.name}
                              name={row.name}
                              checked={row.checked}
                              data={row.data}
                              description={row.description}
                            />
                          ))}
                        </div>
                      </div>
                      <div className='text-[#3D82F1] my-4 flex flex-col text-sm xl:text-base'>
                        *These benefits may differ depending on the specific
                        version of the Partner Program Terms agreed to by the
                        partner.
                        <br />
                        <div className='lg:ml-2 text-sm xl:text-base'>
                          Any discrepancies will be settled in accordance with
                          the terms of the applicable Partner Program Terms.
                        </div>
                      </div>
                      <div className='bg-yellow-gradient rounded-lg py-3 grid grid-cols-1 lg:grid-cols-3 mt-2'>
                        <div></div>
                        <h1 className='text-[#292929] font-semibold text-center text-sm xl:text-base'>
                          You have{' '}
                          {partnerDetails.Account_Type__c === 'Platinum'
                            ? partnerDetails.Partner_Current_Discount__c
                              ? partnerDetails.Partner_Current_Discount__c >=
                                  0 &&
                                partnerDetails.Partner_Current_Discount__c <= 21
                                ? '18'
                                : partnerDetails.Partner_Current_Discount__c >
                                    21 &&
                                  partnerDetails.Partner_Current_Discount__c <=
                                    25
                                ? '22'
                                : partnerDetails.Partner_Current_Discount__c >
                                    25 &&
                                  partnerDetails.Partner_Current_Discount__c <=
                                    29
                                ? '26'
                                : '30'
                              : '-'
                            : partnerDetails.Account_Type__c === 'Gold'
                            ? partnerDetails.Partner_Current_Discount__c
                              ? partnerDetails.Partner_Current_Discount__c >=
                                  0 &&
                                partnerDetails.Partner_Current_Discount__c <= 13
                                ? '10'
                                : partnerDetails.Partner_Current_Discount__c >
                                    13 &&
                                  partnerDetails.Partner_Current_Discount__c <=
                                    17
                                ? '14'
                                : partnerDetails.Partner_Current_Discount__c >
                                    17 &&
                                  partnerDetails.Partner_Current_Discount__c <=
                                    21
                                ? '18'
                                : '22'
                              : '-'
                            : '-'}
                          % off list price
                        </h1>
                        <div
                          onClick={() =>
                            DownloadFiles(
                              `${url}s3img/download/Program Guide.pdf`,
                              'Program Guide.pdf',
                              'application/pdf'
                            )
                          }
                          className='text-[#292929] font-semibold text-center flex justify-center lg:justify-end hover:cursor-pointer text-sm xl:text-base'
                        >
                          <h1>Program guide</h1>{' '}
                          <img
                            className='ml-3 lg:mr-9'
                            src={BlackDownloadButton}
                          ></img>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='bg-white m-4'>
                      <h1>
                        Benefits are given to Partners that are <b>Gold</b> or{' '}
                        <b>Platinum</b>, contact{' '}
                        <a
                          className='text-blue-500 hover:underline'
                          href='mailto:partners@wazuh.com'
                        >
                          partners@wazuh.com
                        </a>{' '}
                        for more details.
                      </h1>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
