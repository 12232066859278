import React from 'react';
import { Field, Form, Formik } from 'formik';
import WhiteRightArrow from '../components/img/WhiteRightArrow.svg';
import CountriesList from '../components/CountriesList';

const AskDemo = () => {
  return (
    <div className='m-5'>
      <div className='font-semibold mb-4 text-3xl'>
        Ask for a Demo with the Wazuh team
      </div>
      <div className=' mb-4 text-lg'>
        Enter a timezone and we will contact you to confirm a date.
      </div>
      <div>
        <Formik>
          <Form className='flex flex-col'>
            <div className='flex gap-8  mt-16'>
              <div>
                <p className='font-semibold mb-2'>Customer company name</p>
                <Field
                  className=' border border-[#B7C6D7] rounded-md w-64 px-2 py-2 text-black placeholder-[#B7C6D7]'
                  placeholder='Company name'
                  type='text'
                  name='Name'
                ></Field>

                <p className='mt-2 text-[#B7C6D7] text-sm'>*Required</p>
              </div>
              <div>
                <p className='font-semibold mb-2'>Country</p>

                <Field
                  className=' border border-[#B7C6D7] rounded-md w-64 px-2 py-2 text-black placeholder-[#B7C6D7]'
                  placeholder='-'
                  as='select'
                  name='country'
                >
                  <CountriesList />
                </Field>
                <p className='mt-2 text-[#B7C6D7] text-sm'>*Required</p>
              </div>
              <div className='flex items-center'>
                <button
                  type='submit'
                  className='bg-[#3D82F1] hover:bg-blue-800 text-white mr-24 rounded-lg px-2 py-2 flex items-center'
                >
                  Next{' '}
                  <img
                    alt='white arrow pointing right'
                    className='ml-12'
                    src={WhiteRightArrow}
                  ></img>
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default AskDemo;
