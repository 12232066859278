import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import AddUserModal from '../../Modals/AddUserModal';

//icons
import BluePlus from '../../img/BluePlus.svg';

const addUserSchema = Yup.object({
  username: Yup.string()
    .required('Username is required')
    .max(30, 'Name must be 30 characters or less.'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .max(70, 'Email name must be 70 characters or less.'),
});

const AddUser = ({ onCreate }) => {
  const [addUser, setAddUser] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const initialData = {
    username: '',
    email: '',
  };

  const addUserFunction = () => {
    setAddUser(!addUser);
  };

  const handleSubmit = async (formData) => {
    addUserFunction();
    handleOpenModal();
    await onCreate(formData.username, formData.email);
  };

  return (
    <div>
      <AddUserModal openModal={openModal} handleCloseModal={handleCloseModal} />
      {!addUser ? (
        <button
          className='flex max-sm:w-full max-sm:justify-end hover:cursor-pointer'
          onClick={addUserFunction}
        >
          <img alt='blue plus sign' src={BluePlus}></img>

          <h1 className='text-blue-500 ml-2'>Add user</h1>
        </button>
      ) : (
        <Formik
          onSubmit={handleSubmit}
          validationSchema={addUserSchema}
          initialValues={initialData}
        >
          {({ errors, touched }) => (
            <Form className=''>
              <div className='flex max-sm:flex-col mb-5'>
                <div>
                  {errors.username && touched.username ? (
                    <p className='font-semibold mb-2 text-red-500'>
                      {errors.username}
                    </p>
                  ) : (
                    <p className='font-semibold mb-2'>Username</p>
                  )}
                  <Field
                    name='username'
                    type='text'
                    placeholder='username'
                    className=' border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md w-full sm:w-48 2xl:w-64 px-2 py-2 text-black placeholder-[#B7C6D7]'
                  ></Field>
                </div>
                <div className='sm:ml-6'>
                  {errors.email && touched.email ? (
                    <p className='font-semibold mb-2 text-red-500'>
                      {errors.email}
                    </p>
                  ) : (
                    <p className='font-semibold mb-2 max-sm:mt-4'>Email</p>
                  )}
                  <Field
                    name='email'
                    type='text'
                    placeholder='email'
                    className=' border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md w-full sm:w-48 2xl:w-64 px-2 py-2 text-black placeholder-[#B7C6D7]'
                  ></Field>
                </div>
                {/* <div className='ml-7'>
                  {errors.password && touched.password ? (
                    <p className='font-semibold mb-2 text-red-500'>
                      {errors.password}
                    </p>
                  ) : (
                    <p className='font-semibold mb-2'>Password</p>
                  )}
                  <Field
                    name='password'
                    type='password'
                    placeholder='password'
                    className=' border border-[#B7C6D7] rounded-md  w-64 px-2 py-2 text-black placeholder-[#B7C6D7]'
                  ></Field>
                </div> */}
                <div className='max-sm:max-w-52 max-sm:gap-12 flex max-sm:justify-end max-sm:flex max-sm:mt-5'>
                  <button
                    type='submit'
                    className='bg-[#3D82F1] hover:bg-blue-800 max-sm:py-2 px-2 ml-4 hover:cursor-pointer sm:mt-10 text-white rounded-lg text-center items-center flex'
                  >
                    Save
                  </button>
                  <div
                    onClick={addUserFunction}
                    className=' px-2 ml-4 hover:underline hover:cursor-pointer sm:mt-10 text-blue-500 rounded-full text-center items-center flex'
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default AddUser;
