import { apiSlice } from '../../apiSlice';

export const TwoFAApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    needs2FA: builder.mutation({
      query: (body) => ({
        url: '/api/2fa/needs2FA',
        method: 'POST',
        body: body,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    generate: builder.mutation({
      query: (body) => ({
        url: '/api/2fa/generate',
        method: 'POST',
        body: body,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    verify: builder.mutation({
      query: (body) => ({
        url: '/api/2fa/verifysecret',
        method: 'POST',
        body: body,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    disable: builder.mutation({
      query: (body) => ({
        url: '/api/2fa/disable2FA',
        method: 'POST',
        body: body,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
  }),
});

export const {
  useNeeds2FAMutation,
  useGenerateMutation,
  useVerifyMutation,
  useDisableMutation,
} = TwoFAApiSlice;
