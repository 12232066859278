import * as React from 'react';
const Export = (props) => (
  <svg
    width='41'
    height='41'
    viewBox='0 0 41 41'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='20.5' cy='20.5' r='20.5' fill={props.bgfill} />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.983 22.294a3.125 3.125 0 1 1 4.514.833C30.015 23.968 31 25.577 31 27.5v1.875a.625.625 0 1 1-1.25 0V27.5c0-2.155-1.595-3.75-3.75-3.75-1.138 0-1.622-.024-2.256-.153-1.562-.317-2.916-1.25-4.482-3.207a.625.625 0 1 1 .976-.78c1.125 1.406 2.065 2.176 3.042 2.554.193.064.432.103.703.13Zm2.23.16c.105.013.21.027.315.043a1.875 1.875 0 1 0-.314-.043Zm-2.988 2.508c.345 0 .625.28.625.625a1.3 1.3 0 0 1-.062.29c-.224.473-.288.967-.288 1.626v1.875a.625.625 0 1 1-1.25 0v-1.875c0-.82.09-1.482.384-2.12a.625.625 0 0 1 .591-.42Zm-4.777-7.746a.625.625 0 1 1-.976.78l-2.585-3.23a.625.625 0 1 1 .976-.781l2.585 3.23ZM12.25 23.75h6.875a.625.625 0 1 1 0 1.25h-7.5a.625.625 0 0 1-.625-.625v-13.75c0-.345.28-.625.625-.625h15c.345 0 .625.28.625.625v3.75a.625.625 0 1 1-1.25 0V11.25H12.25v12.5Z'
      fill={props.mainfill}
    />
  </svg>
);
export default Export;
