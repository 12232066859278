import React from 'react';
import ChartInfo from '../Popover/ChartInfo';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  Tooltip,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';
import Oval from '../img/Oval.svg';

const OpportunitiesPerYear = ({ deals, loading }) => {
  const startsWithRArray = deals.filter((obj) => /R\d+/.test(obj.Name));
  const doesNotStartWithRArray = deals.filter((obj) => !/R\d+/.test(obj.Name));
  const combinedData = startsWithRArray
    .map((obj) => ({
      name: obj.Name,
      StageName: obj.StageName,
      CloseDate: obj.CloseDate,
      Renewal: true,
    }))
    .concat(
      doesNotStartWithRArray.map((obj) => ({
        name: obj.Name,
        StageName: obj.StageName,
        CloseDate: obj.CloseDate,
        Renewal: false,
      }))
    );

  const countObjectsPerYear = (data) => {
    const countByYear = data.reduce((acc, item) => {
      const year = item.CloseDate.split('-')[0]; // Extract the year from the date
      const startsWithRCount = acc[year]?.Renewal || 0;
      const doesNotStartWithRCount = acc[year]?.Prospect || 0;

      if (item.Renewal) {
        acc[year] = {
          year,
          Renewal: startsWithRCount + 1,
          Prospect: doesNotStartWithRCount,
        };
      } else {
        acc[year] = {
          year,
          Renewal: startsWithRCount,
          Prospect: doesNotStartWithRCount + 1,
        };
      }

      return acc;
    }, {});

    return Object.values(countByYear);
  };

  const resultNew = [];

  combinedData.forEach((item) => {
    // Extract the year from CloseDate
    const year = item.CloseDate.split('-')[0];

    // Determine the category based on Renewal and StageName
    let category;
    if (item.Renewal) {
      category =
        item.StageName === 'Closed Won'
          ? 'RenewalClosedWon'
          : item.StageName === 'Closed Lost' ||
            item.StageName === 'Closed Disqualified'
          ? 'RenewalClosedLost'
          : 'RenewalInProcess';
    } else {
      category =
        item.StageName === 'Closed Won'
          ? 'ProspectClosedWon'
          : item.StageName === 'Closed Lost' ||
            item.StageName === 'Closed Disqualified'
          ? 'ProspectClosedLost'
          : 'ProspectInProcess';
    }

    // Find or create the year object in the result array
    let yearObject = resultNew.find((obj) => obj.year === year);
    if (!yearObject) {
      yearObject = { year };
      resultNew.push(yearObject);
    }

    // Increment the category count in the year object
    yearObject[category] = (yearObject[category] || 0) + 1;
  });

  resultNew.sort((a, b) => a.year - b.year);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let reversedPayload = payload.reverse();
      let renewalCount = 0;
      let prospectCount = 0;
      reversedPayload.forEach((item) => {
        const dataKey = item.dataKey;
        if (dataKey.startsWith('Renewal')) {
          renewalCount++;
        } else if (dataKey.startsWith('Prospect')) {
          prospectCount++;
        }
      });

      return (
        <div className='bg-white border-2 rounded-lg border-gray-300 p-4'>
          <p className='px-2'>{`${label}`}</p>
          {reversedPayload.map((pld, index) => (
            <div className=' px-2 flex flex-col'>
              {/* {console.log(reversedPayload, 'payload map')} */}
              {(index == 0 || index == prospectCount) && (
                <span className='form-divider h-1 mr-0 lg:mr-6 2xl:mr-3 hidden md:block my-2'></span>
              )}

              <div style={{ color: pld.fill }}>
                {`${pld.name}: ${pld.value.toLocaleString()}`}
              </div>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomLegend = ({ payload }) => {
    return (
      <div className='flex flex-col ml-11'>
        <div className='flex justify-between   w-full'>
          {payload.slice(0, 3).map((entry, index) => (
            <div key={entry.value} className='flex items-center mr-1'>
              <div
                style={{
                  backgroundColor: entry.color,
                  width: 14,
                  height: 10.5,
                  marginRight: 5,
                }}
              />
              <span style={{ color: entry.color }}>{entry.value}</span>
            </div>
          ))}
        </div>
        <div className='flex justify-between   w-full'>
          {payload.slice(3).map((entry, index) => (
            <div key={entry.value} className='flex items-center mr-1'>
              <div
                style={{
                  backgroundColor: entry.color,
                  width: 14,
                  height: 10.5,
                  marginRight: 5,
                }}
              />
              <span style={{ color: entry.color }}>{entry.value}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className=''>
      <div className=''>
        <div className='flex items-center'>
          <h1 className='text-[22px] mr-1 font-semibold text-left'>
            Opportunities per year
          </h1>
          <ChartInfo
            text={
              'Shows your amount of Opportunities per year of all stages, divided between Renewals and New oppportunities. Includes only recurrent services, does not include professional hours and trainings.'
            }
          />
        </div>
        <span className='form-divider h-1 mr-0 lg:mr-6 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
      </div>
      {loading === true ? (
        <div className='flex items-center justify-center bg-[#F6F6F6] h-[450px]'>
          <img src={Oval}></img>
        </div>
      ) : loading === false && deals.length <= 0 ? (
        <div className='flex items-center justify-center bg-[#F6F6F6] h-[450px]'>
          Register your first deal to view this chart
        </div>
      ) : (
        <div className='rounded-lg  p-3  bg-[#F6F6F6]'>
          <div className='justify-start flex items-center'>
            <ResponsiveContainer width='100%' height={423}>
              <BarChart
                margin={{
                  top: 40,
                  right: 40,
                  left: 20,
                  bottom: 20,
                }}
                data={resultNew}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='year' />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend content={<CustomLegend />} />
                <Bar
                  dataKey='RenewalClosedLost'
                  stackId='a'
                  fill='#d54639'
                  name='Renewal Lost'
                >
                  {resultNew.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      radius={
                        !entry.RenewalInProcess &&
                        !entry.RenewalClosedWon &&
                        !entry.ProspectClosedLost &&
                        !entry.ProspectClosedWon &&
                        !entry.ProspectInProcess
                          ? [10, 10, 0, 0]
                          : [0, 0, 0, 0]
                      }
                    />
                  ))}
                </Bar>
                <Bar
                  dataKey='RenewalInProcess'
                  stackId='a'
                  fill='#E55C42'
                  name='Renewal In Process'
                >
                  {resultNew.map((entry, index) => (
                    <>
                      <Cell
                        key={`cell-${index}`}
                        radius={
                          !entry.RenewalClosedWon &&
                          !entry.ProspectClosedLost &&
                          !entry.ProspectClosedWon &&
                          !entry.ProspectInProcess
                            ? [10, 10, 0, 0]
                            : [0, 0, 0, 0]
                        }
                      />
                    </>
                  ))}
                </Bar>

                <Bar
                  dataKey='RenewalClosedWon'
                  stackId='a'
                  fill='#fa704d'
                  name='Renewal Won'
                >
                  {resultNew.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      radius={
                        !entry.ProspectClosedLost &&
                        !entry.ProspectClosedWon &&
                        !entry.ProspectInProcess
                          ? [10, 10, 0, 0]
                          : [0, 0, 0, 0]
                      }
                    />
                  ))}
                </Bar>
                <Bar
                  dataKey='ProspectClosedLost'
                  stackId='a'
                  fill='#2E6BE6'
                  name='New Opps Lost'
                >
                  {resultNew.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      radius={
                        !entry.ProspectClosedWon && !entry.ProspectInProcess
                          ? [10, 10, 0, 0]
                          : [0, 0, 0, 0]
                      }
                    />
                  ))}
                </Bar>
                <Bar
                  dataKey='ProspectInProcess'
                  stackId='a'
                  fill='#3585F9'
                  name='New Opps In Process'
                >
                  {resultNew.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      radius={
                        !entry.ProspectClosedWon ? [10, 10, 0, 0] : [0, 0, 0, 0]
                      }
                    />
                  ))}
                </Bar>
                <Bar
                  dataKey='ProspectClosedWon'
                  stackId='a'
                  fill='#559FFF'
                  name='New Opps Won'
                >
                  {resultNew.map((entry, index) => (
                    <Cell key={`cell-${index}`} radius={[10, 10, 0, 0]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default OpportunitiesPerYear;
