import { useState } from 'react';
import DeleteModal from './Modals/Delete';
import useAuth from '../hooks/useAuth';

const User = ({
  id,
  email,
  username,
  onEdit,
  onDelete,
  rolemap,
  key1,
  userId,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { role } = useAuth();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleDelete = () => {
    onDelete(id);
  };

  const editUserInitialValues = {
    id: id,
    username: username,
    email: email,
  };

  const handleFormikSubmit = async (formData) => {
    setIsEdit(!isEdit);
    handleOpenModal();
    await onEdit(formData.id, formData.username, formData.email);
  };

  return (
    <div>
      <div className='flex max-sm:flex-col space-y-4 sm:space-y-0 sm:space-x-7 2xl:space-x-6 mt-4'>
        <div className='flex flex-col'>
          <h1>{key1}</h1>
          <h1 className=' border border-[#B7C6D7] rounded-md sm:w-48 xl:w-48 2xl:w-64 px-2 py-2'>
            <div>{username}</div>
          </h1>
        </div>
        <div className='flex flex-col'>
          <h1>Email</h1>
          <h1 className=' border border-[#B7C6D7] rounded-md  sm:w-48 xl:w-48 2xl:w-64 px-2 py-2 truncate'>
            {email}
          </h1>
        </div>
        <div className='max-sm:max-w-52 max-sm:gap-12 max-sm:mr-4 max-sm:justify-end max-sm:flex'>
          {(role === 'admin' || role === 'wazuh') && id !== userId && (
            <>
              {/* <button onClick={handleEdit}>
                  <img alt='edit' className='sm:mt-8' src={Edit}></img>
                </button> */}
              {/* <button className='ml-5' onClick={handleDelete}>
              <img className='mt-8' src={Trash}></img>
            </button> */}
              <DeleteModal handleDelete={handleDelete} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default User;
