import ResourceDocIcon from '../components/img/ResourceDocIcon.svg';
import SmallFolderResourceIcon from '../components/img/SmallFolderResourceIcon.svg';
import BigFolderResourceIcon from '../components/img/BigFolderResourceIcon.svg';
import { DownloadFiles } from '../hooks/DownloadFiles';

const ResourcesCard = ({ name, date, type, funct, file }) => {
  const url = `${process.env.REACT_APP_API_URL}/api/`;

  const handleClick = (name, file, type) => {
    if (type === 'folder') {
      if (funct !== null) {
        funct(name);
      }
    } else {
      DownloadFiles(
        `${url}s3img/download/${file}`,
        `${file}`,
        'application/pdf'
      );
    }
  };

  return (
    <div className='flex justify-center sm:justify-start w-full'>
      <div
        onClick={() => handleClick(name, file, type)}
        className='bg-[#f6f6f6] hover:bg-[#ececec] hover:cursor-pointer rounded-xl flex flex-col h-32 w-40 xl:h-44 xl:w-52 2xl:w-60'
      >
        <div className='flex items-center ml-4 my-2'>
          {type === 'folder' ? (
            <img
              src={SmallFolderResourceIcon}
              className='h-5 w-5 relative bottom-[2px]'
            ></img>
          ) : (
            <img
              src={ResourceDocIcon}
              className='h-5 w-5 relative bottom-[2px]'
            ></img>
          )}
          <p className='ml-2 text-xs overflow-ellipsis mr-2 truncate font-semibold'>
            {name}
          </p>
        </div>
        <div className='bg-white mx-2 mb-2 h-32 rounded-md flex justify-center items-center flex-col text-center'>
          {type === 'folder' ? (
            <img className='w-14 h-14' src={BigFolderResourceIcon}></img>
          ) : (
            <img className='w-14 h-14' src={ResourceDocIcon}></img>
          )}
          <p className='mt-2 text-xs'>
            Modified date:
            <br className=' hidden xl:block' />
            {date ? date : '-'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResourcesCard;
